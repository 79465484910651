import {
  Radio           as __Radio,
} from "exports.js";

import {
  Button     as __Button,

} from "components/Controls/Buttons";

import {
  TextView   as __TextView,
  PillText   as __PillText,

} from "components/Controls/TextView";

export const Button = ( {color, ...props} ) =>
{
  var clsName = ""
  if( props.outlined )
  {
    clsName = `py-4 px-4 rounded text-sm
                  bg-transparent hover:bg-gray-300 text-${color}-700
                  border border-${color}-700
                  ${props.className} `;
  }
  else
  {
    clsName = `py-4 px-4 rounded text-sm
                  bg-${color}-600 hover:bg-${color}-700 text-white
                  ${props.className} `;
  }

  return __Button( clsName, props )
}

export const SearchButton = ( {color, ...props} ) =>
{
  var clsName = ""
    clsName = `py-4 px-8 rounded text-sm
                  bg-${color}-600 hover:bg-${color}-700 text-white
                  ${props.className} `;

  return __Button( clsName, props )
}


/**
export const TextView = ( {color, ...props} ) =>
{

  var clsName = `bg-transparent px-3
                  ${props.className}`;

  return __TextView( clsName, props )
}
**/


export const TextView = ( {type, className, ...props} ) =>
{

  var clsName = '';
  switch( type )
  {
    case 'xxlarge' :
         clsName = `flex bg-transparent rounded px-3 justify-center
                      text-4xl font-bold ${className}`
         break
    case 'title' :
    case 'xlarge' :
         clsName = `bg-transparent rounded py-2 px-3
                      text-2xl font-bold ${className}`
         break
    case 'large' :
    case 'subtitle' :
         clsName = `bg-transparent rounded py-2 px-3
                      text-xl font-semibold ${className}`
         break
    case 'small' :
         clsName = `bg-transparent rounded px-2
                      text-xs font-normal ${className}`
         break
    case 'normal' :
    default :
         clsName = `bg-transparent rounded px-2
                      font-normal ${className}`
         break
  }

  return __TextView( clsName, props )
}

export function PillText( {className, color, ...props} ) {

  const classname
        = `justify-center py-2 my-2
           bg-${color}-500 hover:bg-${color}-500 text-white
           ${className}
          `;

  return __PillText( classname, props )
}

export function Radio( {className, ...props} ) {
  const clsName = `items-center ${className} pl-6`

  return __Radio( clsName, props )
}
