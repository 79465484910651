import { schemeBlues } from 'd3';

export function createScaleExpr(thresholds, colors) {
  const expr = [];

  for (let i = 0; i < thresholds.length; i++) {
    expr.push(thresholds[i]);
    expr.push(colors[i]);
  }

  return {
    thresholds,
    colors,
    expr,
  };
}

export const populationScheme = createScaleExpr(
  [0, 1000, 2000, 2500, 3000, 3500, 4000, 4500, 5000],
//  [0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000],
  schemeBlues[9],
);
/**
export const populationScheme = createScaleExpr(
//  [0, 1, 2, 3, 4, 5, 6, 7, 8],
  [0, 2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20],
  schemeBlues[9],
);
**/
