import React from "react";
import Navbar from "components/Navbar.js";
import {useHistory, } from 'react-router-dom';

import { bundle } from 'shared/helpers';
import { strings } from 'shared/strings';
import { SimData as SIMDATA } from 'shared/simdata';

import Footer              from "components/Footer";

import {
  TextView, 
  Checkbox,
} from './exports';
import {
  Button,
} from './wrapper';

const S = strings.SimGuide

const Top = () => {
  return(
    <div className="top-section pt-8 lg:px-36 md:px-16 sm:px-8">
      <div>
        <TextView type="title"
                  text={`${S.page_title}`}
        />
      </div>

      <div className="flex bg-purple-000">
        <TextView className="lg:w-1/3 md:w-full pl-2"
                  type="default"
                  text={`${S.page_desc}`}
        />
      </div>
    </div>
  )
}

export default function Main() 
{
  const uid      = bundle.getUid()

  const history = useHistory();

  const qstring  = history.location.qstring
  const gotoNext = (val) => history.push({pathname: '/sim/m', qstring: val });

//  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({chkbox1:false, chkbox2: false, chkbox3: false});
  const [ disableBtn,   setDisableBtn ] = React.useState(true);

  const sales = [
    {chkid: 'chkbox1', id:S.chkbox1_id, name: S.chkbox1, op: '+'},
    {chkid: 'chkbox2', id:S.chkbox2_id, name: S.chkbox2, op: '+'},
    {chkid: 'chkbox3', id:S.chkbox3_id, name: S.chkbox3, op: '+'},
  ]
/**
  const calculation = ( _formula ) => {
    setOpen(false);

    bundle.set( uid, bundle.key.main_cat_formula, _formula )

    gotoNext(qstring)

  }
**/


  const openCalDlg = () => {

    const values = Object.values(state)
    var formula = []
    values.forEach( (d,i) => {
      if( d === true )
      formula.push({id:sales[i].id, name:sales[i].name, op:sales[i].op})
    })

    bundle.set( uid, bundle.key.main_cat_formula, formula )
    SIMDATA.empty(uid);

    gotoNext(qstring)

  }

/**
  const closeCalDlg = () => {
    setOpen(false);
  };
**/

  const handleCheck = (e) => {
    if( e.target.id === sales[0].id ) {
      state.chkbox1 = !state.chkbox1
    }
    else if( e.target.id === sales[1].id ) {
      state.chkbox2 = !state.chkbox2
    }
    else if( e.target.id === sales[2].id ) {
      state.chkbox3 = !state.chkbox3
    }
    setState( state )
    if(state.chkbox1 || state.chkbox2 || state.chkbox3 ) {
      setDisableBtn( false );
    }
    else {
      setDisableBtn( true );
    }
  }

  return (
    <>

      <Navbar />

      <div className="h-full w-full" >

        <Top />

        <div className="body-section relative pt-24 lg:px-36 md:px-16 sm:px-8 w-full">

          <div className="">
            <TextView className=""
                      type="subtitle"
                      text={`${S.btn1_desc}`}
            />
            <TextView className="pl-3"
                      type="normal"
                      text={`${S.btn1_desc2}`}
            />
          </div>

          <div className="">
            <div className="bg-purple-000 py-4 w-1/2">

              <Checkbox id={sales[0].id} name="checkbox" text={S.chkbox1}
                        checked={state.checked} onChange={(e) =>{handleCheck(e)} }/>
              <Checkbox id={sales[1].id} name="checkbox" text={S.chkbox2} 
                        onChange={(e) => handleCheck(e) }/>
              <Checkbox id={sales[2].id} name="checkbox" text={S.chkbox3} 
                        onChange={(e) =>{handleCheck(e)} }/>

            </div>

            <div className="pt-16 lg:w-1/4 md:w-1/2 sm:w-1/2 px-2">

              <Button color="blue" disabled={disableBtn} className="w-full py-4 text-xl"
                      onClick={() => {openCalDlg()} } >
                {S.btn1_label}
              </Button>
            </div>

          </div>

        </div>

      </div>
{/**
      <div className="absolute bottom-0 w-full pt-16 pb-2 px-16 bg-transparent">
      </div>
**/}
      {/* --- [ Footer ] --- */}
      <div className="w-full pt-2 pb-2 px-16 bg-transparent">
        <Footer />
      </div>

    </>
  );
}
