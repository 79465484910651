import { populationScheme } from './styles';

export const grid = {
  id: 'grid',
  type: 'line',
  source: 'population',
  'source-layer': 'krpop',

  paint: {
    'line-color': 'rgba(0,0,0,0.3)',
    'line-width': 0.5,
  },
//  filter: ['==', 'year', '2010'],
};

//
// to display selections on map
//
export const popHighlighted = {
  id: 'population-highlighted',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'age_both_total'],
      ...populationScheme.expr,
    ],
    'fill-opacity': 0.75,
  },

  filter: ['in', 'key_code', '' ]
};


//
// for population pyramid chart
//
export const population = {
  id: 'population',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpop',

  paint: {
    'fill-outline-color': '#484896',
    'fill-opacity': 0,
  },
//  filter: ['==', 'year', '2018'],
};

export const krPopLayer = {
  id: 'krPopLayer',
  type: 'line',
  source: 'population',
//  source: 'retail',
  'source-layer': 'krpop',
  paint: {
    'line-color': 'rgba(0,0,0,0.1)',
    'line-width': 1,
  },
//  filter: ['==', 'year', '2010'],
};

export const krPopTrendLayer = {
  id: 'krPopTrendLayer',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpoptrend',

  paint: {
    'fill-outline-color': '#484896',
    'fill-opacity': 0,
  }

//  filter: ['==', 'year', '2010'],
};

export const highlightedGridLayer = {
  id: 'grid-highlighted',
  type: 'fill',
  source: 'population',
  'source-layer': 'krpop',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'age_both_total'],
      ...populationScheme.expr,
    ],
    'fill-opacity': 0.75,
  },
  filter: ['in', 'key_code', ''],
};

//
// for housetype chart
//
export const housetype = {
  id: 'housetype',
  type: 'fill',
  source: 'population',
  'source-layer': 'krhousetype',
  paint: {
    'fill-outline-color': '#484896',
    'fill-opacity': 0,
  },
//  filter: ['==', 'year', '2018'],
};

