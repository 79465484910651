import React                    from "react";
import {useHistory}             from 'react-router-dom';

import StoreIcon                from '@material-ui/icons/StoreMallDirectoryOutlined';
import GroupIcon                from '@material-ui/icons/Group';
import TableChartIcon           from '@material-ui/icons/TableChart';
import AccountBalanceIcon       from '@material-ui/icons/AccountBalance';
//import ArrowDownIcon            from '@material-ui/icons/ArrowDownward';
//import ArrowDownIcon            from '@material-ui/icons/LabelImportant';
//import ArrowDownIcon            from '@material-ui/icons/Navigation';
import ArrowDownIcon            from '@material-ui/icons/TransitEnterexit';
//import SpaceBarIcon            from '@material-ui/icons/SpaceBar';
import SpaceBarIcon            from '@material-ui/icons/FormatQuote';


import { initializeApp }        from 'firebase/app';
import { getAuth,
//         signInWithPopup,
         onAuthStateChanged,
//         GoogleAuthProvider 
}   from "firebase/auth";

import Navbar                   from "components/Navbar.js";
import Footer                   from "components/Footer.js";
import {
        Card, 
//        CardHeader, 
        CardBody, 
//        CardFooter, 
//        CardButton,
}                               from "components/Controls/Card";

import { 
        TextView,
        Button,
}                               from "./wrapper";
import {bundle}                 from "shared/helpers";
import {strings}                from "shared/strings";
import {firebaseConfig}         from "shared/firebase";

import imgCustomer              from 'assets/img/ubiforecast-customer.png'
import imgCategory              from 'assets/img/ubiforecast-category.png'
import imgFormula               from 'assets/img/ubiforecast-formula.png'
import imgSummary               from 'assets/img/ubiforecast-summary.png'
import imgSummary2              from 'assets/img/ubiforecast-summary2.png'

const S = strings.Landing;

export default function Main() 
{
  const history = useHistory();

  const gotoDemo    = (val) => history.push({pathname: '/demo/reg', qstring: val });
  const gotoPricing = (val) => history.push({pathname: '/pricing',  qstring: val });
  const gotoHome    = (val) => history.push({pathname: '/home',     qstring: val });
  const gotoSearch  = (val) => history.push({pathname: '/search',  qstring: val });

  const handleGoForecast = () => {

    initializeApp(firebaseConfig);

    const auth = getAuth();

    onAuthStateChanged( auth, (user) => {
      if( user ) {
        const brands = bundle.get(user.uid, bundle.key.auth_brands )
        const qstring = {uid: user.uid, brands: brands}

        gotoHome( qstring );
      }
      else {
        const qstring = {}
        gotoPricing( qstring );
      }
    });
  }

  const handleGoSearch = () => {

    initializeApp(firebaseConfig);

    const auth = getAuth();
console.log( auth.currentUser );
console.log( auth.currentUser.email );
console.log( auth.currentUser.displayName );

    onAuthStateChanged( auth, (user) => {
console.log( user );
      if( user ) {
        const qstring = {}
        gotoSearch( qstring );
      }
      else {
        const qstring = {}
        gotoPricing( qstring );
      }
    });
  }

  const handleDemo = () => {

    bundle.del('demo');
    bundle.set("demo", bundle.key.region_map_center, [127.024612, 37.532600] )

    const qstring = {}
    gotoDemo( qstring );
  }

  return (
    <>
      {/* --- [ Header ] --- */}
      <Navbar />

      {/* --- [ Body ] --- */}
      <div className="container mx-auto w-full bg-blue-500 pb-0 min-w-min" >

        {/* --- [ Title ] --- */}
        <div className="pt-24 px-6 lg:px-32">
          <TextView className="text-5xl font-semibold text-white"
                    text={S.title_line1} />
          <TextView className="text-5xl font-semibold text-white pt-6"
                    text={S.title_line2} />
        </div>

        {/* --- [ Sub Title ] --- */}
        <div className="pt-12 px-6 lg:px-32">
          <TextView className="text-2xl text-white"
                    text={S.subtitle_line1} />
          <TextView className="text-2xl text-white pt-2"
                    text={S.subtitle_line2} />
          <TextView className="text-2xl text-white pt-2"
                    text={S.subtitle_line3} />
        </div>

        {/* --- [ Buttons ] --- */}
        <div className="container flex justify-center mx-auto h-48 pt-12 pb-36 px-8 ">

          <Button className="w-48 h-24 ml-0" type="top" onClick={() => {handleGoSearch()}} >
            <TextView text='상권검색 시작' className="font-bold text-xl lg:text-2xl"/>
            <TextView text='좋은 위치를 찾아주세요. ' className="text-sm pt-2 text-gray-500"/>
          </Button>
          <Button className="w-48 h-24 ml-12" type="top" onClick={() => {handleGoForecast()}} >
            <TextView text='매출예측 시작' className="font-bold text-xl lg:text-2xl"/>
            <TextView text='매출이 얼마나 나올까요?' className="text-sm pt-2 text-gray-500"/>
          </Button>
          <Button className="w-48 h-24 ml-6 text-left" type="top" outlined onClick={() => {handleDemo()}} >
            <TextView text='매출예측 체험' className="font-bold"/>
            <TextView text='' className="text-sm"/>
          </Button>
        </div>

        {/* --- [ Features ] --- */}
        <div className="mt-24 mx-16 px-16 pt-8 pb-8 bg-white rounded-2xl">
          <TextView className="text-2xl text-gray-700 px-24 py-8"
                    text={S.feat_title} />

          <Card color="white" className="flex flex-col lg:flex-row justify-between border-none shadow-none">
            <CardBody color={{bg:'gray', fg:'gray'}} className="w-full rounded-xl text-center mr-6 py-8">
              <div className="flex my-2 justify-center">
                <div className="flex w-32 h-32 rounded-full bg-red-300 justify-center items-center">
                  <GroupIcon fontSize="large" style={{fontSize:'72' }} className="my-2"/>
                </div>
              </div>
              <TextView className="text-xl font-bold pt-6" text={`${S.feat_col1_title}`} />
              <TextView className="pt-6 px-6 text-sm" text={`${S.feat_col1_desc}`} />
              <TextView className="pt-6 px-6 text-sm" text='' />

              <img
                alt="..."
                className="max-w-full pt-8 px-4"
                src={imgCustomer}
              />

            </CardBody>

            <CardBody color={{bg:'gray', fg:'gray'}} className="w-full rounded-xl text-center mr-6 py-8">
              <div className="flex my-2 justify-center">
                <div className="flex w-32 h-32 rounded-full bg-blue-300 justify-center items-center">
                  <TableChartIcon fontSize="large" style={{fontSize:'72' }} className="my-2"/>
                </div>
              </div>
              <TextView className="text-xl font-bold pt-6" text={`${S.feat_col2_title}`} />
              <TextView className="pt-6 px-6 text-sm" text={`${S.feat_col2_desc}`} />
              <TextView className="pt-6 px-6 text-sm" text='' />
              <TextView className="pt-6 px-6 text-sm" text='' />
              <img
                alt="..."
                className="max-w-full pt-8 px-4"
                src={imgFormula}
              />
              <img
                alt="..."
                className="max-w-full pt-2 px-4"
                src={imgCategory}
              />
            </CardBody>

            <CardBody color={{bg:'gray', fg:'gray'}} className="w-full rounded-xl text-center mr-0 py-8">
              <div className="flex my-2 justify-center">
                <div className="flex w-32 h-32 rounded-full bg-yellow-300 justify-center items-center">
                  <StoreIcon fontSize="large" style={{fontSize:'72' }} className="my-2"/>
                </div>
              </div>
              <TextView className="text-xl font-bold pt-6" text={`${S.feat_col3_title}`} />
              <TextView className="pt-6 px-6 text-sm" text={`${S.feat_col3_desc}`} />
              <img
                alt="..."
                className=" w-full max-w-full pt-8 px-4"
                src={imgSummary}
              />
              <img
                alt="..."
                className=" w-full max-w-full px-4"
                src={imgSummary2}
              />
            </CardBody>

          </Card>

        </div>

      <div className="container mx-auto w-full h-8 bg-blue-500" >
      </div>
      {/* --- [ Try Buttons ] --- */}

      <div className="container mx-auto py-48 items-center text-center justify_center bg-gray-200">
        <div className="flex my-2 justify-center">
          <AccountBalanceIcon fontSize="large" style={{fontSize:'108' }} className="my-2"/>
        </div>
        <TextView className="text-5xl font-semibold text-gray-600"
                  text={`${S.start_title}`} />
        <TextView className="text-xl font-semibold text-gray-600 pt-8"
                  text={`${S.start_subtitle}`} />

        <div className="container flex justify-center mt-12">
          <div className="px-2 lg:px-12">
            <div className="grid grid-cols-4 px-12">
              {/* --- [ 1st ROW ] --- */}
              <Button className="w-40 h-36" type="bot" onClick={() => {handleGoSearch()}} >
                  <TextView text='상권검색 시작' className="font-bold text-xl "/>
              </Button>
              <div className="flex col-span-3 justify-center items-center pl-8">
                <SpaceBarIcon style={{fontSize:'48', color:'darkblue' }} className="transform rotate-180"/>
                <div className="w-full text-left">
                  <TextView text='창업 위치를 아직 못 정하셨나요? ' className="text-gray-700"/>
                  <TextView text='상권검색으로 이동하여 좋은 위치를 찾아보세요.' className="text-gray-700"/>
                </div>
                <SpaceBarIcon style={{fontSize:'48', color:'darkblue' }} className="transform rotate-0"/>
              </div>

              {/* --- [ 2nd ROW ] --- */}
              <div className="w-40 justify-center items-center">
                <ArrowDownIcon style={{fontSize:'64', color:'darkblue' }} className="my-2 transform -rotate-45"/>
              </div>

              {/* --- [ 3rd ROW ] --- */}
              <Button className="w-40 h-36 col-start-1" type="bot" onClick={() => {handleGoForecast()}} >
                <TextView text='매출예측 시작' className="font-bold text-xl"/>
              </Button>
              <div className="flex col-span-3 justify-center items-center pl-8">
                <SpaceBarIcon style={{fontSize:'48', color:'darkblue' }} className="transform rotate-180"/>
                <div className="w-full text-left">
                  <TextView text='좋은 위치를 찾으셨다고요?' className="text-gray-700"/>
                  <TextView text='매출예측으로 이동하여 예상 매출액을 시뮬레이션해 보세요.' className="text-gray-700"/>
                </div>
                <SpaceBarIcon style={{fontSize:'48', color:'darkblue' }} className="transform rotate-0"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      {/* --- [ Footer ] --- */}
      <div className="w-full py-2 bg-transparent">
        <Footer />
      </div>
    </>
  );
}
