import React                    from "react";
import * as d3                  from 'd3';

import Paper                    from '@material-ui/core/Paper';
import { DataGrid }             from '@material-ui/data-grid';


import Pyramid                  from "components/Chart/Pyramid";
import Mline                    from "components/Chart/Mline";
import Radar                    from "components/Chart/Radar";
import {
        Card,
        CardHeader,
        CardBody,
        CardFooter,
}                               from "components/Controls/Card";

import { currency }             from "shared/helpers";
import { bundle }               from 'shared/helpers';

import {
  TextView,
}                               from './exports';
import * as Config              from "config";

export function Card2 ( {title, cols, className, ...items } )
{

  return(
    <div className={`container mx-auto ${className}`}>
      <TextView type="subtitle" text={title} className="bg-gray-200"/>
      <div className={`py-4 shadow-md w-full rounded bg-white grid grid-cols-${cols} divide-x-2`} >
      {
        items.children.length > 1
          ? items.children.map( (d,i) => {
              return d
            })
          : items.children
      }
      </div>
    </div>
  )
}

export function CardView ( {title, cols, className, ...items } )
{

  return(
    <Paper elevation={5} className={`py-4 grid grid-cols-${cols} divide-x-2 ${className}`} >
    {
      items.children.length > 1
        ? items.children.map( (d,i) => {
            return d
          })
        : items.children
    }
    </Paper>
  )
}

export function Summary ( {simdata, brandName, branchName, data, popTrend, population, keyCat} )
{

  const brandNm = branchName ? brandName + ' (' + branchName + ')'
                             : brandName

  const sales = {
    daily: {
      total:    currency.format( Math.round(data.inhouse + data.takeout + data.delivery) ),
      inhouse:  currency.format( Math.round(data.inhouse)  ),
      takeout:  currency.format( Math.round(data.takeout)  ),
      delivery: currency.format( Math.round(data.delivery) ),
    },
    monthly: {
      total:    currency.format( Math.round(data.inhouse + data.takeout + data.delivery) ),
      inhouse:  currency.format( Math.round(data.inhouse )  ),
      takeout:  currency.format( Math.round(data.takeout )  ),
      delivery: currency.format( Math.round(data.delivery ) ),
    }
  }

  const age10 = population.filter( it => (it.group === "10-14" || it.group === "15-19") );
  const age20 = population.filter( it => (it.group === "20-24" || it.group === "25-29") );
  const age30 = population.filter( it => (it.group === "30-34" || it.group === "35-39") );
  const age40 = population.filter( it => (it.group === "40-44" || it.group === "45-49") );
  const age50 = population.filter( it => (it.group === "50-54" || it.group === "55-59") );
  const age60 = population.filter( it => (it.group === "60-64" || it.group === "65-69") );
  const age70 = population.filter( it => (it.group === "70-74" || it.group === "75-79"  ||
                                          it.group === "80-84" || it.group === "85-89"  ||
                                          it.group === "90-94" || it.group === "95-100" ||
                                          it.group === "100+" ) );

  const age10m = d3.sum(age10.map( (d) => {return d.male  } ) );
  const age10f = d3.sum(age10.map( (d) => {return d.female} ) );
  const age20m = d3.sum(age20.map( (d) => {return d.male  } ) );
  const age20f = d3.sum(age20.map( (d) => {return d.female} ) );
  const age30m = d3.sum(age30.map( (d) => {return d.male  } ) );
  const age30f = d3.sum(age30.map( (d) => {return d.female} ) );
  const age40m = d3.sum(age40.map( (d) => {return d.male  } ) );
  const age40f = d3.sum(age40.map( (d) => {return d.female} ) );
  const age50m = d3.sum(age50.map( (d) => {return d.male  } ) );
  const age50f = d3.sum(age50.map( (d) => {return d.female} ) );
  const age60m = d3.sum(age60.map( (d) => {return d.male  } ) );
  const age60f = d3.sum(age60.map( (d) => {return d.female} ) );
  const age70m = d3.sum(age70.map( (d) => {return d.male  } ) );
  const age70f = d3.sum(age70.map( (d) => {return d.female} ) );

//  const simdata = SIMDATA.get('default');

  const targetAges = {
          age10m: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age10m : false, value: age10m},
          age10f: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age10f : false, value: age10f},
          age20m: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age20m : false, value: age20m},
          age20f: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age20f : false, value: age20f},
          age30m: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age30m : false, value: age30m},
          age30f: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age30f : false, value: age30f},
          age40m: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age40m : false, value: age40m},
          age40f: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age40f : false, value: age40f},
          age50m: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age50m : false, value: age50m},
          age50f: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age50f : false, value: age50f},
          age60m: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age60m : false, value: age60m},
          age60f: {checked: simdata[keyCat].population ? simdata[keyCat].population.popinfo.ages.age60f : false, value: age60f},
        }

  const getPopSelected = (ages) => {
    const values = Object.values(ages)
    const filtered = values.filter( (d) => d.checked === true )
    const tot = d3.sum(filtered.map( (d) => {return d.value} ) );

    return( tot );
  }

  // 선택 연령층 인구 합산 결과 저장
  const selected = getPopSelected(targetAges);

  const tot = d3.sum(Object.values(population)
                .map( (d) => {return d.male + d.female} ) );

  let majorityRate = Math.round( (selected / tot * 100) * 100) / 100       // 주고객층 비율
  majorityRate = majorityRate.toLocaleString();

  return(

    <div className="container mx-auto">
      <TextView type="subtitle" text={`상권 개요`}  className="bg-gray-000"/>
      <div className="flex flex-col lg:flex-row gap-x-2 gap-y-2">
        <div className='lg:w-2/4 md:w-full'>
          <CardView cols="2" className="">

            <div className="px-4">
              <TextView text="후보지 점명"     type="small"  
                        className="font-bold text-gray-500" />
              <TextView text={brandNm} type="normal" 
                        className="font-bold text-gray-800 py-2" />
            </div >

            <div className="px-4 col-start-2">
              <TextView text="내점 매출"     type="small"  
                        className="font-bold text-gray-500" />
              <TextView text={`${sales.monthly.inhouse}원/월`} 
                        type="normal" 
                        className="font-bold text-gray-800 py-2 text-right" />
            </div >

            <div className="px-4 col-start-1">
              <TextView text="상권유형"     type="small"  
                        className="font-bold text-gray-500" />
              <TextView text={`주고객 비중: ${majorityRate}%`} type="normal" 
                        className="font-bold text-gray-800 py-2" />
            </div >

            <div className="px-4 col-start-2">
              <TextView text="배달 매출"     type="small"  
                        className="font-bold text-gray-500" />
              <TextView text={`${sales.monthly.delivery}원/월`} 
                        type="normal" 
                        className="text-right font-bold text-gray-800 py-2" />
            </div >

            <div className="px-4 col-start-2">
              <TextView text="테이크아웃 매출"     type="small"  
                        className="font-bold text-gray-500" />
              <TextView text={`${sales.monthly.takeout}원/월`} 
                        type="normal" 
                        className="text-right font-bold text-gray-800 py-2" />
            </div >

          </CardView>

        </div >
        <div className='lg:w-1/4 md:w-full'>
          <Card className='w-1/4 bg-blue-500'>
            <CardHeader h={8} color={{bg:'transparent', fg:'white'}} >
              <TextView text="사회활동인구 증감 (최근5년)"     type="normal" />
            </CardHeader>

            <CardBody color={{bg:'transparent', fg:'white'}} >
              <TextView className="h-full font-bold items-center text-5xl" type="xxlarge"
                        text={`${popTrend.year5Rate} %`} />
            </CardBody>

            <CardFooter h={6} color={{bg:'transparent', fg:'white'}} 
                        className="text-left grid grid-cols-2" >
              <TextView text={`최근1년 : ${popTrend.year1Rate}%`} type="small"
                        className="flex items-center justify-left" />

              <TextView text={`*사회활동인구:15~64세`} type="small" 
                        className="flex items-center justify-end" />
            </CardFooter>
          </Card >
        </div>

        <div className='lg:w-1/4 md:w-full'>
          <Card className='w-1/4 bg-pink-500'>
            <CardHeader h={8} color={{bg:'transparent', fg:'white'}} >
              <TextView text="예상 월매출액 (단위:원)"     type="normal" />
            </CardHeader>

            <CardBody color={{bg:'transparent', fg:'white'}} >
              <TextView className="h-full font-bold items-center text-5xl" type="xxlarge"
                        text={`${sales.monthly.total} `} />
            </CardBody>

            <CardFooter h={6} color={{bg:'transparent', fg:'white'}} className="text-right" >
            </CardFooter>
          </Card >
        </div>
      </div>
    </div>
  )
}

export function Population ( {chart1: population, chart2: timeline} )
{

  const age10 = population.filter( it => (it.group === "10-14" || it.group === "15-19") );
  const age20 = population.filter( it => (it.group === "20-24" || it.group === "25-29") );
  const age30 = population.filter( it => (it.group === "30-34" || it.group === "35-39") );
  const age40 = population.filter( it => (it.group === "40-44" || it.group === "45-49") );
  const age50 = population.filter( it => (it.group === "50-54" || it.group === "55-59") );
  const age60 = population.filter( it => (it.group === "60-64" || it.group === "65-69") );
  const age70 = population.filter( it => (it.group === "70-74" || it.group === "75-79"  ||
                                          it.group === "80-84" || it.group === "85-89"  ||
                                          it.group === "90-94" || it.group === "95-100" ||
                                          it.group === "100+" ) );

  const age10m = d3.sum(age10.map( (d) => {return d.male  } ) );
  const age10f = d3.sum(age10.map( (d) => {return d.female} ) );
  const age20m = d3.sum(age20.map( (d) => {return d.male  } ) );
  const age20f = d3.sum(age20.map( (d) => {return d.female} ) );
  const age30m = d3.sum(age30.map( (d) => {return d.male  } ) );
  const age30f = d3.sum(age30.map( (d) => {return d.female} ) );
  const age40m = d3.sum(age40.map( (d) => {return d.male  } ) );
  const age40f = d3.sum(age40.map( (d) => {return d.female} ) );
  const age50m = d3.sum(age50.map( (d) => {return d.male  } ) );
  const age50f = d3.sum(age50.map( (d) => {return d.female} ) );
  const age60m = d3.sum(age60.map( (d) => {return d.male  } ) );
  const age60f = d3.sum(age60.map( (d) => {return d.female} ) );
  const age70m = d3.sum(age70.map( (d) => {return d.male  } ) );
  const age70f = d3.sum(age70.map( (d) => {return d.female} ) );

  return(

    <div className="container mx-auto">
      <TextView type="subtitle" text={`인구정보`}  className="bg-gray-000"/>
      <div className="flex flex-col lg:flex-row gap-x-2 gap-y-2">
        <div className='lg:w-1/3 md:w-full'>
          <CardView cols="3" className="h-full">
            <TextView className="text-center text-gray-800 font-bold"
                      text="나이" />
            <TextView className="text-center text-gray-800 font-bold"
                      text="남"   />
            <TextView className="text-center text-gray-800 font-bold"
                      text="여"   />

            <TextView className="text-center text-gray-800"
                      text="10대" />
            <TextView className="text-center text-gray-800"
                      text={`${age10m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age10f}명`}   />

            <TextView className="text-center text-gray-800"
                      text="20대" />
            <TextView className="text-center text-gray-800"
                      text={`${age20m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age20f}명`}   />

            <TextView className="text-center text-gray-800"
                      text="30대" />
            <TextView className="text-center text-gray-800"
                      text={`${age30m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age30f}명`}   />

            <TextView className="text-center text-gray-800"
                      text="40대" />
            <TextView className="text-center text-gray-800"
                      text={`${age40m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age40f}명`}   />

            <TextView className="text-center text-gray-800"
                      text="50대" />
            <TextView className="text-center text-gray-800"
                      text={`${age50m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age50f}명`}   />

            <TextView className="text-center text-gray-800"
                      text="60대" />
            <TextView className="text-center text-gray-800"
                      text={`${age60m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age60f}명`}   />

            <TextView className="text-center text-gray-800"
                      text="70세 이상" />
            <TextView className="text-center text-gray-800"
                      text={`${age70m}명`}   />
            <TextView className="text-center text-gray-800"
                      text={`${age70f}명`}   />
          </CardView>
        </div>

        <div className='lg:w-1/3 md:w-full'>
          <Paper elevation={5} className="h-full" >
            <div className="flex bg-white justify-center mx-2 shadow-xl">
              <Pyramid
                data={population}
                wparam={{width:'520', height:'260'}}
              />
            </div >
          </Paper>
        </div >

        <div className='lg:w-1/3 md:w-full'>
          <Paper elevation={5} className="h-full" >
            <div className="flex bg-white justify-center mx-2 shadow-xl">
              <Mline
                data={timeline}
                wparam={{width:'520', height:'260'}}
              />
            </div >
          </Paper>
        </div >

      </div>
    </div>
  )
}

export function Housing ( {chart1: housetype} )
{
          
  // noh: number of households
  const noh = {
    apt:   housetype[0]['아파트'] ? housetype[0]['아파트'] : 0,
    house:  housetype[0]['단독주택'] ? housetype[0]['단독주택'] : 0,
    etc:   housetype[0]['다세대/빌라/기타'] ? housetype[0]['다세대/빌라/기타'] : 0,
  }
  const nohSum = noh.apt + noh.house + noh.etc > 0 ? (noh.apt + noh.house + noh.etc) : 1;
  const rate = {
    apt:   Math.round((noh.apt   / nohSum * 100) * 100) / 100,
    house: Math.round((noh.house / nohSum * 100) * 100) / 100,
    etc:   Math.round((noh.etc   / nohSum * 100) * 100) / 100,
  }
  rate.apt   = rate.apt   > 0 ? rate.apt   + " %" : '-';
  rate.house = rate.house > 0 ? rate.house + " %" : '-';
  rate.etc   = rate.etc   > 0 ? rate.etc   + " %" : '-';

  return(
    <div className="container mx-auto">
      <TextView type="subtitle" text={`세대정보`}  className=""/>
      <div className="flex flex-col lg:flex-row gap-x-2 gap-y-2">
        <div className='lg:w-2/3 md:w-full'>
          <CardView cols="2" className="h-full items-center">

            <div className="pl-4 col-start-1 pr-12">
              <TextView text="아파트"     type="small"
                        className="font-bold text-gray-500" />
              <TextView text={`${noh.apt.toLocaleString()} 세대`} type="normal"
                        className="text-right font-bold text-gray-800 py-2" />
            </div>

            <div className="pl-4 col-start-2 pr-12">
              <TextView text="아파트 비율"     type="small"
                        className="font-bold text-gray-500" />
              <TextView text={`${rate.apt.toLocaleString()}`} type="normal"
                        className="text-right font-bold text-gray-800 py-2" />
            </div>

            <div className="pl-4 col-start-1 pr-12">
              <TextView text="단독주택"     type="small"
                        className="font-bold text-gray-500" />
              <TextView text={`${noh.house.toLocaleString()} 세대`} type="normal"
                        className="text-right font-bold text-gray-800 py-2" />
            </div>

            <div className="pl-4 col-start-2 pr-12">
              <TextView text="단독주택 비율"     type="small"
                        className="font-bold text-gray-500" />
              <TextView text={`${rate.house.toLocaleString()}`} type="normal"
                        className="text-right font-bold text-gray-800 py-2" />
            </div>

            <div className="pl-4 col-start-1 pr-12">
              <TextView text="다세대/빌라/기타"     type="small"
                        className="font-bold text-gray-500" />
              <TextView text={`${noh.etc.toLocaleString()} 세대`} type="normal"
                        className="text-right font-bold text-gray-800 py-2" />
            </div>

            <div className="pl-4 col-start-2 pr-12">
              <TextView text="다세대/빌라/기타 비율"     type="small"
                        className="font-bold text-gray-500" />
              <TextView text={`${rate.etc.toLocaleString()}`} type="normal"
                        className="text-right font-bold text-gray-800 py-2" />
            </div>

          </CardView>
        </div>

        <div className='lg:w-1/3 md:w-full'>
          <Paper elevation={5} className="h-full" >
            <div className="flex bg-white justify-center mx-0 shadow-xl">
              <Radar
                data={housetype}
                wparam={{width:'350', height:'350'}}
              />
            </div >
          </Paper>
        </div>

      </div>
    </div>
  )
}

export function Competitor ( {simdata, uid, keyCat, ...props} )
{
  const cfg = Object.assign({}, Config );
//  const simdata = SIMDATA.get('default');

//  const { brandName: brand, branchName: branch } = props

  const [directCompetitors,   setDirectCompetitors]   = React.useState(null);
  const [indirectCompetitors, setIndirectCompetitors] = React.useState(null);

  const stores = {
        inSameIndustry:    simdata[keyCat].marketShare ? simdata[keyCat].marketShare.stores.inSameIndustry : {},
        inSimilarIndustry: simdata[keyCat].marketShare ? simdata[keyCat].marketShare.stores.inSimilarIndustry : {},
  }


  async function fetchCompetitors( keycodes, class1Nm, class3Nm, sicNm) {

    const direct = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/same`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({'key_codes': keycodes, 'class3Nm': class3Nm })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {
       data = data.filter( item => stores.inSameIndustry.includes(item.shop_no) )
       if( data.length > 0 ) {
         data = data.map( (d,i) => {return( Object.assign({'no':i+1, 'id': d.shop_no}, d)) })

         direct['columns'] = theaderStores(Object.keys(data[0]))
         direct['rows'] = data

         setDirectCompetitors(direct)
       }
       else {
         const dummy = [
           {id:'9999', dummy:"해당 업종의 경쟁 업소가 없는 지역입니다." }
         ]
         direct['columns'] = theaderStores(Object.keys(dummy[0]))
         direct['rows'] = dummy
         setDirectCompetitors(direct)
       }

    })

    .catch( error => {
         const dummy = [
           {id:'9999', dummy:"서버 연결에 실패하였습니다. 잠시 후 다시 시도해 주십시오." }
         ]
         direct['columns'] = theaderStores(Object.keys(dummy[0]))
         direct['rows'] = dummy
         setDirectCompetitors(direct)
    });


    const indirect = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/relative`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({'key_codes': keycodes, 'class1Nm': class1Nm, 'class3Nm': class3Nm, 
                            'sicNm': sicNm })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {
       data = data.filter( item => stores.inSimilarIndustry.includes(item.shop_no) )
   
       if( data.length > 0 ) {
         data = data.map( (d,i) => {return( Object.assign({'no':i+1, 'id': d.shop_no}, d)) })

         indirect['columns'] = theaderStores(Object.keys(data[0]))
         indirect['rows'] = data

         setIndirectCompetitors(indirect)
       }
       else {
         const dummy = [
           {id:'9999', dummy:"유사 업종의 경쟁 업소가 없는 지역입니다." }
         ]
         indirect['columns'] = theaderStores(Object.keys(dummy[0]))
         indirect['rows'] = dummy
         setIndirectCompetitors(indirect)
       }
    })
    .catch( error => {
         const dummy = [
           {id:'9999', dummy:"서버 연결에 실패하였습니다. 잠시 후 다시 시도해 주십시오." }
         ]
         indirect['columns'] = theaderStores(Object.keys(dummy[0]))
         indirect['rows'] = dummy
         setIndirectCompetitors(indirect)
    });
  }

  const theaderStores = (header) => {

    const format = [
          {'hid': 'no',        'width': 100, 'type': 'string', 'hide': false,
                               'headerName': 'No.'},
          {'hid': 'id',        'width': 100, 'type': 'string', 'hide': true,
                               'headerName': 'id.'},
          {'hid': 'key_code',  'width': 140, 'type': 'string', 'hide': true,
                               'headerName': 'KEY_CODE'},
          {'hid': 'shop_no',   'width': 140, 'type': 'string', 'hide': true,
                               'headerName': '상호ID'},
          {'hid': 'shop_nm',   'width': 260, 'type': 'string', 'hide': false, 
                               'headerName': '상호명'},
          {'hid': 'branch_nm', 'width': 120, 'type': 'string', 'hide': false,
                               'headerName': '지점명'},
          {'hid': 'floor_no',  'width': 120, 'type': 'number', 'hide': false,
                               'headerName': '층정보'},
          {'hid': 'sic_nm',    'width': 200, 'type': 'string', 'hide': false,
                               'headerName': '표준산업분류'},
          {'hid': 'class1_nm', 'width': 200, 'type': 'string', 'hide': false,
                               'headerName': '업종대분류'},
          {'hid': 'class2_nm', 'width': 200, 'type': 'string', 'hide': false,
                               'headerName': '업종중분류'},
          {'hid': 'class3_nm', 'width': 200, 'type': 'string', 'hide': false,
                               'headerName': '업종소분류'},

          {'hid': 'oaddr',     'width': 400, 'type': 'string', 'hide': true,
                               'headerName': '지번주소'},
          {'hid': 'naddr',     'width': 400, 'type': 'string', 'hide': true,
                               'headerName': '도로명주소'},
          {'hid': 'bldg_nm',   'width': 200, 'type': 'string', 'hide': true,
                               'headerName': '건물정보'},
          {'hid': 'unit_no',   'width': 80, 'type': 'number',  'hide': true,
                               'headerName': '호정보'},
          {'hid': 'dummy',     'width': 600, 'type': 'string', 'hide': false,
                               'headerName': ' '},
    ]

    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i]
      const item = format.find( it => it.hid === hid )
        columns.push( {
          'field':       hid,
          'hide':        item ? item.hide : false,
          'headerName':  item ? item.headerName : '',
          'width':       item ? item.width : 80,
          'type':        item ? item.type : 'string',
          'headerAlign': 'center',
          'valueGetter': item ? item.valueGetter : '',
        })
    }

    return( columns )
  }

  React.useEffect(() => {

    const keycodes = bundle.get(uid, bundle.key.region_keycodes);
    const industry = bundle.get(uid, bundle.key.classifications);

    fetchCompetitors(keycodes, industry.class1_nm, industry.class3_nm, industry.sic_nm );

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(

    <div className="container mx-auto">
      <TextView type="subtitle" text={`경쟁업소 정보`}  className="bg-gray-000"/>
      <div className="grid grid-cols-4 mx-auto">
        <div className="col-span-4">
        {
          directCompetitors &&
          <CardView title={`경쟁점 정보`}>
            <TextView type="subtitle" text={`직접경쟁점`}  className="bg-gray-000"/>
            <div className="bg-purple-000 p-4 w-full" >
            {
              directCompetitors &&

              <DataGrid
                columns={directCompetitors.columns}
                rows={directCompetitors.rows}
                autoPageSize={true}
                autoHeight={true}
                disableColumnMenu={true}
                hideFooterPagination={false}
                hideFooterRowCount={false}
                hideFooterSelectedRowCount={true}
                loading={false}
              />

            }
            </div>

            <TextView type="subtitle" text={`간접경쟁점`}  className="bg-gray-000 pt-8"/>
            <div className="bg-purple-000 p-4 w-full" >
            {
              indirectCompetitors &&
              <DataGrid
                columns={indirectCompetitors.columns}
                rows={indirectCompetitors.rows}
                autoPageSize={true}
                autoHeight={true}
                disableColumnMenu={true}
                hideFooterPagination={false}
                hideFooterRowCount={false}
                hideFooterSelectedRowCount={true}
                loading={false}
              />
            }
            </div>
          </CardView>
        }
        </div>
      </div>
    </div>

  )
}
