import React from 'react';

import {
  TextView     as __TextView,
  Actionbar     as __Actionbar,
  IconButton     as __IconButton,
  Button     as __Button,
} from "exports.js";

export function ItemButton( {className, ...props} ){

//  const color = 'transparent'
  const classname = `h-20 bg-blue-500 hover:bg-blue-700 text-white text-sm m-2 px-2 rounded
                     ${className}`;
  return(
    <button className={classname} onClick={props.onClick} >
      {props.text}
      {props.children}

{/**
      React.Children.map( props.children, (child, i) => {
        return( React.cloneElement( child,
                { className :`hover:bg-${color}-300 cursor-pointer`,
                  onClick   : child.props.onClick}
        ))
      })
**/}

    </button>
  )
}

export function TemplateButton( {className, ...props} ){

  const classname = `p-2 bg-tranparent text-gray-700 hover:bg-gray-500 hover:text-white border-2 text-sm m-2 rounded-2xl
                     ${className}`;
  return(
    <button className={classname} onClick={props.onClick} >
      <div>
        {props.children}
      </div>
      <div>
        {props.text}
      </div>
    </button>
  )
}


export function OpButton( {...props} ){

  const bg = props.color ? props.color.bg : "purple"
  const fg = props.color ? props.color.fg : "white"

  const classname = `bg-${bg}-500 hover:bg-${bg}-700 text-${fg} m-2 py-2 px-2 rounded 
                     ${props.className}`;

  return(
    <button className={classname} onClick={props.onClick}>
      {props.text}
    </button>
  )
}

export function IconButton( {className, color, ...props} ){

  var clsName = ""
  if( props.invert ) {
      clsName = `text-${color}-700 font-normal bg-transparent
                 py-2 px-4 border border-${color}-500 rounded
                 h-full hover:bg-gray-300
                 ${className}`
  }
  else {
      clsName = `font-normal bg-${color}-500 text-white
                 py-2 px-4 rounded h-full hover:bg-${color}-700
                 ${className}`
  }

  const align = props.align === 'column' ? 'none' : 'inline-flex';

  return __IconButton( clsName, align, props )
}



export function TextView( {className, ...props} ) {

  const classname = `${className}`;

  return __TextView( classname, props )
}

export function Button( {className, color, ...props} ) {

  const classname = `bg-${color}-500 hover:bg-${color}-700 text-white
                     rounded
                     ${className}`;

  return __Button( classname, props )
}


export function TextInput( prop ){
  const classname = `shadow bg-transparent placeholder-gray-700  w-full my-2 py-2 px-3
                     h-10
                     `;
  return(
      <input className={classname} type="text" placeholder={prop.hint} value={prop.text} onChange={prop.onChange}/>
  )
}



export function Actionbar( {className, ...props} ) {

  const clsName = `
                   ${className}`;
  const color = "gray";

  return __Actionbar( clsName, color, props )
}


export function ApplyButton( prop ){

  const classname = `bg-blue-500 hover:bg-blue-700 text-white py-2 px-2 rounded
                     w-full text-center
                     ${prop.visibility}`;
//    <button className={classname} onClick={prop.onClick}>
  return(
    <div className={classname} onClick={prop.onClick} >
      {prop.text}
    </div>
  )
}


