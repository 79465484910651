import {
        TextView     as __TextView,
}                    from "components/Controls/TextView";
import {
        Button  as __Button,
}                    from "components/Controls/Buttons";

export const TextView = ( {color, ...props} ) =>
{

  var clsName = `bg-transparent
                  ${props.className}`;

  return __TextView( clsName, props )
}

export const  Button = ( {color, ...props} ) =>
{
  const fg = "text-" + color + "-600";
  const clsName = `w-full h-full bg-white ${fg} shadow rounded-2xl
                   border border-gray-300
                     ${props.className}`;

  return __Button( clsName, props )
}
