import React from 'react';
import * as d3 from 'd3';
import legend from 'shared/legend';
import { flattenObjArray, createColorScale } from 'shared/helpers';
import { barwide as defaults } from 'shared/defaults.js';
import { progressBar } from 'shared/helpers';


//const BarWide = React.forwardRef(({ data, options }, ref) => {
const BarWide = React.forwardRef(({ data, chartId, callback, wparam }, ref) => {

  const cfg = Object.assign({}, defaults, wparam);

  const w       = cfg.width,
        h       = cfg.height,
        mtop    = cfg.margin.top,
        mleft   = cfg.margin.left,
        mbottom = cfg.margin.bottom,
        mright  = cfg.margin.right,
        gutter  = cfg.legendGutter;


  const bounded = {
    width: w - mleft - mright,
    height: h - mbottom - mtop - gutter,
  };

  const rootRef   = React.useRef(null);
  const bodyRef   = React.useRef(null);
  const titleRef  = React.useRef(null);
  const unitsRef  = React.useRef(null);
  const xAxisRef  = React.useRef(null);
  const yAxisRef  = React.useRef(null);
  const legendRef = React.useRef(null);
  const pbarRef   = React.useRef(null);

  const draw = () => {
    const body = d3.select(bodyRef.current);
    const title = d3.select(titleRef.current);
    const xAxis = d3.select(xAxisRef.current);
    const yAxis = d3.select(yAxisRef.current);

    const groupColumn = data.columns[0];
    const groups = data.map((d) => d[groupColumn]);

    const flat = flattenObjArray(data, groupColumn);
    let maxValue = Math.max(
      cfg.maxValue || 0,
      d3.max(flat.map((d) => parseInt(d.value))) * cfg.rangeFactor,
    );

    const color = createColorScale(
      cfg.colors,
      groups,
      cfg.firstColor,
      cfg.lastColor,
    );

    const labelFormat = d3.format(cfg.numFormat);
    // Scales.
    const x = d3
      .scaleBand()
      .domain(groups)
      .rangeRound([0, bounded.width])
      .paddingInner(0.5)
      .paddingOuter(0.25)

    // Axis generators.
    const xAxisGen = d3
      .axisBottom()
      .scale(x)
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickPadding(10)
      .tickFormat( d => d.substring(0,5) )

    const y = d3
      .scaleLinear()
      .domain([0, maxValue])
      .rangeRound([bounded.height, 0])
      .nice(cfg.numTicks);


    if (cfg.xAxisFormat) xAxisGen.tickFormat(cfg.xAxisFormat);
    const yAxisGen = d3
      .axisLeft(y)
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickPadding(10)
//      .tickFormat(labelFormat)
      .ticks(cfg.numTicks);

    // Draw axes.
    xAxis.call(xAxisGen) 
     .selectAll("text")  
     .style("text-anchor", "end")
     .attr("dx", "-.8em")
     .attr("dy", ".15em")
     .attr("transform", "rotate(-65)");

    yAxis.call(yAxisGen);

    // Define the div for the tooltip
    var div = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("opacity", .5);


    // Draw bars.
    body
      .selectAll('.bar')
      .data(flat)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (d,i) => x(d.group))
      .attr('width', x.bandwidth())
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => bounded.height - y(d.value))
      .style('fill', (d, i) => color(d.group))
      .on( 'mouseover', (d, i) => {
            div.transition()
               .duration(20)
               .style("opacity", .9);
//            div.html(d.value)
            div.html(d.group)
               .style("font-size", "12")
               .style("position", "absolute")
               .style("z-index", "99")
               .style("top", (d3.event.pageY - 48)+"px")
               .style("left", (d3.event.pageX)+"px")
//               .style("top", (d3.event.pageY - 1200)+"px")
//               .style("left", (d3.event.pageX)+"px")
            })
      .on("mouseout", function(d) {
            div.transition()
               .duration(1000)
               .style("opacity", 0)
        })
/**
      .on( 'click', (d, i) => {
           div.transition()
              .duration(1000)
              .style("opacity", 0)
           callback(chartId, d.group) 
        })
**/
      .transition()
      .on("end", (d, i) => {
          if( i+1 === flat.length ) {
            progressBar.hide(pbarRef)
          }
       })



    // Draw bar value labels.
    body
      .selectAll('.barValue')
      .data(flat)
      .join('text')
      .attr('class', 'barValue')
      .attr('x', (d,i) => x(groups[i]) + x.bandwidth() / 2)
      .attr('text-anchor', 'middle')
      .text((d) => labelFormat(d.value) )
      .style('fill', (d, i) => 'black')
      .on( 'mouseover', (d, i) => {

            div.transition()
               .duration(20)
               .style("opacity", .9);
            div.html(d.group )
               .style("font-size", "12px")
               .style("position", "absolute")
               .style("z-index", "99")
               .style("top", (d3.event.pageY - 48)+"px")
               .style("left", (d3.event.pageX)+"px")
            })

      .on("mouseout", function(d) {
            div.transition()
               .duration(1000)
               .style("opacity", 0)
        })
/**
      .on( 'click', (d, i) => {
           div.transition()
              .duration(1000)
              .style("opacity", 0)
           callback(chartId, d.group)
        })
**/


      .transition()
      .attr('y', (d) => y(d.value) - 20)



    // Draw title.
    title.text(cfg.title || null);

    // Draw the legend.
    if (cfg.useLegend) {
      d3.select(legendRef.current).call(legend, {
        color,
        maxWidth: bounded.width,
        fontSize: cfg.fontSize,
      });
    }

    if (cfg.units) {
      d3.select(unitsRef.current).text(cfg.units || null);
    }
  };

  React.useEffect(() => {

    try {


      if( data && data.length > 0 ) {
        draw();
      }
      else
      {
        if( Object.keys(data).length === 0  ) {
          // show progress bar
          progressBar.show(pbarRef, cfg.width, cfg.height,
                           "no data selected" );
        } else {
          // show progress bar
          progressBar.show(pbarRef, cfg.width, cfg.height,
                           "no data found" );
        }

        d3.select(bodyRef.current)
          .selectAll("*")
          .remove()
        d3.select(xAxisRef.current)
          .selectAll("*")
          .remove()
        d3.select(yAxisRef.current)
          .selectAll("*")
          .remove()
        d3.select(unitsRef.current)
          .text(null)

      }
    } catch (error) {
      alert(`Invalid data entered for chart id. (${error}) `);
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cfg ]);
//  }, [data, cfg, draw]);
//  }, [data, cfg ]);

  return (
    <svg
      ref={ref}
      width={cfg.width}
      height={cfg.height}
      className="chart"
      viewBox={`0 0 ${cfg.width} ${cfg.height}`}
      style={{ margin: 'auto', display: 'block', fontSize: cfg.fontSize }}
    >

      <g ref={pbarRef} transform={`translate(0,0)`}/>

      <g ref={rootRef} transform={`translate(${cfg.margin.left}, ${cfg.margin.top})`}>
        <g ref={bodyRef} />
        <text
          ref={titleRef}
          transform={`translate(${bounded.width / 2}, ${cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize * 1.5, textAnchor: 'middle' }}
        />
        <text
          ref={unitsRef}
          transform={`translate(${-4 * cfg.fontSize}, ${-cfg.fontSize * 1.5})`}
          style={{ fontSize: cfg.fontSize, textAnchor: 'right' }}
        />
        <g
          ref={xAxisRef}
          transform={`translate(${0}, ${bounded.height})`}
          style={{ fontSize: cfg.fontSize }}
        />
        <g ref={yAxisRef} style={{ fontSize: cfg.fontSize }} />
        <g
          ref={legendRef}
          transform={`translate(${0}, ${bounded.height + cfg.legendGutter})`}
        />
      </g>
    </svg>
  );
});

export default BarWide;
