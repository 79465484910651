import * as d3 from 'd3';
import omit from 'lodash/omit';
import map from 'lodash/map';

import pbar from 'shared/pbar';
import water from 'shared/watermark';

export const arrayToCSV = (arr) => {

  const fieldNames = Object.keys(arr[0]).join(',');
  const values = arr.map((d) => Object.values(d).join(',')).join('\n');
  const csvString = fieldNames + '\n' + values;

  return csvString;
};

export const getArray = (o, type) => {
  switch (type) {
    case 'network':
      return o.nodes;
    case 'radar':
      return o.points;
    default:
      return o;
  }
};

export const polygonPath = (radius, sides) => {
  return d3
    .radialLine()
    .angle((d, i) => (i / sides) * 2 * Math.PI)
    .curve(d3.curveLinearClosed)
    .radius(() => radius)(Array.from({ length: sides }));
};

export function parseTransposed(tsvString) {
  const array = d3.transpose(d3.tsvParseRows(tsvString));
  const string = array.map((row) => row.join('\t')).join('\n');
  return d3.tsvParse(string, d3.autoType);
}

export const parseDataString = (string, transpose) =>
  transpose ? parseTransposed(string) : d3.tsvParse(string, d3.autoType);

export function flattenObjArray(objArray, groupKey, provideId = false) {
  if (!groupKey) throw 'Invalid group key.';

  let id = 0;

  return objArray
    .map((d) => {
      const group = d[groupKey];
      const values = omit(d, [groupKey]);
      const entries = map(values, (v, k) => {
        const entry = {};
        entry.group = group;
        entry.axis = k;
        entry.value = v;
        if (provideId) entry.id = id++;
        return entry;
      });
      return entries;
    })
    .flat();
}

export const createColorScale = (colors, domain, firstColor, lastColor) => {
  const middle = colors.slice(
    0,
    domain.length - (firstColor ? 1 : 0) - (lastColor ? 1 : 0),
  );
  const scheme = [firstColor, ...middle, lastColor].filter((d) => !!d);
  return d3.scaleOrdinal().domain(domain).range(scheme);
};

export const progressBar = {
    show : (ref, width, height, msg) => {
           d3.select(ref.current)
             .style('display', 'block')
             .call(pbar, {
                 msg: msg,
                 width: width,
                 height:height,
              })
    },
    hide : (ref) => {
           d3.select(ref.current)
             .style('display', 'none')

    }
};

export const watermark = {
    show : (ref, width, height, msg) => {
              d3.select(ref.current)
                .style('display', 'block')
                .call(water, {
                    msg: msg,
                    width: width,
                    height:height,
                 })
    },
    hide : (ref) => {
           d3.select(ref.current)
             .style('display', 'none')

    }
};

export const currency = {
  format: (val) => {
    const roundup = Math.round(val * 100) / 100

    const formatted  = roundup > 0 ? roundup.toLocaleString() : 0

    return( formatted );
  }
}

export const bundle = 
{
  key: {
    login_uid           : "__LOGIN_UID__",
    auth_user           : "__AUTH_USER__",
    auth_projects       : "__AUTH_PROJECTS__",
    main_cat_formula    : "__MAIN_CAT_FORMULA__",
    sub_cat_formula     : "__SUB_CAT_FORMULA__",
    sim_data            : "__SIM_DATA__",
    brand_info          : "__BRAND_INFO__",
    classifications     : "__CLASSIFICATIONS__",
    region_map_center   : "__REGION_MAP_CENTER__",
    region_pop_pyramid  : "__REGION_POP_PYRAMID__",
    region_pop_timeline : "__REGION_POP_TIMELINE__",
    region_house_type   : "__REGION_HOUSE_TYPE__",
    region_keycodes     : "__REGION_KEYCODES__",
    category            : "__CATEGORY__",
    featured_data       : "__FEATURED_DATA__",
    search_sparam       : "__SEARCH_SPARAM__",
  },

  jsonMapReviver : (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  },

  jsonMapReplacer : (key, value) => {
    if (value instanceof Map ) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...originalObject]
      };
    } else {
      return value;
    }
  },

  status: () => {
    var allStrings = '';
    for(var key in window.localStorage){
      if(window.localStorage.hasOwnProperty(key)){
        allStrings += window.localStorage[key];
      }
    }
    return allStrings ? 3 + ((allStrings.length*16)/(8*1024)) + ' KB' : 'Empty (0 KB)';
  },

  clear: () => {
    localStorage.clear();
  },

  del: (projId) => {
    for( var key in window.localStorage ){
      if(window.localStorage.hasOwnProperty(key)){
        const idx = key.indexOf('_')
        if( key.substring(0,idx) === projId ){
          localStorage.removeItem( key );
        }
      }
    }
/**
    for( var key in window.localStorage ){
      if(window.localStorage.hasOwnProperty(key)){
        console.log( key );
      }
    }
**/
  },

  set : (projId, catId, value) => {
    const key = projId + "_" + catId;
    localStorage.setItem(
      key, 
      JSON.stringify(value, bundle.jsonMapReplacer),
    );
  },

  get : (projId, catId) => {
    const key = projId + "_" + catId
    const data = JSON.parse( localStorage.getItem(key), bundle.jsonMapReviver,);
    return data;
  },

  setUid : (value) => {
    const key = bundle.key.login_uid
    localStorage.setItem(
      key, 
      JSON.stringify(value, bundle.jsonMapReplacer),
    );
  },

  getUid : () => {
    const key = bundle.key.login_uid
    const data = JSON.parse( localStorage.getItem(key), bundle.jsonMapReviver,);
    return data;
  },
}


