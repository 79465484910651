import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

/* Icons **/
import PanTool from '@material-ui/icons/PanToolOutlined';
import AppendIcon from '@material-ui/icons/LibraryAdd';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: 'auto',
    backgroundColor: 'white',
    position: 'absolute',
  },


  divider: {
    alignSelf: 'stretch',
    border: `0px solid ${theme.palette.divider}`,
    width: 'auto',
    backgroundColor: 'white',
    margin: theme.spacing(0.1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexDirection: 'column',      // 'column',
  },
  grouped: {
    margin: theme.spacing(0),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
/*  groupedSizeSmall: {
    padding: '0px',
    width: '30px',
    height: '30px',
  },
**/
}))(ToggleButtonGroup);


export default function MapToolbar(props) {
//  const anchorRef = React.useRef(null);

  const [tool, setTool] = React.useState('select');
  const [action, setAction] = React.useState('new');

  const handleActionChange = (event, newAction) => {
    setAction(newAction);
    props.onActionChange(newAction);
  };

  const handleToolChange = (event, newTool) => {
    setTool(newTool);
    props.onToolChange(newTool);
  };

  const classes = useStyles();


  return (
    <div>

      <Paper elevation={0} className={classes.paper }>
        <StyledToggleButtonGroup
          size="small"
          value={tool}
          exclusive
          onChange={handleToolChange}
          aria-label="current tool"
        >
          <ToggleButton value="pan" aria-label="pan" >
            <PanTool />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider orientation="horizontal" className={classes.divider} />

        <StyledToggleButtonGroup
          size="small"
          value={action}
          exclusive
          onChange={handleActionChange}
          aria-label="default action"
        >
          <ToggleButton value="add" aria-label="add" >
            <AppendIcon />
          </ToggleButton>

        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}
