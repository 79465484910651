import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
//import Button from '@material-ui/core/Button';
//import SyncIcon from '@material-ui/icons/Sync';


/* Icons **/
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import CropFreeIcon from '@material-ui/icons/CropFree';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: 'auto',
    backgroundColor: 'white',
    position: 'absolute',
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexDirection: 'column',
  },
  grouped: {
    margin: theme.spacing(0),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default function MapToolbar(props) {
  const [tool, setTool] = React.useState('select');


  const handleToolChange = (event, newTool) => {
    setTool(newTool);
    props.onToolChange(newTool);
  };

  const classes = useStyles();

/**
  const updateStatInfo = () => {
    props.onDataSync();
  }
**/
  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value={tool}
          exclusive
          onChange={handleToolChange}
          aria-label="current tool"
        >
          <ToggleButton value="select" aria-label="select">
            <CropFreeIcon />
          </ToggleButton>
          <ToggleButton value="pan" aria-label="pan">
            <ControlCameraIcon />
          </ToggleButton>
{/**
          <ToggleButton value="sync" aria-label="sync">
            <SyncIcon onClick={ () => {updateStatInfo()}}> </SyncIcon>
          </ToggleButton>
**/}
        </StyledToggleButtonGroup>

      </Paper>
    </div>
  );
}
