import React                  from "react";
import * as d3                from 'd3';
import Navbar                 from "components/Navbar.js";
import Footer                 from "components/Footer";
import {bundle}               from "shared/helpers";

import { SimData as SIMDATA } from 'shared/simdata';

import {
  TextView,
}                             from './exports';

import {
  Summary,
  Population,
  Housing,
  Competitor,
}                             from './modules';

export function Top ( {brandName, branchName} )
{

  const branchNm = branchName ? '(' + branchName + ')' : '';

  return(
    <div className="top-section container relative mx-auto pt-8 text-center">
      <div>
        <TextView className="text-gray-600"
                      type="title"
                      text={`『 ${brandName} ${branchNm}』 매출 예측 결과`}
        />
      </div>
    </div>
  )
}

export default function Main(prop) 
{
  const uid      = bundle.getUid()

//  const history = useHistory();
//  const [qstring, setQstring] = React.useState(history.location.qstring)
  let PopTrend = {year1Rate: 0, year5Rate: 0}
//  const [popTrend, setPopTrend ] = React.useState({year1Rate: 0, year5Rate: 0})

  const brandinfo  = bundle.get(uid, bundle.key.brand_info)
  const sim  = SIMDATA.get(uid);
  const mainCatFormula = bundle.get(uid, bundle.key.main_cat_formula);

  let inhouse      = 0, 
      takeout      = 0, 
      delivery     = 0, 
      majorityRate = 0,
      keyCat       = '';

  mainCatFormula.forEach( (d) => {
      if( d.id === 'inhouse' ) {
          inhouse = SIMDATA.getCatTotal(uid, 'inhouse')
          majorityRate = majorityRate || sim.inhouse.population ? sim.inhouse.population.popinfo.majorityRate : 0
          keyCat = keyCat || 'inhouse'
      }
      else if( d.id === 'takeout' ) {
          takeout = SIMDATA.getCatTotal(uid, 'takeout')
          majorityRate = majorityRate || sim.takeout.population ? sim.takeout.population.popinfo.majorityRate : 0
          keyCat = keyCat || 'takeout'
      }
      else if( d.id === 'delivery' ) {
          delivery = SIMDATA.getCatTotal(uid, 'delivery')
          majorityRate = majorityRate || sim.delivery.population ? sim.delivery.population.popinfo.majorityRate : 0
          keyCat = keyCat || 'delivery'
      }
  } )

  const brandName   = brandinfo.brandName;
  const branchName  = brandinfo.branchName;
  const population  = bundle.get(uid, bundle.key.region_pop_pyramid);
  const popTimeline = bundle.get(uid, bundle.key.region_pop_timeline);
  const housetype   = bundle.get(uid, bundle.key.region_house_type);
  const summary     = { inhouse: inhouse,
                        takeout: takeout,
                        delivery:delivery,
                        majorityRate: majorityRate,
                      }

  const calPopTrend = (data) => {
    const popChanges  = [];
    data.filter(it => (it.name === 'a64'))
        .forEach((d,i) => {
              d.values.forEach( (it) => {
                popChanges.push({year: it.year, pop: it.population})
            } )
    } )

    const thisYear = d3.max( popChanges.map( (d) => {return d.year} ) );
    const year1Ago = thisYear - 1;
    const year5Ago = thisYear - 5;

    const popThisYear = popChanges.find( it => it.year == thisYear );
    const popYear1Ago = popChanges.find( it => it.year == year1Ago );
    const popYear5Ago = popChanges.find( it => it.year == year5Ago );

/**
    let y1Rate = ((popThisYear.pop - popYear1Ago.pop) * 100) / popYear1Ago.pop
        y1Rate = Math.round((y1Rate * 10)) / 10
    let y5Rate = ((popThisYear.pop - popYear5Ago.pop) * 100) / popYear5Ago.pop
        y5Rate = Math.round((y5Rate * 10)) / 10
**/

    let y1Rate = 0;
    if( popThisYear && popYear1Ago ) {
      y1Rate = ((popThisYear.pop - popYear1Ago.pop) * 100) / popYear1Ago.pop
      y1Rate = Math.round((y1Rate * 100)) / 100
    }

    let y5Rate = 0;
    if( popThisYear && popYear5Ago ) {
      y5Rate = ((popThisYear.pop - popYear5Ago.pop) * 100) / popYear5Ago.pop
      y5Rate = Math.round((y5Rate * 100)) / 100
    }

    PopTrend = {year1Rate: y1Rate, year5Rate: y5Rate}
  }

/**
  const save = (key, value) => {
    bundle.set(uid, key, value)  

    const ret = bundle.get(uid, 'region');
    console.log( ret );
  };
**/

  calPopTrend( popTimeline );

  return (
    <>

      <Navbar />

      <div className="relative h-full w-full pb-8 bg-gray-300" >

        <Top        brandName={brandName} branchName={branchName} />
        <div        className="pt-12" />

        <Summary    simdata={sim} brandName={brandName} branchName={branchName} 
                    population={population} popTrend={PopTrend}
                    keyCat={keyCat} data={summary} />
        <div        className="pt-4" />
        <Population chart1={population} chart2={popTimeline} />
        <div        className="pt-4" />
        <Housing    chart1={housetype}/>
        <div        className="pt-4" />
        <Competitor simdata={sim} uid={uid} brandName={brandName} branchName={branchName} keyCat={keyCat} />

      </div>
      {/* --- [ Footer ] --- */}
      <div className="w-full py-2 bg-transparent">
        <Footer />
      </div>


    </>
  );
}
