import {
        TextView     as __TextView,
}                    from "components/Controls/TextView";
import {
        Button  as __Button,
}                    from "components/Controls/Buttons";

const table_row_height = 'py-3';
export const TextView = ( {color, ...props} ) =>
{

  var clsName = `bg-transparent
                  ${props.className}`;

  return __TextView( clsName, props )
}

//
// Table Header
//
export const TH = ( {color, ...props} ) =>
{

  var clsName = `${table_row_height} font-semibold text-2xl 
                 flex items-center justify-center 
                  ${props.className}`;

  return __TextView( clsName, props )
}

//                 flex items-center justify-center bg-blue-50

//
// Table Sub-header
//
export const TS = ( {color, ...props} ) =>
{

  var clsName = `${table_row_height} font-semibold text-2xl text-gray-700
                 bg-pink-50
                 ${props.className}`;

  return __TextView( clsName, props )
}


//
// Table Row
//
export const TR = ( {color, ...props} ) =>
{

  var clsName = `px-2 ${table_row_height} text-lg text-gray-700
                 text-center border border-gray-200
                 ${props.className}`;

  return __TextView( clsName, props )
}

//
// Table Label
//
export const TL = ( {color, ...props} ) =>
{

  var clsName = `px-3 ${table_row_height} text-lg text-gray-700
                 bg-white font-semibold
                 border border-gray-200
                 ${props.className}`;

  return __TextView( clsName, props )
}

export const  Button = ( {color, ...props} ) =>
{
  let clsName = "";
    clsName = `bg-white text-blue-600 shadow rounded-lg text-xl py-2 px-4
               ${props.className}`;

  return __Button( clsName, props )
}
