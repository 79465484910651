//import React from "react";
import {bundle} from 'shared/helpers';

// 2019년 통계 기준
export const shared = 
{
  'Y2019': {
    dineoutRate:       84.0,            // 외식인구 비율
//    visitRate:       25.7,            // 월 방문외식 이용 빈도
//    deliveryRate:    11.2,            // 월 배달외식 이용 빈도
//    takeoutRate:      5.9,            // 월 포장외식 이용 빈도
    visitFrequency:     7.8,            // 월 방문외식 이용 빈도
    deliveryFrequency:  3.4,            // 월 배달외식 이용 빈도
    takeoutFrequency:   1.8,            // 월 포장외식 이용 빈도
    kfoodVisitRate:    89.4,            // 한식(방문) 이용 비율
    kfoodDeliveryRate: 26.5,            // 한식(배달) 이용 비율
    kfoodTakeoutRate:  25.4,            // 한식(포장) 이용 비율
  }
}
export const featured = 
{
  data: 
  {
    inhouse: {
      '잠재고객'       : {
                         id:'population',
                         name:'잠재고객',
                         val:0,
                         unit:"명",
                         desc:'선택 지역에 거주하고 있는 인구'
                       },
      '통행량'       : {
                         id:'passenger',
                         name:'통행량',
                         val:0,
                         unit:"명",
                         desc:'선택 지역에서 실측한 통행량'
                       },
  
      '객단가'       : {
                         id:'unitPrice', 
                         name:'객단가',       
                         val:0,   
                         unit:"원",  
                         desc:'고객 한명이 1회 방문시 평균적으로 지출하는 금액'
                       },
      '외식비율'     : { 
                         id:'dineoutRate',   
                         name:'외식비율',     
                         val:shared.Y2019.dineoutRate, 
                         unit:"%",   
                         desc:'우리나라 사람들의 외식 인구 비율'
                       },
      '방문외식빈도'    : {
                         id:'visitFrequency', 
                         name:'방문외식 빈도',
                         val:shared.Y2019.visitFrequency,
                         unit:"회",
                         desc:'월 평균 방문 외식 이용 빈도'
                       },
      '일반한식비율'  : {
                         id:'kfood_general', 
                         name:'일반한식 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 52.0 / 100 * 100) / 100,
                         unit:"%",
                         desc:'방문 외식 인구중 일반 한식 이용 비율'
                       },
      '육류구이비율'  : {
                         id:'kfood_meat', 
                         name:'육류구이 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 21.8 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 육류 구이 이용 비율'
                       },
      '국.탕.찜류비율'  : {
                         id:'kfood_stew', 
                         name:'국.탕.찜류 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 15.0 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 국.탕.찜류 이용 비율'
                       },
      '한정식비율'  : {
                         id:'kfood_set', 
                         name:'한정식 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 4.5 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 한정식 이용 비율'
                       },
      '해산물비율'  : {
                         id:'kfood_seafood', 
                         name:'해산물 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 2.0 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 해산물 이용 비율'
                       },
      '면류비율'  : {
                         id:'kfood_noodle', 
                         name:'면류 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 3.4 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 면류 이용 비율'
                       },
      '죽/도시락비율'  : {
                         id:'kfood_congee', 
                         name:'죽/도시락 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 0.2 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 죽/도시락 이용 비율'
                       },
      '구내식당비율'  : {
                         id:'cafe', 
                         name:'구내식당 비율',       
                         val:Math.round( 31.1 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 구내식당 이용 비율'
                       },
      '패스트푸드비율'  : {
                         id:'fastfood', 
                         name:'패스트푸드 비율',       
                         val:Math.round( 65.1 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 패스트푸드 이용 비율'
                       },
      '일식비율'  : {
                         id:'japanese', 
                         name:'일식 비율',       
                         val:Math.round( 46.9 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 일식 이용 비율'
                       },
      '중식비율'  : {
                         id:'chinese', 
                         name:'중식 비율',       
                         val:Math.round( 71.6 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 중식 이용 비율'
                       },
      '분식및김밥비율'  : {
                         id:'casual', 
                         name:'분식 및 김밥 비율',       
                         val:Math.round( 43.1 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 분식 및 김밥 이용 비율'
                       },
      '커피전문점비율'  : {
                         id:'coffee', 
                         name:'커피전문점 비율',       
                         val:Math.round( 43.1 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 커피전문점 이용 비율'
                       },
      '서양식비율'  : {
                         id:'western', 
                         name:'서양식 비율',       
                         val:Math.round( 30.4 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 서양식 이용 비율'
                       },
      '기타외국음식비율'  : {
                         id:'foreign', 
                         name:'기타외국음식 비율',       
                         val:Math.round( 26.9 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 기타외국음식 이용 비율'
                       },
      '치킨전문점비율'  : {
                         id:'chicken', 
                         name:'치킨전문점 비율',       
                         val:Math.round( 55.8 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 치킨전문점 이용 비율'
                       },
      '제과제빵비율'  : {
                         id:'bakery', 
                         name:'제과제빵 비율',       
                         val:Math.round( 54.6 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 제과제빵 이용 비율'
                       },
      '주점비율'  : {
                         id:'bar', 
                         name:'주점 비율',       
                         val:Math.round( 37.8 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 주점 이용 비율'
                       },
      '출장음식비율'  : {
                         id:'catering', 
                         name:'출장음식 비율',       
                         val:Math.round(  4.4 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 출장음식 이용 비율'
                       },
      '이동음식비율'  : {
                         id:'foodtruck', 
                         name:'이동음식 비율',       
                         val:Math.round( 16.9 * 100) / 100,
                         unit:"%",  
                         desc:'방문 외식 인구중 이동음식 이용 비율'
                       },


/**

      '면류방문비율' : { 
                         id:'noodleVisitRate',    
                         name:'면류방문비율',     
                         val:29.28,
                         unit:"%",   
                         desc:'외식 인구 중 면류를 방문 이용하는 사람들의 비율'
                       },
      '면류배달비율' : { 
                         id:'noodleDeliveryRate',
                         name:'면류배달비율',
                         val:3.3,
                         unit:"%",
                         desc:'외식 인구 중 면류를 배달 이용하는 사람들의 비율'
                       },
      '면류포장비율' : { 
                         id:'noodleTakeoutRate',
                         name:'면류포장비율',
                         val:2.3,
                         unit:"%",
                         desc:'외식 인구 중 면류를 포장 이용하는 사람들의 비율'
                       },
  
      '세대수가중치' : { 
                         id:'householdWeight', 
                         name:'세대수가중치', 
                         val:100, 
                         unit:"%",   
                         desc:'해당 지역의 세대수를 분석한 가중치'
                       },
      '아파트가중치' : { 
                         id:'aptWeight',
                         name:'아파트가중치', 
                         val:100, 
                         unit:"%",   
                         desc:'해당 지역의 아파트 분포를 분석한 가중치'
                       },
**/
  
      '시장점유율' : {
                         id:'marketShare',
                         name:'시장점유율',
                         val:100,
                         unit:"%",
                         desc:'해당 지역의 경쟁 업소 분포를 분석한 가중치'
                       },

    },

    delivery: {
      '잠재고객'       : {
                         id:'population',
                         name:'잠재고객',
                         val:0,
                         unit:"명",
                         desc:'선택 지역에 거주하고 있는 인구'
                       },
      '통행량'       : {
                         id:'passenger',
                         name:'통행량',
                         val:0,
                         unit:"명",
                         desc:'선택 지역에서 실측한 통행량'
                       },
  
      '객단가'       : {
                         id:'unitPrice', 
                         name:'객단가',       
                         val:0,   
                         unit:"원",  
                         desc:'고객 한명이 1회 방문시 평균적으로 지출하는 금액'
                       },
      '외식비율'     : { 
                         id:'dineoutRate',   
                         name:'외식비율',     
                         val:shared.Y2019.dineoutRate, 
                         unit:"%",   
                         desc:'우리나라 사람들의 외식 인구 비율'
                       },
      '배달외식빈도'    : {
                         id:'deliveryFrequency', 
                         name:'배달외식 빈도',
                         val:shared.Y2019.deliveryFrequency,
                         unit:"회",  
                         desc:'월 평균 배달 외식 이용 빈도'
                       },
      '일반한식비율'  : {
                         id:'kfood_general', 
                         name:'일반한식 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 49.6 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 일반 한식 이용 비율'
                       },
      '육류구이비율'  : {
                         id:'kfood_meat', 
                         name:'육류구이 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 12.1 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 육류 구이 이용 비율'
                       },
      '국.탕.찜류비율'  : {
                         id:'kfood_stew', 
                         name:'국.탕.찜류 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 17.8 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 국.탕.찜류 이용 비율'
                       },
      '한정식비율'  : {
                         id:'kfood_set', 
                         name:'한정식 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 6.1 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 한정식 이용 비율'
                       },
      '해산물비율'  : {
                         id:'kfood_seafood', 
                         name:'해산물 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 3.0 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 해산물 이용 비율'
                       },
      '면류비율'  : {
                         id:'kfood_noodle', 
                         name:'면류 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 6.1 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 면류 이용 비율'
                       },
      '죽/도시락비율'  : {
                         id:'kfood_congee', 
                         name:'죽/도시락 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 3.9 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'배달 외식 인구중 죽/도시락 이용 비율'
                       },
      '구내식당비율'  : {
                         id:'cafe', 
                         name:'구내식당 비율',       
                         val:3.8,
                         unit:"%",  
                         desc:'배달 외식 인구중 구내식당 이용 비율'
                       },

      '패스트푸드비율'  : {
                         id:'fastfood', 
                         name:'패스트푸드 비율',       
                         val:47.5,
                         unit:"%",  
                         desc:'배달 외식 인구중 패스트푸드 이용 비율'
                       },
      '일식비율'  : {
                         id:'japanese', 
                         name:'일식 비율',       
                         val:12.8,
                         unit:"%",  
                         desc:'배달 외식 인구중 일식 이용 비율'
                       },
      '중식비율'  : {
                         id:'chinese', 
                         name:'중식 비율',       
                         val:62.6,
                         unit:"%",  
                         desc:'배달 외식 인구중 중식 이용 비율'
                       },
      '분식및김밥비율'  : {
                         id:'casual', 
                         name:'분식 및 김밥 비율',       
                         val:12.6,
                         unit:"%",  
                         desc:'배달 외식 인구중 분식 및 김밥 이용 비율'
                       },
      '커피전문점비율'  : {
                         id:'coffee', 
                         name:'커피전문점 비율',       
                         val:5.5,
                         unit:"%",  
                         desc:'배달 외식 인구중 커피전문점 이용 비율'
                       },
      '서양식비율'  : {
                         id:'western', 
                         name:'서양식 비율',       
                         val:9.0,
                         unit:"%",  
                         desc:'배달 외식 인구중 서양식 이용 비율'
                       },
      '기타외국음식비율'  : {
                         id:'foreign', 
                         name:'기타외국음식 비율',       
                         val:5.3,
                         unit:"%",  
                         desc:'배달 외식 인구중 기타외국음식 이용 비율'
                       },
      '치킨전문점비율'  : {
                         id:'chicken', 
                         name:'치킨전문점 비율',       
                         val:70.0,
                         unit:"%",  
                         desc:'배달 외식 인구중 치킨전문점 이용 비율'
                       },
      '제과제빵비율'  : {
                         id:'cake', 
                         name:'제과제빵 비율',       
                         val:9.2,
                         unit:"%",  
                         desc:'배달 외식 인구중 제과제빵 이용 비율'
                       },
      '주점비율'  : {
                         id:'bar', 
                         name:'주점 비율',       
                         val:2.5,
                         unit:"%",  
                         desc:'배달 외식 인구중 주점 이용 비율'
                       },
      '출장음식비율'  : {
                         id:'catering', 
                         name:'출장음식 비율',       
                         val:2.6,
                         unit:"%",  
                         desc:'배달 외식 인구중 출장음식 이용 비율'
                       },
      '이동음식비율'  : {
                         id:'foodtruck', 
                         name:'이동음식 비율',       
                         val:3.1,
                         unit:"%",  
                         desc:'배달 외식 인구중 이동음식 이용 비율'
                       },
      '시장점유율' : {
                         id:'marketShare',
                         name:'시장점유율',
                         val:100,
                         unit:"%",
                         desc:'해당 지역의 경쟁 업소 분포를 분석한 가중치'
                       },

    },

    takeout: {
      '잠재고객'       : {
                         id:'population',
                         name:'잠재고객',
                         val:0,
                         unit:"명",
                         desc:'선택 지역에 거주하고 있는 인구'
                       },
      '통행량'       : {
                         id:'passenger',
                         name:'통행량',
                         val:0,
                         unit:"명",
                         desc:'선택 지역에서 실측한 통행량'
                       },
  
      '객단가'       : {
                         id:'unitPrice', 
                         name:'객단가',       
                         val:0,   
                         unit:"원",  
                         desc:'고객 한명이 1회 방문시 평균적으로 지출하는 금액'
                       },
      '외식비율'     : { 
                         id:'dineoutRate',   
                         name:'외식비율',     
                         val:shared.Y2019.dineoutRate, 
                         unit:"%",   
                         desc:'우리나라 사람들의 외식 인구 비율'
                       },
      '포장외식빈도'  : {
                         id:'takeoutFrequency', 
                         name:'포장외식 빈도',
                         val:shared.Y2019.takeoutFrequency,
                         unit:"회",  
                         desc:'월 평균 포장 외식 이용 빈도'
                       },
      '일반한식비율'  : {
                         id:'kfood_general', 
                         name:'일반한식 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 35.4 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 일반 한식 이용 비율'
                       },
      '육류구이비율'  : {
                         id:'kfood_meat', 
                         name:'육류구이 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate *  9.2 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 육류 구이 이용 비율'
                       },
      '국.탕.찜류비율'  : {
                         id:'kfood_stew', 
                         name:'국.탕.찜류 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 30.6 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 국.탕.찜류 이용 비율'
                       },
      '한정식비율'  : {
                         id:'kfood_set', 
                         name:'한정식 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 3.0 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 한정식 이용 비율'
                       },
      '해산물비율'  : {
                         id:'kfood_seafood', 
                         name:'해산물 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 5.8 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 해산물 이용 비율'
                       },
      '면류비율'  : {
                         id:'kfood_noodle', 
                         name:'면류 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 3.7 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 면류 이용 비율'
                       },
      '죽/도시락비율'  : {
                         id:'kfood_congee', 
                         name:'죽/도시락 비율',       
                         val:Math.round(shared.Y2019.kfoodVisitRate * 8.5 / 100 * 100) / 100,
                         unit:"%",  
                         desc:'포장 외식 인구중 죽/도시락 이용 비율'
                       },
      '구내식당비율'  : {
                         id:'cafe', 
                         name:'구내식당 비율',       
                         val:2.8,
                         unit:"%",  
                         desc:'포장 외식 인구중 구내식당 이용 비율'
                       },
      '패스트푸드비율'  : {
                         id:'fastfood', 
                         name:'패스트푸드 비율',       
                         val:49.8,
                         unit:"%",  
                         desc:'포장 외식 인구중 패스트푸드 이용 비율'
                       },
      '일식비율'  : {
                         id:'japanese', 
                         name:'일식 비율',       
                         val:16.7,
                         unit:"%",  
                         desc:'포장 외식 인구중 일식 이용 비율'
                       },
      '중식비율'  : {
                         id:'chinese', 
                         name:'중식 비율',       
                         val:13.9,
                         unit:"%",  
                         desc:'포장 외식 인구중 중식 이용 비율'
                       },
      '분식및김밥비율'  : {
                         id:'casual', 
                         name:'분식 및 김밥 비율',       
                         val:25.0,
                         unit:"%",  
                         desc:'포장 외식 인구중 분식 및 김밥 이용 비율'
                       },
      '커피전문점비율'  : {
                         id:'coffee', 
                         name:'커피전문점 비율',       
                         val:19.5,
                         unit:"%",  
                         desc:'포장 외식 인구중 커피전문점 이용 비율'
                       },
      '서양식비율'  : {
                         id:'western', 
                         name:'서양식 비율',       
                         val:5.3,
                         unit:"%",  
                         desc:'포장 외식 인구중 서양식 이용 비율'
                       },
      '기타외국음식비율'  : {
                         id:'foreign', 
                         name:'기타외국음식 비율',       
                         val:5.7,
                         unit:"%",  
                         desc:'포장 외식 인구중 기타외국음식 이용 비율'
                       },
      '치킨전문점비율'  : {
                         id:'chicken', 
                         name:'치킨전문점 비율',       
                         val:36.7,
                         unit:"%",  
                         desc:'포장 외식 인구중 치킨전문점 이용 비율'
                       },
      '제과제빵비율'  : {
                         id:'cake', 
                         name:'제과제빵 비율',       
                         val:32.1,
                         unit:"%",  
                         desc:'포장 외식 인구중 제과제빵 이용 비율'
                       },
      '주점비율'  : {
                         id:'bar', 
                         name:'주점 비율',       
                         val:2.0,
                         unit:"%",  
                         desc:'포장 외식 인구중 주점 이용 비율'
                       },
      '출장음식비율'  : {
                         id:'catering', 
                         name:'출장음식 비율',       
                         val:2.3,
                         unit:"%",  
                         desc:'포장 외식 인구중 출장음식 이용 비율'
                       },
      '이동음식비율'  : {
                         id:'foodtruck', 
                         name:'이동음식 비율',       
                         val:7.8,
                         unit:"%",  
                         desc:'포장 외식 인구중 이동음식 이용 비율'
                       },
      '시장점유율' : {
                         id:'marketShare',
                         name:'시장점유율',
                         val:100,
                         unit:"%",
                         desc:'해당 지역의 경쟁 업소 분포를 분석한 가중치'
                       },

    },
  },

  get: (projId) =>
  {
    const feat = bundle.get(projId, bundle.key.featured_data )

    return feat
  },

  set: (projId, name, val) => 
  {
    const feat = bundle.get(projId, bundle.key.featured_data )
    feat[name].val = val

    bundle.set(projId, bundle.key.featured_data, feat)

  },

  init: (projId) =>
  {
    bundle.set(projId, bundle.key.featured_data, featured.data)
  }

}
