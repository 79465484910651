import {
  Button       as __Button,
  TextView     as __TextView,
} from "exports.js";


export function TextView( {className, ...props} ) {

  var clsName = '';
  switch( props.type )
  {

    case 'xxlarge' :
         clsName = `flex bg-transparent rounded justify-center
                      text-4xl font-bold ${className}`
         break

    case 'title' :
         clsName = `bg-transparent rounded py-2 px-3
                      text-2xl font-bold ${className}`
         break
    case 'large' :
    case 'subtitle' :
         clsName = `bg-transparent rounded py-2 px-3
                      text-xl font-semibold ${className}`
         break
    case 'small' :
         clsName = `bg-transparent rounded 
                      text-xs font-normal ${className}`
         break
    case 'normal' :
    default :
         clsName = `bg-transparent rounded px-3 
                      font-normal ${className}`
         break
  }

  return __TextView( clsName, props )
}

export function Button( {className, color, ...props} ) {

  var clsName = `py-2 px-4 h-full
                  bg-teal-500 hover:bg-${color}-700 text-white
                  bg-${color}-300
                  ${className} `;


  return __Button( clsName, props )
}

