import React from "react";
//import { Fragment, useState }   from 'react'
import { Fragment }   from 'react'
import {useHistory}             from 'react-router-dom';

//import LaunchIcon               from '@material-ui/icons/Launch';
//import InfoIcon                 from '@material-ui/icons/InfoOutlined';
import PersonIcon               from '@material-ui/icons/Person';
import Dialog                   from '@material-ui/core/Dialog';
import DialogTitle              from '@material-ui/core/DialogTitle';
import DialogContent            from '@material-ui/core/DialogContent';

import { initializeApp }        from 'firebase/app';
import { getAuth,
         signOut,
         signInWithPopup,
         onAuthStateChanged,
         GoogleAuthProvider }   from "firebase/auth";

import * as Config              from "config";
import {bundle}                 from 'shared/helpers';
import {firebaseConfig}         from "shared/firebase";

import {
        Card,
//        CardHeader,
        CardBody,
        CardFooter,
        CardButton,
}                               from "components/Controls/Card";

//import DropDown                 from "components/Controls/DropDownSample";

//import {LinkView}               from './res/NavbarRes'
import {
//        Btn, 
        TextView, 
//        MenuItem,
//        SignButton
}                               from './wrapper'

const provider = new GoogleAuthProvider();

const SignedIn = ( {cbSetOpenAccount, user, ...props} ) => {

  return(

    <div className="flex rounded-xl px-2 py-2 bg-blue-500 border justify-center items-center cursor-pointer"
         tabIndex="1" 
         onClick={() => {cbSetOpenAccount('block')}}
         onBlur={(e) => {cbSetOpenAccount('hidden')}} >
      <PersonIcon fontSize="large" style={{color:'white', fontSize:'32' }} />
        <TextView className="text-sm text-white font-semibold" text={user.displayName} />
    </div>
  )
}

const SignedOut = ( {cbSetUser, ...props} ) => {


//  const history = useHistory();
//  const gotoPricing = (val) => history.push({pathname: '/pricing',  qstring: val });

  const handleSignin = () => {
    initializeApp(firebaseConfig);

    const auth = getAuth();

    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      GoogleAuthProvider.credentialFromResult(result);
//      const credential = GoogleAuthProvider.credentialFromResult(result);
//      token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      cbSetUser( user )
      // ...
    }).catch((error) => {
      // Handle Errors here.
//      const errorCode = error.code;
//      const errorMessage = error.message;
      // The email of the user's account used.
//      const email = error.email;
      // The AuthCredential type that was used.
//      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }

  return(

    <div className="flex rounded-full px-2 justify-center items-center cursor-pointer border" >
      <TextView className="text-lg text-gray-500 py-2 font-semibold" text={'Sign in'} 
                onClick={() => {handleSignin()}}/>
    </div>
  )
}

const Pricing = ( {cbPricing, ...props} ) => {

  return (
    <ul className="flex flex-col lg:flex-row list-none mr-auto cursor-pointer">
      <li className="flex items-center">
        <div
           className={
             (props.transparent
               ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
               : "text-gray-800 hover:text-gray-600") +
             " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
           }
           onClick={ () => {cbPricing()} }

        >
          <span className="hidden lg:inline-block ml-2">Pricing</span>
          <i className={
               (props.transparent
                 ? "lg:text-gray-300 text-gray-500"
                 : "text-gray-500") +
               " fas fa-coins text-lg leading-lg lg:ml-2"
             }
          />{" "}
          <span className="lg:hidden md:inline-block ml-2">Pricing</span>
        </div>
      </li>
    </ul>
  )

}


export default function Navbar(props) {

  const [navbarOpen, setNavbarOpen] = React.useState(false);


  const history = useHistory();
  const gotoSearch   = (val) => history.push({pathname: '/search',  qstring: val });
  const gotoForecast = (val) => history.push({pathname: '/home',    qstring: val });
  const gotoPricing  = (val) => history.push({pathname: '/pricing', qstring: val });
  const cfg = Object.assign({}, Config );


//  const [ diskusage,  setDiskusage  ] = React.useState(bundle.status())
//  const [ show,       setShow       ] = React.useState(false)
  const [ openIntro,  setOpenIntro  ] = React.useState(false)
  const [ user,       setUser       ] = React.useState(null)
  const [ openAccount,setOpenAccount] = React.useState('hidden')
  const [ brands,     setBrands     ] = React.useState('')

  async function ubiSignin( uinfo ) {

/**
      console.log( uinfo );
      console.log( `${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/auth/signin` );
**/
    // insert transaction
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/auth/signin`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({ uid: uinfo.uid, accessToken: uinfo.accessToken, email: uinfo.email })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { 
       return response.json() 
     })
    .then(data => {

      setBrands( data );
      bundle.set(user.uid, bundle.key.auth_projects, data );
      const prj = bundle.get(user.uid, bundle.key.auth_projects );

      console.log( data );
      console.log( prj );

    })
    .catch( error => {
//       setLoadingState( LOADING_DONE );
    });

  }

/**
  const handleReset = () => {
    bundle.clear();
  }
**/

  const handleIntro = () => {
    setOpenIntro( true );

  }

  const handleSignout = () => {
    const auth = getAuth();
    signOut(auth)
    .then( () => {
     })
    .catch( (error) => {
     })
  }

  const handlePricing = () => {
    const qstring = {}
    gotoPricing( qstring );
  }

  const handleSearch = () => {
      if( user ) {
        const qstring = {}
        gotoSearch( qstring );
      }
      else {
        const qstring = {}
        gotoPricing( qstring );
      }
  }

  const handleForecast = () => {
      if( user ) {
        const qstring = {uid: user.uid, brands: brands}
    console.log( qstring )
        gotoForecast( qstring );
      }
      else {
        const qstring = {}
        gotoPricing( qstring );
      }
  }

/**
  const handleOpenAccount = (val) => {
    console.log( val )
  }
**/

  React.useEffect( () => {
    initializeApp(firebaseConfig);

    const auth = getAuth();
    onAuthStateChanged( auth, (user) => {

      if( user ) {
        setUser( user );
        bundle.setUid(user.uid );
        bundle.set(user.uid, bundle.key.auth_user, user );
        ubiSignin( user )
      }
      else {
        setUser( null );
      }
    });

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>

      <nav
        className={
          (props.transparent
            ? "top-0 absolute z-50 w-full"
            : "relative shadow-lg bg-white shadow-lg") +
          " flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg"
        }
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div 
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " text-sm font-bold leading-relaxed inline-block py-2 whitespace-no-wrap nouppercase"
              }
            >
              <a className="mr-1" href="/" >
                UbiForecast 매출 예측 (Beta)
              </a>

              {/* infomation icon*/}
              <i
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " fas fa-info-circle cursor-pointer"
                }
                onClick={() => {handleIntro()}}
              ></i>
            </div>

            {/* hamburger button */}
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " fas fa-bars"
                }
              ></i>
            </button>

          </div>

          <div
            className={
              "lg:flex flex-grow items-center lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >

            <Pricing cbPricing={handlePricing}/>

            {/* menu items */}
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

              {/* 매출 예측 */}
              <li className="flex items-center cursor-pointer">
                <div
                  className={
                    (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={ () => {handleForecast()} }
                >
                  <i
                    className={
                      (props.transparent
                        ? "lg:text-gray-300 text-gray-500"
                        : "text-gray-500") +
                      " fas fa-chart-line text-lg leading-lg "
                    }
                  />
                  <span className="lg:nohidden inline-block ml-2">매출예측</span>
                </div>
              </li>

              {/* 상권 검색 */}
              <li className="flex items-center cursor-pointer">
                <div
                  className={
                    (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={ () => {handleSearch()} }
                >
                  <i
                    className={
                      (props.transparent
                        ? "lg:text-gray-300 text-gray-500"
                        : "text-gray-500") +
                      " fas fa-search-location text-lg leading-lg "
                    }
                  />
                  <span className="lg:nohidden inline-block ml-2">상권검색</span>
                </div>
              </li>

              {/* sign-in / sign-out button */}
              <li className="flex items-center">
                <div className="pr-0" >
                {
                  user ? <SignedIn cbSetOpenAccount={setOpenAccount} user={user} /> 
                       : <SignedOut cbSetUser={setUser} />
                }
                </div>

              {/* sign-out popup */}
                <div className={`absolute lg:right-6 mt-64 lg:mt-64 bg-transparent z-10 ${openAccount}`} >

                  <Card color="white" className="divide-y divide-gray-200 border-none">

                    <CardBody color={{bg:'white', fg:'gray'}} className={`px-2 pl-2 py-6`} >
                      <div className="grid grid-cols-3">
                        <div className="row-span-2 flex justify-center items-center" >
                          <PersonIcon style={{fontSize:48}} />
                        </div>
                        <TextView className="py-1 text-gray-900 col-span-2" 
                                  text={user && user.displayName} />
                        <TextView className="text-sm py-1 text-indigo-400 col-span-2" 
                                  text={user && user.email} />
                      </div>
                    </CardBody>

                    <CardFooter color={{bg:'white', fg:'gray'}} className={`py-4`}>
                      <CardButton color={{bg:'white', fg:'gray'}} className="text-right" tabIndex="0"
                                  onMouseDown={() => {handleSignout()}} >
                        <TextView className="text-normal text-blue-600" 
                                  text='SIGN OUT' 
                                  onMouseDown={() => {handleSignout()}}/>
                      </CardButton>
                    </CardFooter>
                  </Card>

                </div>
              </li>
            </ul>

          </div>
        </div>

        <Dialog onClose={() => {setOpenIntro(false)}} fullWidth={true} maxWidth={'md'} open={openIntro} >
          <DialogTitle> <span className="font-bold">Ubiz 매출 예측 시스템</span> </DialogTitle>
          <DialogContent className="mb-4">
            <TextView className="py-2 font-semibold text-lg">
              Ubiz 매출 예측 시스템을 활용하여 상가점포의 매출을 손쉽게 예측할 수 있습니다.
            </TextView>
            <TextView className="py-2">
              매출 예측은 창업 예정 지역의 인구를 기반으로 하는 방법과
              점포 앞 통행량을 기반으로 하는 두가지 방법을 제공합니다.
            </TextView>
            <TextView className="py-2">
              <span className="text-blue-700 font-bold">인구 기반 매출 예측</span>은
              선택 지역의 인구정보를 기반으로 한 매출 예측으로, 창업 지역 물색 과정의 초기 참고자료로
              활용하는 것을 추천합니다.
            </TextView>
            <TextView className="py-2">
              <span className="text-blue-700 font-bold">통행량 기반 매출 예측</span>은
              점포 앞 통행량을 실측한 결과를 기반으로 한 비교적 정확한 매출 예측 방법입니다. (서비스 준비중)
            </TextView>

          </DialogContent>
        </Dialog>

      </nav>
    </>
  );
}
