import React               from "react";
import {useHistory}        from 'react-router-dom';

import CloseIcon           from '@material-ui/icons/Close';
//import ArrowDropDownIcon   from '@material-ui/icons/ArrowDropDown';
import CircularProgress    from '@material-ui/core/CircularProgress';
import { DataGrid, 
         GridToolbar,
}                          from '@material-ui/data-grid';

import * as Config         from "config";

import Navbar              from "components/Navbar.js";
import Footer              from "components/Footer";
import {strings}           from "shared/strings";
import {bundle}            from "shared/helpers";
import { featured as FEATURED} from 'shared/featured';

import {
         Button, 
         IconButton, 
         EditText, 
         TextView, 
         PillText, 
         Radio,
}                          from "./wrapper.js";

const S = strings.Register

const Init = ({close, prev, next, open}) => 
{

  const brandName = S.step1.brand_nm_example;
  const branchName   = S.step1.branch_nm_example;
  const disableBtn1  = false;

  if( open ) {
    return(
      <>
        <div className="min-h-screen w-full bg-gray-100" >
  
          <div className="top-section relative pt-24 lg:px-36 sm:px-12 md:px-24 bg-transparent bg-blue-000">

            <IconButton text="기본 정보 설정 (Step 1 of 2)">
              <CloseIcon fontSize="default" onClick={close}/>
            </IconButton>

            <div className="relative pt-24 w-full">
              <TextView type="title" text={S.step1.page_title}
                        className="inline-flex text-gray-600 text-4xl"/>
            </div>

            {/* --- [ 브랜드명 입력] --- */}
            <div className="bg-purple-000 pt-24 md:w-1/2">
              <div className="">
                <TextView text={S.step1.brand_nm_label} className="text-normal"/>
                <EditText hint={S.step1.brand_nm_hint} className="text-2xl bg-gray-100" 
                          text={brandName}
                          onChange={() => {}} />

              </div>
              <div className="flex bg-purple-000 pt-4 lg:w-1/3 md:w-1/2 sm:w-full">
                <PillText color="gray" text={S.step1.brand_nm_example} />
              </div>
            </div>

            {/* --- [ 지점명 입력] --- */}
            <div className="bg-purple-000 pt-12 md:w-1/2">
              <div className="">
                <TextView text={S.step1.branch_nm_label} className="text-normal"/>
                <EditText hint={S.step1.branch_nm_hint} className="text-2xl bg-gray-100"
                          text={branchName}
                          onChange={() => {}} />
              </div>
              <div className="flex bg-purple-000 pt-4 lg:w-1/3 md:w-1/2 sm:w-full">
                <PillText className="" color="gray" text={S.step1.branch_nm_example} />
              </div>
            </div>

            <div className="pt-12">
              <Button color="blue" disabled={disableBtn1} 
                      onClick={() => next(brandName, branchName)} >
                <TextView text='Continue' className="text-normal"/>
              </Button>
            </div>
          </div>

        </div>
      </>
    )
  }
  else {
    return( 
      <>
      </>
    )
  }
}

const Conf = ({close, prev, next, open}) =>
{

  const cfg = Object.assign({}, Config );
  const dummy = [
    {no:'1', id:"대표업종이 아직 선택되지 않았습니다." }
  ]
  const fError = [
    {no:'1', id:"서버 연결에 실패하였습니다. 잠시 후 다시 시도해 주십시오." }
  ]

  const LOADING_INIT       = 10001;
  const LOADING_INPROGRESS = 10002;
  const LOADING_DONE       = 10009;
  const LOADING_ERROR      = 90001;

  const [loadingState, setLoadingState] = React.useState(LOADING_INIT)
  const [class1,       setClass1]       = React.useState(null);
  const [class3,       setClass3]       = React.useState(null);
  const [industry,     setIndustry]     = React.useState({});
  const [classSelected,  setClassSelected]     = React.useState('');
  const [ disableBtn1, setDisableBtn1 ]  = React.useState(true);

  const radio = [
    {id:'cd1', name: '음식'          },
    {id:'cd2', name: '소매'          },
    {id:'cd3', name: '생활서비스'    },
    {id:'cd4', name: '학문/교육'     },
    {id:'cd5', name: '관광/여가/오락'},
    {id:'cd6', name: '숙박'          },
    {id:'cd7', name: '의료'          },
    {id:'cd8', name: '부동산'        },
  ]


  async function fetchClass3(class1Nm) {

    const class3s = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/class3Demo`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({'class1_nm': class1Nm })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     }) 
    .then(response => { return response.json() } )
    .then(data => {
       data = data.map( (d,i) => {
                return( Object.assign({'no':i+1, 'id': d.class3_nm, 'class2_nm':d.class2_nm, 'sic_nm':d.sic_nm})) 
              })

       class3s['columns'] = theaderStores(Object.keys(data[0]))
       class3s['rows'] = data

       setClass3(class3s)

       setLoadingState( LOADING_DONE )
    })
    .catch( error => {
       setLoadingState( LOADING_ERROR )
    });
  }

  const theaderStores = (header) => {

    const format = [
          {'hid': 'no',        'headerName': 'No',            'hide' : false, 'headerAlign': 'center',
                               'resizable' : false,           'width': 100, 'type': 'number'},
          {'hid': 'id',        'headerName': '업종소분류',    'hide' : false,
                               'resizable' : false,           'width': 1000, 'type': 'string'},
          {'hid': 'class3_nm', 'headerName': '업종소분류',    'hide' : false, 
                               'resizable' : false,           'width': 600, 'type': 'string'},
          {'hid': 'class2_nm', 'headerName': '업종중분류',    'hide' : true, 
                               'resizable' : false,           'width': 200, 'type': 'string'},
          {'hid': 'sic_nm',    'headerName': '표준산업분류',  'hide' : true,
                               'resizable' : false,           'width': 200, 'type': 'string'},
          {'hid': 'dummy',     'headerName': ' ',             'hide' : false,
                               'resizable' : false,           'width': 600, 'type': 'string'},
    ]
    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i]
      const item = format.find( it => it.hid === hid )

      columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'headerName': item ? item.headerName : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string',
          'valueGetter': item ? item.valueGetter : '',
          'headerAlign': 'center'
      })
    }

    return( columns )
  }

  const handleSelection = (e) => {

    let selected = ''
    if( typeof e.selectionModel === 'object' && e.selectionModel[0] ) {
      selected = e.selectionModel[0];
    }
    else if( typeof e === 'object' && e[0] ) {
      console.log( e[0] );
      selected = e[0];
    }
    const sicNm = class3.rows.filter( (d) => {
      return d.id === selected;
    })

//    setIndustry( {class1_nm: class1, class3_nm: sicNm[0].class3_nm, sic_nm: sicNm[0].sic_nm} );
    setIndustry( {class1_nm: class1, class3_nm: sicNm[0].id, sic_nm: sicNm[0].sic_nm} );
    setDisableBtn1( false );
    setClassSelected( `${class1}(대표업종) , ${selected}(세부업종) 을 선택하였습니다.` );
  }

  const handleNext = () => {
    next(industry)
  }

  const handleSelectClass1 = (val) => {

    setClass1(val);
    setLoadingState( LOADING_INPROGRESS )
    setDisableBtn1( true );
    setClassSelected('');
    fetchClass3(val);
  }

  if( open ) {
    return(
      <>
        <div className="min-h-screen w-full bg-gray-100" >
{/**
        <div className="relative md:min-h-screen w-full bg-gray-100" >
**/}

{/**
          <div className="top-section relative mx-auto pt-24 px-36 bg-transparent bg-blue-000">
**/}
          <div className="top-section relative pt-24 lg:px-36 sm:px-12 md:px-24 bg-transparent bg-blue-000">

            <IconButton text="Create a project(Step 2 of 2)">
              <CloseIcon fontSize="default" onClick={close}/>
            </IconButton>

            <div className="relative pt-24 w-full">
              <TextView type="title" text={S.step2.page_title}
                        className="inline-flex text-gray-600 text-4xl"/>
            </div>
            <TextView text={S.step2.page_desc}
                      className="text-gray-600 text-xl pt-4"/>

            <div className="pt-16 w-1/3">
              <TextView type="subtitle" text={S.step2.class1_title}
                        className="inline-flex text-gray-600 text-2xl"/>
            </div>
            <TextView text={S.step2.class1_desc}
                      className="text-gray-600 text-normal pt-4"/>
            <div className="pt-8 grid lg:w-2/3 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">

              <Radio id={radio[0].id} name='radio'
                   className="text-2xl font-bold"
                   alignX text={radio[0].name} 
                   onChange={(e) => {handleSelectClass1(radio[0].name)}} />

              <Radio id={radio[1].id} name='radio'
                   alignX text={radio[1].name} 
                   disabled />

              <Radio id={radio[2].id} name='radio'
                   alignX text={radio[2].name} 
                   disabled />

              <Radio id={radio[3].id} name='radio'
                   alignX text={radio[3].name} 
                   disabled />

              <Radio id={radio[4].id} name='radio'
                   alignX text={radio[4].name} 
                   disabled />

              <Radio id={radio[5].id} name='radio'
                   alignX text={radio[5].name} 
                   disabled />

              <Radio id={radio[6].id} name='radio'
                   alignX text={radio[6].name} 
                   disabled />

              <Radio id={radio[7].id} name='radio'
                   alignX text={radio[7].name} 
                   disabled />

            </div>

            <div className="relative pt-12 w-3/3">
              <TextView type="subtitle" text={S.step2.class3_title} style={{whiteSpace:'pre-line'}}
                        className="inline-flex text-gray-600 text-2xl"/>
            </div>
            <div className="py-4 w-3/3">
              <TextView text={S.step2.class3_desc} className="text-gray-600 text-normal"/>
            </div>
            <div className="w-3/3 items-center w-full lg:w-2/3">
              <div className="text-left lg:text-right">
                <TextView className="text-gray-600 text-normal"
                          text={classSelected} />
              </div>
              <div className="text-right pt-2">
                <Button color="blue" disabled={disableBtn1} onClick={handleNext} className="">
                  <TextView text="Continue" />
                </Button>
              </div>
            </div>
            <div className="py-4 w-3/3 lg:w-2/3"  >
            {
              loadingState === LOADING_DONE && class3 &&
                <DataGrid
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  columns={class3.columns}
                  rows={class3.rows}
                  autoHeight={true}
                  pageSize={25}

                  checkboxSelection={false}
                  onSelectionModelChange={(e) => {handleSelection(e)} }
                />
            }

            {
              loadingState === LOADING_INIT &&
                <DataGrid
                  className="h-64"
                  columns={theaderStores(Object.keys(dummy[0]))}
                  rows={dummy}
                  pageSize={25}
                  autoHeight={true}
                  checkboxSelection={false}
                />
            }

            {
              loadingState === LOADING_ERROR &&
                <DataGrid
                  className="h-64"
                  columns={theaderStores(Object.keys(fError[0]))}
                  rows={fError}
                  pageSize={25}
                  autoHeight={true}
                  checkboxSelection={false}
                />
            }

            {
              loadingState === LOADING_INPROGRESS &&
                <div className="w-full py-4 text-center">
                  <CircularProgress disableShrink />
                </div>
            }
            </div>

            <TextView className="text-gray-600 text-normal pt-12"
                      text={classSelected} />
            <div className="flex flex-col md:flex-col lg:flex-row px-16 space-y-4 lg:space-y-0 py-8 lg:space-x-24">
              <Button color="blue" onClick={prev} outlined >
                <TextView text="Previous" />
              </Button>
              <Button color="blue" disabled={disableBtn1} onClick={handleNext} >
                <TextView text="Continue" />
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
  else {
    return(
      <>
      </>
    )
  }
}


/**
const Auth = ({close, prev, next, open}) => 
{

  const selections = [
    {
      id: 1,
      name: "Sign in",
      href: "https://www.ubimemes.com"
    },
    {
      id: 2,
      name: "create a new account",
      href: "http://www.google.com"
    },
  ]

  if( open ) {
    return(
    <>
      <div className="relative md:min-h-screen w-full bg-gray-100" >

        <div className="top-section relative mx-auto pt-24 px-36 bg-transparent bg-blue-000">

          <div className="flex bg-purple-000">
            <CloseIcon fontSize="default"/>
            <TextView type="default" text="Create a project(Step 2 of 2)" 
                        className="inline-flex pl-2 text-gray-600"/>
          </div>

          <div className="relative bg-purple-000 pt-24 w-1/3">
            <TextView type="title" text="Configure my business"
                      className="inline-flex text-gray-600 text-4xl"/>
          </div>

          <div className="pt-24 w-1/3">
            <Dropdown title="Choose or create an account" items={selections} >
              <ArrowDropDownIcon fontSize="default"/>
            </Dropdown>
          </div>

          <div className="relative pt-8 w-2/3">
            <TextView type="small" text="Upon project creation, a new Google Analytics property will be created in your chosen Google Analytics account and linked to your Firebase project. This link will enable data flow between the products. Data exported from your Google Analytics property into Firebase is subject to the Firebase terms of service, while Firebase data imported into Google Analytics is subject to the Google Analytics terms of service"
                      className="inline-flex text-gray-400"/>
          </div>

          <div className="pt-12 space-x-24">
            <Button text="Previous" color="blue" onClick={prev} outlined />
            <Button text="Continue" color="blue" onClick={next} />
          </div>
        </div>

      </div>
    </>
    )
  }
  else
  {
    return( 
      <>
      </>
    )
  }
}
**/

export default function Main() {

  const uid      = "demo"

  const history = useHistory();
  const gotoNext = (val) => history.push({pathname: '/demo/region', qstring: val });

  const [ openInit,   setOpenInit   ] = React.useState(true)
  const [ openConfig, setOpenConfig ] = React.useState(false)

//  FEATURED.init("default");
  FEATURED.init(uid);

  const gotoStep1 = () => {
    setOpenInit( true );
    setOpenConfig( false );
  }

  const gotoStep2 = (brandName, branchName) => {

    const brandInfo = {
      brandId    : brandName,
      brandName  : brandName,
      branchId   : branchName,
      branchName : branchName,
      dataReady  : false,
    }
    bundle.set(uid, bundle.key.brand_info, brandInfo)
//    bundle.set("default", bundle.key.brand_info, brandInfo)

    setOpenInit( false );
    setOpenConfig( true );
  }

  const gotoNextStep = (val) => {
    const querystring = {
      id: 'myid'
    }
//    bundle.set("default", bundle.key.classifications, val)
    bundle.set(uid, bundle.key.classifications, val)
    gotoNext( querystring )
  }

  const goBack = () => history.push('/');

  return(
    <>
      {/* --- [ Header ] --- */}
      <Navbar />

      {/* --- [ Body ] --- */}

      <Init close={goBack} prev={goBack}    next={gotoStep2}    open={openInit}   />
      <Conf close={goBack} prev={gotoStep1} next={gotoNextStep} open={openConfig} />
{/**
      <Auth close={goBack} prev={gotoStep1} next={gotoNext}     open={openAuth}   />
**/}
      {/* --- [ Footer ] --- */}
      <div className="w-full py-2 bg-transparent">
        <Footer />
      </div>


    </>
  )
}
