import React                  from "react";
import Navbar                 from "components/Navbar.js";
import {useHistory }          from 'react-router-dom';

import MoreHorizIcon          from '@material-ui/icons/MoreHoriz';
import CheckIcon              from '@material-ui/icons/Check';

import { bundle }             from 'shared/helpers';
import { strings }            from 'shared/strings';
import { SimData as SIMDATA } from 'shared/simdata';

import Footer                 from "components/Footer";
import CalDlg                 from 'components/CalDlg/CalDlg';

import { defData, }           from './default';

import {
  TextView, 
  IconButton, 
}                             from './exports';
import {
  Button, 
}                             from './wrapper';

const S = strings.SimMain

const Top = () => 
{
  return(
    <div className="top-section relative mx-auto pt-8">
      <div>
        <TextView className="text-gray-600"
                      type="title"
                      text={S.page_title}
        />
      </div>

      <div className="bg-purple-000">
        <TextView className="w-2/3 pl-2 text-gray-600"
                  type="default"
                  text={S.page_desc}
        />
        <TextView className="w-2/3 pl-2 p-4 text-gray-600"
                  type="default"
                  text={S.page_desc2}
        />
      </div>
    </div>
  )
}

const MenuButtons = ( {data: simdata, onClick: cbOnClick, formula} ) =>
{

  const handleOnclick = (event) => {
    cbOnClick(event)
  }

  const handleOnedit = (event) => {

    event.stopPropagation()
//      setOpen(true)
  }

  return(
    <div className="relative">
      <div className="flex text-center justify-center">
      {
        formula
        ? formula.filter( (data) => {
            return data.id !== ""
          }).map( (d,i) => {
            return(
              <div className="flex h-40 w-64 items-center" key={i}>
                <TextView className={`w-1/6 ${d.dp}`} text={ i > 0 ? d.op : ''} />
                <div className="w-full h-full justify-center" >

                  <IconButton color="indigo" className="w-5/6" 
                              id={d.name} text={d.name} align='column'
                              onEdit={handleOnedit}
                              onClick={handleOnclick} >
                     
                  {
                    simdata && simdata[d.id]
                      ? <CheckIcon fontSize="large" style={{color:'white', fontSize:'72' }} />
                      : <MoreHorizIcon fontSize="large" style={{color:'white', fontSize:'72'}} />
                  }
                  </IconButton>
                </div>
              </div>
            )
          })
        :  <div visibility="invisible" />
      }
      </div>
    </div>
  )
}

/*
 *  in: 
 *      - queryString : null
 *      - bundle      : main_cat_formula
 *
 *  out:
 *      - queryString: mainCatid
 *      - bundle     : mainCatId (main category id)
 *                     sim_data(simulation data)
 */
export default function Main() 
{
  const uid      = bundle.getUid()

  const history               = useHistory();
  const qstring               = history.location.qstring;
  const gotoNext              = (val) => history.push({pathname: '/sim/cm', qstring: val });
  const mainCatFormula        = bundle.get(uid, bundle.key.main_cat_formula);

  const [ open,        setOpen]         = React.useState(false);
  const [ mainCatId,   setMainCatId ]   = React.useState(null)
  const [ nextEnabled, setNextEnabled ] = React.useState(false);
//  const [ disableBtn,  setDisableBtn ]  = React.useState(true);
  const [ simdata,     setSimdata ]     = React.useState(SIMDATA.get('default'));
  const [ subCategory, setSubCategory ] = React.useState(defData.catItems);

console.log( uid );
console.log( simdata );


  const calculation = (subCatForm ) => {
    setSubCategory( subCatForm );
    setOpen(false);

    bundle.set(uid, mainCatId, subCatForm)
    const rec = mainCatFormula.find( (item) => item.name === mainCatId );
    SIMDATA.init(uid, rec, subCatForm, SIMDATA.default.sub)
    
    const sim = SIMDATA.get(uid );
    setSimdata( sim )

    subCatForm.forEach( (d,i) => {
      let rows = [{
             no      : 1,
             name    : d.name,
             value   : d.val,
             unit    : d.unit,
             desc    : d.desc,
           }]
      SIMDATA.update.totalby( uid, rec.id, sim[rec.id][d.id].subCatId, 'sum' );

      if( d.id === 'population' ) {
        SIMDATA.update.rows   ( uid, rec.id, sim[rec.id][d.id].subCatId,
                               {subCatId: sim[rec.id][d.id].subCatId,
                                subCatNm: sim[rec.id][d.id].subCatNm,
                                popinfo: sim[rec.id][d.id].popinfo,
                                total: d.value, totalby: 'sum',
                                op: d.op , unit: d.unit, rows:rows } );
      }

/**
      else if( d.id === 'passenger' ) {
        SIMDATA.update.rows   ( 'default', rec.id, sim[rec.id][d.id].subCatId,
                               {subCatId: sim[rec.id][d.id].subCatId,
                                subCatNm: sim[rec.id][d.id].subCatNm,
                                popinfo: sim[rec.id][d.id].popinfo,
                                total: d.value, totalby: 'sum',
                                op: d.op , unit: d.unit, rows:rows } );
      }
**/
      else if( d.id === 'marketShare' ) {
        SIMDATA.update.rows   ( uid, rec.id, sim[rec.id][d.id].subCatId,
                               {subCatId: sim[rec.id][d.id].subCatId,
                                subCatNm: sim[rec.id][d.id].subCatNm,
                                stores: sim[rec.id][d.id].stores,
                                total: d.value, totalby: 'sum',
                                op: d.op , unit: d.unit, rows:rows } );
      }
      else {
        SIMDATA.update.rows   ( uid, rec.id, sim[rec.id][d.id].subCatId,
                             {subCatId: sim[rec.id][d.id].subCatId,
                              subCatNm: sim[rec.id][d.id].subCatNm,
                              total: d.value, totalby: 'sum',
                              op: d.op , unit: d.unit, rows:rows} );

      }
    })
  }

  const openCalDlg = (e) => {
    const catId = e.target.innerText;
    setMainCatId( catId );

    setSubCategory(bundle.get(uid, catId ))

    setOpen(true)
  }

  const closeCalDlg = () => {
    setOpen(false);
  };

  const handleNext = () => {

    bundle.set(uid, bundle.key.category, mainCatFormula[0].id );
    gotoNext(qstring)

  }

  React.useEffect( () => {

    if( simdata ) {
      const sdlen =  Object.values(simdata).length
      if( sdlen=== mainCatFormula.length ) {
        setNextEnabled(true)  
//        setDisableBtn( false );
      }
    }

  },[simdata, mainCatFormula])
  
  return (
    <>
      {/* --- [ Header ] --- */}
      <Navbar />

{/* --- [ Body ] --- 
      <div className="container mx-auto w-full bg-blue-500 pb-0 min-w-min" >

        <div className="pt-24 px-6 lg:px-32">
          <TextView className="text-5xl font-semibold text-white"
                    text={S.title_line1} />
          <TextView className="text-5xl font-semibold text-white pt-6"
                    text={S.title_line2} />
        </div>
      <div className="container mx-auto h-full" >
*/}



      <div className="container mx-auto w-full pb-0 min-w-min" >

        <Top />

        <div className="pt-20">
          <MenuButtons data={simdata} onClick={(e) => {openCalDlg(e)} } formula={mainCatFormula} />
        </div>

        <div className={`mx-64 mt-12 text-right`} >
          <Button color="blue" disabled={!nextEnabled} className="text-lg font-bold"
                  onClick={() => {handleNext()} } >
            Next
          </Button>
        </div>

        <CalDlg open={open}
                category={mainCatId}
                project={uid}
                catData={subCategory}
                onClose={closeCalDlg}
                onCalc={ calculation } />

        <div className="relative pt-24 px-36 w-full">
        </div>

      </div>
      <div className="w-full py-2 bg-transparent">
        <Footer />
      </div>
    </>
  );
}
