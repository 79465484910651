import React from "react";

import Dialog                    from '@material-ui/core/Dialog';
import DialogTitle               from '@material-ui/core/DialogTitle';
import DialogContent             from '@material-ui/core/DialogContent';

import {
//        Btn,
        TextView,
}                                from "./wrapper";


export default function Footer(props) {

  const [ openContact, setOpenContact ]      = React.useState(false)

  return (
    <>
      <nav
        className={
          (props.transparent
            ? "top-0 absolute z-50 w-full"
            : "relative shadow-lg bg-white shadow-lg") +
          " flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg"
        }
      >


     <div className="container mx-auto flex flex-wrap items-center justify-between">
       <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">

         <div
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " text-sm font-bold leading-relaxed inline-block py-2 whitespace-no-wrap nouppercase"
              }
            >
           <a className="text-gray-900 hover:text-gray-500 py-1 font-semibold" 
                 href="https://www.ubimemes.com" >
                    Ubimemes Research
           </a>

         </div>
       </div>

            {/* menu items */}
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

              {/* Contact */}
              <li className="flex items-center cursor-pointer">
                <div
                  className={
                    (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
                  }
                  onClick={ () => {setOpenContact(true)} }
                >
                  <span className="lg:nohidden inline-block ml-2">Contact</span>
                </div>
              </li>

              {/* Terms */}
              <li className="flex items-center cursor-pointer">
                <div
                  className={
                    (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
                  }
                  onClick={ () => {} }
                >
                  <span className="lg:nohidden inline-block ml-2">Terms</span>
                </div>
              </li>

              {/* Privacy */}
              <li className="flex items-center cursor-pointer">
                <div
                  className={
                    (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
                  }
                  onClick={ () => {} }
                >
                  <span className="lg:nohidden inline-block ml-2">Privacy</span>
                </div>
              </li>

            </ul>






{/**
        <div className="flex w-full justify-end">
          <TextView className="px-4" color="blue" text='Contact' onClick={() => {setOpenContact(true) }}/>
          <TextView className="px-4" color="blue" text='terms' /> 
          <TextView className="px-4" color="blue" text='Privacy' /> 
        </div>
**/}

      </div>

      <Dialog onClose={() => {setOpenContact(false)}} fullWidth={true} maxWidth={'md'} open={openContact} >
        <DialogTitle> <span className="font-bold">UbiForecast 매출 예측 서비스</span> </DialogTitle>
        <DialogContent className="mb-4">
          <TextView className="py-2 font-semibold text-lg">
            아래와 같은 각종 문의는 이메일을 통해 보내 주시면 최대한 빨리 답변 드리겠습니다.
          </TextView>
          <li className="py-2 pl-8"> 서비스 사용 관련 문의</li>
          <li className="py-2 pl-8"> 서비스 구축 관련 문의</li>
          <li className="py-2 pl-8"> 기능 추가 요청</li>
          <li className="py-2 pl-8"> 데이터 관련 문의</li>
          <TextView className="text-right py-2 text-lg text-blue-500 font-bold hover:text-blue-300">
            <a href="mailto:biz.ubimemes@gmail.com"> 이메일 보내기</a>
          </TextView>
        </DialogContent>
      </Dialog>

    </nav>
    </>
  );
}
