import React                from "react";
import {useHistory}         from 'react-router-dom';

import * as d3              from 'd3';

import Accordion            from '@material-ui/core/Accordion';
import AccordionDetails     from '@material-ui/core/AccordionDetails';
import AccordionSummary     from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore';
import Slider               from '@material-ui/core/Slider';
import CircularProgress     from '@material-ui/core/CircularProgress'

import Navbar               from "components/Navbar";
import Footer               from "components/Footer";
import MBMap                from 'components/SearchMap/SearchMap';

import Pyramid              from "components/Chart/Pyramid";
import Mline                from "components/Chart/Mline";
import Radar                from "components/Chart/Radar";
import Barwide              from "components/Chart/BarWide";

import {
        TabView,
}                           from "components/Controls/TabView";

// core components

import * as Config          from "config";
import * as config          from "./config";
import {strings}            from "shared/strings";
import {bundle}             from "shared/helpers";
import { featured as FEATURED} from 'shared/featured';

import {
        TextView,
        Radio,
        Button,
}                           from "./wrapper";

const CardView = ({cols, className, ...props}) =>
{
  const clsName = `grid grid-cols-${cols} 
                   absolute z-40 right-0 mr-24
                   ${className}`;

  return(
    <div elevation={5} className={clsName} >
      { props.children }
    </div>
  )
}

//
// 검색조건 설정: 잠재고객 설정
//
const Target = ( {config, industry, dist, pop, onValueChange, ...props} ) => {
  const [distance,    setDistance]   = React.useState(dist);
  const [population,  setPopulation] = React.useState(pop);

  const marks = (min, max, step, unit) => {
    let content = [];
    let i = 0;
    for( i = min; i <= max; i += step ) {
      content.push( {value: i, label: i + unit})
    }

    // give the last label max value
    if( i - step < max ) {
      // remove the item right before the last label
      content.push( {value: max, label: max + unit})
    }
    return content
  }

  React.useEffect( () => {
    onValueChange(distance, population)

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distance, population]);

  return(
    <div className="w-full bg-white" >

      <TextView className="" type="subtitle" text='잠재 고객 설정' />
      <TextView className="pl-4" type="default"
                text='아래에서 설정한 도보 거리와 배경인구의 조건에 부합하는 상권을 검색합니다.' />

      <div className="flex w-full grid lg:grid-cols-2 md:grid-cols-1 gap-x-16 lg:px-4 py-4 items-center">
        <div className="pr-32" >
          <TextView className="w-64 font-semibold"  text={`도보 거리 (${distance}분 이내)`} />
          <Slider className="mt-16 ml-16"
                  value={distance}
                  orientation="horizontal"
                  step={config.slider.distance.datastep}
                  min={config.slider.distance.min}
                  max={config.slider.distance.max}
                  valueLabelDisplay="on"
                  marks={marks(config.slider.distance.min, 
                               config.slider.distance.max, 
                               config.slider.distance.labelstep, 
                               '분')}
                  onChange={ (event, value) => { setDistance(value)} }
          />
        </div>
        <div className="pr-32 lg:pt-0 md:pt-8" >
          <TextView className="w-64 font-semibold"  text={`배경 인구 (${population}명 이상)`} />
          <Slider className="mt-16 ml-16"
                  track='inverted'
                  value={population}
                  step={config.slider.population.datastep}
                  min={config.slider.population.min}
                  max={config.slider.population.max}
                  valueLabelDisplay="on"
                  marks={marks(config.slider.population.min, 
                               config.slider.population.max, 
                               config.slider.population.labelstep, 
                               '명')}
                  onChange={ (event, value) => { setPopulation(value)} }
          />
        </div>
      </div>
    </div>
  )
}

//
// 검색조건 설정: 업종 설정
//
const Industry = ( {config, industry, onValueChange, selected, ...props} ) => {

  const handleValueChange = (id, name, class1Nm, sicNm) => {
    onValueChange(id, name, class1Nm, sicNm);
  }

  return(
    <div className="w-full mt-4 relative bg-white" >

      <TextView className="" type="subtitle" text='업종 설정' />
      <TextView className="pl-4" type="default"
                text='아래에서 선택한 업종에 부합하는 상권을 검색합니다.' />

      <div className="flex w-full px-4 py-4 items-center">
        <div className="pt-2 md:w-full lg:w-6/12">

          <TabView labels={industry.labels} tabidx={1} >

            <div key={0} id={"tab1"} tabno={1} className="" >
              <Tab1 codes={industry.dining.codes} selected={selected} onValueChange={handleValueChange} />
            </div>

            <div key={1} id={"tab2"} tabno={2} >
              <Tab2 codes={industry.retail.codes} selected={selected} onValueChange={handleValueChange} />
            </div>

            <div key={2} id={"tab3"} tabno={3} >
              <Tab3 codes={industry.service.codes} selected={selected} onValueChange={handleValueChange} />
            </div>

          </TabView>

        </div>
      </div>
    </div>
  )
}

const Tab1 = ({codes, selected, onValueChange}) => {

  const handleOnChange = (id, name, class1Nm, sicNm) => {
    onValueChange( id, name, class1Nm, sicNm );
  }

  return(
    <div className="py-4">
    {
        codes.map( ({id, name, class1Nm, sicNm}) => {
          return(
            <Radio key={id} name='radio' isSelected={id === selected ? true : false }
                 className="w-1/4"
                 alignX text={name} 
                 onChange={(e) => { handleOnChange(id, name, class1Nm, sicNm) } }/>
          )

        })
    }
    </div>
  )
}
const Tab2 = ({codes, selected, onValueChange}) => {

  const handleOnChange = (id, name, class1Nm, sicNm) => {
    onValueChange( id, name, class1Nm, sicNm );
  }

  return(
    <div className="py-4">
    {
        codes.map( ({id, name, class1Nm, sicNm}) => {
          return(
            <Radio key={id} name='radio' isSelected={id === selected ? true : false }
                 className="w-1/4"
                 alignX text={name}
                 onChange={(e) => { handleOnChange(id, name, class1Nm, sicNm) } }/>
          )

        })
    }
    </div>
  )
}
const Tab3 = ({codes, selected, onValueChange}) => {

  const handleOnChange = (id, name, class1Nm, sicNm) => {
    onValueChange( id, name, class1Nm, sicNm );
  }

  return(
    <div className="py-4">
    {
        codes.map( ({id, name, class1Nm, sicNm}) => {
          return(
            <Radio key={id} name='radio' isSelected={id === selected ? true : false }
                 className="w-1/4"
                 alignX text={name}
                 onChange={(e) => { handleOnChange(id, name, class1Nm, sicNm) } }/>
          )

        })
    }
    </div>
  )
}

const ChartTab1 = ({tabno, data, ...rest} ) =>
{

  return(
    data &&
    <Pyramid
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const ChartTab2 = ({tabno, data, ...rest} ) =>
{

  return(

    data &&
    <Mline
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const ChartTab3 = ({tabno, data, ...rest} ) =>
{

  return(

    data &&
    <Radar
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const ChartTab4 = ({tabno, data, ...rest} ) =>
{

  return(

    data &&
    <Barwide
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const ChartTab5 = ({tabno, data, ...rest} ) =>
{

  return(

    data &&
    <Barwide
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const BannerChart = ( {pop, trend, htype, relativeClass3, top10Class3, onNextClick, show, ...rest} ) =>
{
  const labels = [ "인구구성", "인구변동", "주거형태", "유관업종", "TOP10업종" ]

  return(
    <CardView cols={1} className={`absolute z-40 right-0 mt-20 bg-white ${show}` }>
    {
      <TabView labels={labels} tabidx={5} >
        <div key={0} id={"tab1"} tabno={1} >
          <ChartTab1 tabno={1} data={pop} />
        </div>
        <div key={1} id={"tab2"} tabno={2} >
          <ChartTab2 tabno={2} data={trend} />
        </div>
        <div key={2} id={"tab3"} tabno={3} >
          <ChartTab3 tabno={3} data={htype} />
        </div>
        <div key={3} id={"tab4"} tabno={4} >
          <ChartTab4 tabno={4} data={relativeClass3} />
        </div>
        <div key={4} id={"tab5"} tabno={5} >
          <ChartTab5 tabno={5} data={top10Class3} />
        </div>
      </TabView>
    }
      <div className="mx-auto py-2 bg-red--00">
        <Button color="blue" disabled={false}
                onClick={() => {onNextClick()} } >
              매출예측
        </Button>
      </div>
    </CardView>
  )
}

export default function Main() {

  const uid      = bundle.getUid()

  const history  = useHistory();
  const gotoNext = (val) => history.push({pathname: '/sim/g', qstring: val });

  const cfg = Object.assign({}, Config, config );
  const S = strings.Search;

  const LOADING_INIT       = 10001;
  const LOADING_INPROGRESS = 10002;
  const LOADING_DONE       = 10009;
  const LOADING_ERROR      = 90001;
  const [loadingState, setLoadingState] = React.useState(LOADING_INIT)

  const [ppop,          setPpop       ] = React.useState(3000);            // param for population
  const [pdist,         setPdist      ] = React.useState(5);               // param for distance
  const [pcells ,       setPcells     ] = React.useState([]);              // cells(keycodes) selected
  const [class3Cd,      setClass3Cd   ] = React.useState('Q12A01');        // Standard Industry Code
  const [class1Nm,      setClass1Nm   ] = React.useState('음식');          // Standard Industry Code (name)
  const [class3Nm,      setClass3Nm   ] = React.useState('갈비/삼겹살');   // Standard Industry Code (name)
  const [sicNm,         setSicNm      ] = React.useState('한식 음식점업'); // Standard Industry Code (name)
  const [isReset,       setIsReset    ] = React.useState(true);            // Standard Industry Code

  const [ show,         setShow       ] = React.useState('show');

  const [ krpop,        setKrpop      ] = React.useState(null);
  const [ ptrend,       setPtrend     ] = React.useState(null);
  const [ htype,        setHtype      ] = React.useState(null);
  const [ relChart,     setRelChart   ] = React.useState(null);            // Relative industry chart
  const [ top10Chart,   setTop10Chart ] = React.useState(null);            // Top 10 industry chart
  const [ features,     setFeatures   ] = React.useState(null);

  const population = {
    data: [
      { agegroup : "0-4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
      { agegroup : "5-9",    agenmMale: "age_male_10",  agenmFemale: "age_female_10",  male: 0, female: 0 },
      { agegroup : "10-14",  agenmMale: "age_male_15",  agenmFemale: "age_female_15",  male: 0, female: 0 },
      { agegroup : "15-19",  agenmMale: "age_male_20",  agenmFemale: "age_female_20",  male: 0, female: 0 },
      { agegroup : "20-24",  agenmMale: "age_male_25",  agenmFemale: "age_female_25",  male: 0, female: 0 },
      { agegroup : "25-29",  agenmMale: "age_male_30",  agenmFemale: "age_female_30",  male: 0, female: 0 },
      { agegroup : "30-34",  agenmMale: "age_male_35",  agenmFemale: "age_female_35",  male: 0, female: 0 },
      { agegroup : "36-39",  agenmMale: "age_male_40",  agenmFemale: "age_female_40",  male: 0, female: 0 },
      { agegroup : "40-44",  agenmMale: "age_male_45",  agenmFemale: "age_female_45",  male: 0, female: 0 },
      { agegroup : "45-49",  agenmMale: "age_male_50",  agenmFemale: "age_female_50",  male: 0, female: 0 },
      { agegroup : "50-54",  agenmMale: "age_male_55",  agenmFemale: "age_female_55",  male: 0, female: 0 },
      { agegroup : "55-59",  agenmMale: "age_male_60",  agenmFemale: "age_female_60",  male: 0, female: 0 },
      { agegroup : "60-64",  agenmMale: "age_male_65",  agenmFemale: "age_female_65",  male: 0, female: 0 },
      { agegroup : "65-69",  agenmMale: "age_male_70",  agenmFemale: "age_female_70",  male: 0, female: 0 },
      { agegroup : "70-74",  agenmMale: "age_male_75",  agenmFemale: "age_female_75",  male: 0, female: 0 },
      { agegroup : "75-79",  agenmMale: "age_male_80",  agenmFemale: "age_female_80",  male: 0, female: 0 },
      { agegroup : "80-84",  agenmMale: "age_male_85",  agenmFemale: "age_female_85",  male: 0, female: 0 },
      { agegroup : "85-89",  agenmMale: "age_male_90",  agenmFemale: "age_female_90",  male: 0, female: 0 },
      { agegroup : "90-94",  agenmMale: "age_male_95",  agenmFemale: "age_female_95",  male: 0, female: 0 },
      { agegroup : "95-100", agenmMale: "age_male_100", agenmFemale: "age_female_100", male: 0, female: 0 },
      { agegroup : "100+",   agenmMale: "age_male_105", agenmFemale: "age_female_105", male: 0, female: 0 },
    ],

    init : () => {
      population.data.map( it => {
        it.male = 0;
        it.female = 0;
        return( it )
      })
    },
  }



  const industry = {
    labels : [ "외식", "도소매", "서비스" ],
    class1Nms : [ "음식", "소매", "생활서비스" ],
    dining: {
      codes: [
        {id:'Q12A01', name: '커피전문점/카페/다방', class1Nm: '음식', sicCd: 'I56220', sicNm: '비알콜 음료점업'     },
        {id:'Q05A08', name: '후라이드/양념치킨', class1Nm: '음식', sicCd: 'I56193', sicNm: '치킨 전문점'            },
        {id:'Q04A03', name: '떡볶이전문',        class1Nm: '음식', sicCd: 'I56194', sicNm: '분식 및 김밥 전문점'    },
        {id:'Q01A08', name: '족발/보쌈전문',     class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
        {id:'Q01A02', name: '갈비/삼겹살',       class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
        {id:'Q01A05', name: '해장국/감자탕',     class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
        {id:'Q01A19', name: '냉면집',            class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
        {id:'Q04A01', name: '라면김밥분식',      class1Nm: '음식', sicCd: 'I56194', sicNm: '분식 및 김밥 전문점'    },
        {id:'Q04A04', name: '우동전문점',        class1Nm: '음식', sicCd: 'I56194', sicNm: '분식 및 김밥 전문점'    },
        {id:'Q05A02', name: '닭갈비전문',        class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
        {id:'Q05A04', name: '찜닭전문점',        class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
        {id:'Q05A05', name: '불닭전문',          class1Nm: '음식', sicCd: 'I56111', sicNm: '한식 음식점업'          },
      ],
    },
    retail: {
      codes: [
        {id:'D01A07', name: '반찬가게',          class1Nm: '소매', sicCd: 'G47219', sicNm: '기타 식료품 소매업'     },
        {id:'D02A02', name: '꽃집/꽃배달',       class1Nm: '소매', sicCd: 'G47851', sicNm: '화초 및 산식물 소매업'  },
        {id:'D03A01', name: '편의점',            class1Nm: '소매', sicCd: 'G47122', sicNm: '체인화 편의점'          },
        {id:'D03A02', name: '수퍼마켓',          class1Nm: '소매', sicCd: 'G47121', sicNm: '수퍼마켓'               },
        {id:'D07A08', name: '퀼트전문',          class1Nm: '소매', sicCd: 'G47411', sicNm: '가정용 직물제품 소매업' },
        {id:'D08A01', name: '사무/문구용품',     class1Nm: '소매', sicCd: 'G47612', sicNm: '문구용품 소매업'        },
        {id:'D09A02', name: '애완동물 미용실',   class1Nm: '소매', sicCd: 'S96995', sicNm: '애완동물 장묘 및 보호 서비스업'},
        {id:'D21A07', name: '열쇠판매/수리',     class1Nm: '소매', sicCd: 'G47511', sicNm: '철물 및 난방용구 소매업'},
      ],
    },
    service: {
      codes: [
        {id:'F01A01', name: '여성미용실',        class1Nm: '생활서비스', sicCd: 'S96112', sicNm: '두발미용업'             },
        {id:'F01A02', name: '발/네일케어',       class1Nm: '생활서비스', sicCd: 'S96119', sicNm: '기타미용업'             },
//        {id:'F01A03', name: '비만/피부관리',     class1Nm: '생활서비스', sicCd: '', sicNm: ''                       },
        {id:'F01A04', name: '남성미용실',        class1Nm: '생활서비스', sicCd: 'S96111', sicNm: '이용업'                 },
        {id:'F02A01', name: '세탁소/빨래방',     class1Nm: '생활서비스', sicCd: 'S96912', sicNm: '가정용 세탁업'          },
        {id:'F03A07', name: '컴퓨터수리',        class1Nm: '생활서비스', sicCd: 'S95121', sicNm: '컴퓨터 및 사무용 기기 수리업' },
        {id:'F05A01', name: '가사도우미알선',    class1Nm: '생활서비스', sicCd: 'N75110', sicNm: '고용알선업'             },

      ],
    },
  }

  async function fetchTop10Chart( ind3Cd, cells ) {

    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/top10`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({class1_cd: ind3Cd.substring(0,1), key_codes: cells })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {

       const chartdata = [];
       data.forEach( (d) => {
         chartdata.push( {name:d.class3_nm, cnt:d.cnt} );
       } )

       chartdata["columns"] = ["name", "cnt"]
       setTop10Chart(chartdata)
       setLoadingState( LOADING_DONE );

    })
    .catch( error => {
       setLoadingState( LOADING_DONE );
    });

  }

  async function fetchRelChart( ind3Cd, cells ) {

    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/class2`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({class2_cd: ind3Cd.substring(0,3), key_codes: cells })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {

       const chartdata = [];
       data.forEach( (d) => {
         chartdata.push( {name:d.class3_nm, cnt:d.cnt} );
       } )
    
       chartdata["columns"] = ["name", "cnt"]
       setRelChart(chartdata)

    })
    .catch( error => {
    });

  }

/**
  const theaderStores = (header) => {

    const format = [
          {'hid': 'id',        'headerName': 'ID',            'hide' : false,
                               'resizable' : false,           'width': 90, 'type': 'number'},
          {'hid': 'pop',       'headerName': '인구',          'hide' : false,
                               'resizable' : false,           'width': 130, 'type': 'number'},
          {'hid': 'nocvs',     'headerName': '기존업소 수',    'hide' : false,
                               'resizable' : false,           'width': 150, 'type': 'number'},
          {'hid': 'rate',      'headerName': '점포당 인구',  'hide' : false,
                               'resizable' : false,           'width': 150, 'type': 'number'},
          {'hid': 'dummy',     'headerName': ' ',             'hide' : false,
                               'resizable' : false,           'width': 600, 'type': 'string'},
    ]
    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i]
      const item = format.find( it => it.hid === hid )

      columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'headerName': item ? item.headerName : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string',
          'valueGetter': item ? item.valueGetter : '',
          'headerAlign': 'center'
      })
    }

    return( columns )
  }
***/


  //
  // --- < event handlers > 
  //
/**
  const handleMapLoad = ( key ) => {
  }
**/

  const handleSelectionChange = (features, center ) => {

    setIsReset( false );
  }

  const handleTargetChange = (features, center ) => {

    const kcodes = Array.from(features.population.keys() )
    setPcells( kcodes )
    setFeatures( features );
  }

  const handleParamTargetChange = (dist, pop) => {
    
    setPdist( dist )
    setPpop( pop )
    
  }

  const handleParamIndustryChange = (id, name, class1, sic) => {

    setClass3Cd( id )
    setClass3Nm( name )
    setClass1Nm( class1 )
    setSicNm( sic )
    setIsReset( true );
    setShow('hidden')

  }

  const handleNextClick = () => {

/**
    let popTotal = d3.sum( krpop.map( (d,i) => { return( d.male + d.female ); }) );
    popTotal = Math.round( popTotal )
console.log( popTotal );
console.log( krpop );
console.log( ptrend );
console.log( htype );
console.log( pcells );
**/

    const brandInfo = {
      brandId    : '상권검색',
      brandName  : '상권검색',
      branchId   : pcells[0],
      branchName : pcells[0],
      dataReady  : false,
    }
    bundle.set(uid, bundle.key.brand_info, brandInfo)
    const industry = {class1_nm: class1Nm, class3_nm: class3Nm, sic_nm: sicNm}
    bundle.set(uid, bundle.key.classifications, industry)

    bundle.set(uid, bundle.key.region_pop_pyramid,    krpop )
    bundle.set(uid, bundle.key.region_pop_timeline,   ptrend )
    bundle.set(uid, bundle.key.region_house_type,     htype )
    bundle.set(uid, bundle.key.region_keycodes,       pcells )

    FEATURED.init(uid);

    const qstring = { }
    gotoNext(qstring)
  }


  //
  // --- < /event handlers > 
  //

  const drawKrpopChart = ( features ) => {

    population.init()

    const rdata = Array.from(features.values())

    rdata.map( row => {

      /* eslint-disable no-unused-vars */
      const [year, key_code, proptype, ...fields] = Object.keys(row);
      /* eslint-enable no-unused-vars */

      fields.forEach(field => {
        if( field.includes("female") ) {
          const item = population.data.find( it => it.agenmFemale === field)
          if( item ) { item.female += row[field] }
        }
        else if( field.includes("male") ) {
          const item = population.data.find( it => it.agenmMale === field)
          if( item ) item.male += row[field]
        }
      })
      return 0
    });

    const chartdata = []
    population.data.map( row => {
      chartdata.push({ group: row.agegroup, male: row.male, female: row.female });
      return 0
    });
    return( chartdata )
  }
  const drawPtrendChart = ( features ) => {

    const rdata = Array.from(features.values())

    var ages = [];
    var years = [];
    rdata.map( d => {

      ages = Object.keys(d).map( key => {
             return( key.substring(0,3) )
           })

          .filter( (item, pos, self) => {
             return( item !== "key" && item !== "pro" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })

      years =  Object.keys(d).map( key => {
             return( key.substring(4,8) )
           })

          .filter( (item, pos, self) => {
             return( item !== "code" && item !== "type" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })
      return 0
    });
    var matrix = []
    matrix = new Array(ages.length).fill(0).map( () => new Array(years.length).fill(0));

    const chartdata = []
    for( var i = 0; i < ages.length; i++ ) {
      for( var j = 0; j < years.length; j++ ) {
        const idx = `${ages[i]}y${years[j]}`;
        matrix[i][j] = {'year': years[j], 'population': d3.sum(rdata.map( row => row[idx]) ) }
      }
      chartdata.push( {name: ages[i], values: matrix[i]})
    }

    return( chartdata )
  }
  const drawKrHousetypeChart = ( features ) => {

    var nosAgg = new Map();

    const rdata = Array.from(features.values())

    rdata.map( (d) => {

      const [...fields] = Object.keys(d);

      fields.forEach(field => {

        if( field === 'num_ho_single' || field === 'num_ho_multi' || field === 'num_ho_semi' ) {
          const accum = nosAgg.get( 'num_house' ) ? nosAgg.get( 'num_house' ) : 0;
          nosAgg.set( 'num_house', accum + d[field] );
        }
        else if( field === 'num_th_small' || field === 'num_th_large' ||  field === 'num_etc'  ) {
          const accum = nosAgg.get( 'num_etc' ) ? nosAgg.get( 'num_etc' ) : 0;
          nosAgg.set( 'num_etc', accum + d[field] );
        }
        else if( field === 'num_apt' ) {
          const accum = nosAgg.get( 'num_apt' ) ? nosAgg.get( 'num_apt' ) : 0;
          nosAgg.set( 'num_apt', accum + d[field] );
        }

      })

      return 0
    })

    const chartdata = []
    chartdata.push({ name: "htype", '단독주택': nosAgg.get( 'num_house' ),
                                    '아파트':   nosAgg.get( 'num_apt'   ),
                                    '다세대/빌라/기타':   nosAgg.get( 'num_etc'   )
                    })

    return( chartdata )
  }

  const newSelectionObject = function(feat ) {
    const sel = {
      featPop: new Map(feat.population),
      featPtrend: new Map(feat.ptrend),
      featHtype: new Map(feat.htype),
      dateCreated: Date.now(),
    };
    return sel;
  };


  const drawChartBanner = function(feat ) {

    const sel = newSelectionObject(feat );

    // number of population
    const rdata = Array.from(sel.featPop.values() )

    const kcodes = rdata.map( (d) => {return d.key_code} );

    // 유관업종 챠트
    fetchRelChart( class3Cd, kcodes )

    // Top10 챠트
    fetchTop10Chart( class3Cd, kcodes )

    // population pyramid
    const cdata = drawKrpopChart( sel.featPop )
    setKrpop( cdata );

    // 인구 변동 챠트
    const tdata = drawPtrendChart( sel.featPtrend )
    setPtrend( tdata )

    // housing type
    const hdata = drawKrHousetypeChart( sel.featHtype )
    setHtype( hdata )

  }


  React.useEffect( () => {

      if( features && features.population.size > 0 ) {
        setLoadingState( LOADING_INPROGRESS );
        drawChartBanner( features )
        setShow('show')
//        setDisableBtn(false)
      }
      else {
        setShow('hidden')
//        setDisableBtn(true)
      }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ features ]);

  return (
    <>

      <Navbar />
      <div className="relative h-full w-full" >

        <div className="top-section pt-2 px-4">
          <TextView className="text-gray-600" type="title" text={`${S.title_line1}`} />
          <TextView className="text-gray-600"              text={`${S.subtitle_line1}`} />
        </div>

        <div className="w-full" >

          <Accordion defaultExpanded style={{margin: '15px 16px 8px 16px', backgroundColor: '#ececec' }}>

            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <div className="w-1/4">
                <TextView type="title" text='검색 조건' />
              </div>
              <div className="flex w-full items-center" >
                <TextView className="" type="" >
                  {`도보 ${pdist}분이내 거리에 
                    ${ppop}명 이상의 인구가 거주하는 상권을 표시 중입니다.`}
                    <span className="font-semibold"> [선택업종: {class3Nm}] </span>
                </TextView>
{/**
                <div className="bg-transparent pl-8" >
                  <div className="text-left items-left justify-left">
                  </div>
                </div>
**/}
              </div>
            </AccordionSummary>

            <AccordionDetails >
              <div className="w-full">
                <Target config={cfg} industry={industry} dist={pdist} pop={ppop} 
                          onValueChange={handleParamTargetChange}/>
                <Industry config={cfg} industry={industry} selected={class3Cd} 
                          onValueChange={handleParamIndustryChange}/>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="body-section relative pt-2 px-4 w-full">
        {
          loadingState === LOADING_INPROGRESS &&
          <div style={{height: "520px"}} className={`absolute flex w-full z-10 justify-center`} >
            <div className="flex items-center">
              <CircularProgress color={'secondary'} size={100} thickness={4} />
            </div>
          </div>
         }
          <BannerChart pop={krpop} trend={ptrend} htype={htype} 
                       relativeClass3={relChart} top10Class3={top10Chart}  
                       onNextClick={handleNextClick} show={show}/>

          <div className="relative" style={{height: "580px"}} >
            <MBMap
              onSelectionChange={handleSelectionChange}
              onTargetChange={handleTargetChange}
              SPARAM={ {dist:pdist, pop:ppop, sic:class3Cd} }
              reset={isReset}
            />
          </div>
        </div>

{/**
        <div className="bottom-section relative mx-auto pt-2 px-4">
          <div className="text-right pr-16 z-0">
            <Button color="blue" disabled={disableBtn}
                    onClick={() => {handleClickNext()} } >
              해당 상권 매출 예측 해보기
            </Button>
          </div>
        </div>
**/}
      </div>

      {/* --- [ Footer ] --- */}
      <div className="w-full py-2 bg-transparent">
        <Footer />
      </div>

    </>
  );
}
