import React from "react";
import ReactDOM from "react-dom";
import './index.css';
//import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import landing        from "views/Landing/Landing";
import home           from "views/Home/Home";
import register       from "views/Register/Register";
import region         from "views/Region/RegionData";
import simGuide       from "views/Simulation/SimGuide";
import simMain        from "views/Simulation/SimMain";
import simCatMain     from "views/Simulation/SimCatMain";
import summary        from "views/Summary/Summary";
import pricing        from "views/Pricing/Pricing";
import search         from "views/Search/Search";

import demoReg        from "views/Demo/Register/Register";
import demoRegion     from "views/Demo/Region/RegionData";
import demoSimGuide   from "views/Demo/Simulation/SimGuide";
import demoSimMain    from "views/Demo/Simulation/SimMain";
import demoSimCatMain from "views/Demo/Simulation/SimCatMain";
import demoSummary    from "views/Demo/Summary/Summary";

ReactDOM.render(

  <BrowserRouter>
    <Switch>
      <Route path="/home"         component={home} />
      <Route path="/register"     component={register} />
      <Route path="/region"       component={region} />
      <Route path="/sim/g"        component={simGuide} />
      <Route path="/sim/m"        component={simMain} />
      <Route path="/sim/cm"       component={simCatMain} />
      <Route path="/summary"      component={summary} />
      <Route path="/pricing"      component={pricing} />
      <Route path="/search"       component={search} />
      <Route path="/demo/reg"     component={demoReg} />
      <Route path="/demo/region"  component={demoRegion} />
      <Route path="/demo/sim/g"   component={demoSimGuide} />
      <Route path="/demo/sim/m"   component={demoSimMain} />
      <Route path="/demo/sim/cm"  component={demoSimCatMain} />
      <Route path="/demo/summary" component={demoSummary} />
      <Route path="/"             component={landing} />
{/**
      <Redirect from="/"          to="/landing" />
**/}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
