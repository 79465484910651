//import React from "react";

import * as d3 from 'd3';
import {bundle} from 'shared/helpers';
import { strings } from 'shared/strings';

const S = strings.SimCatMain
/*
 * Simulation Data
 */
export const SimData = 
{
  default:
  {
    main: {
      total: 0,
      op: '+',
    },
    sub: {
      total: 0,
      totalby: 'sum',
      unit:  S.tab_radio_unit1,
      unitetc: '',
      rows: {
        no: 1,
        name: '',
        value: 0,
        unit: '',
        desc: ''
      }
    },
  },

  operator:
  {
    '+': (a, b) => { return a + b },
    '-': (a, b) => { return a - b },
    '*': (a, b) => { return a * b },
    '×': (a, b) => { return a * b },
    'x': (a, b) => { return a * b },
    '/': (a, b) => { return a / b },
  },

  init: (projId, mainFormula, catFormula, def ) =>
  {
    let catId = mainFormula.id
    let catNm = mainFormula.name
    let result = {}
    result[projId] = {}

    result[projId][catId] =
    {
      mainCatId: catId,
      mainCatNm: catNm,
      total: SimData.default.main.total,
      op: mainFormula.op,
    }

    catFormula.filter( (data) => {
      return data.id !== ""
    }).forEach( (d,i) => {

      result[projId][catId][d.id] =
      {
        subCatId: d.id,
        subCatNm: d.name,
        total: def.total,
        totalby: def.totalby,
        unit: def.unit,
        unitEtc: def.unitetc,
        op: d.op,

        popinfo: {
          ages:{
            age10m: false,
            age10f: false,
            age20m: true,
            age20f: true,
            age30m: true,
            age30f: true,
            age40m: true,
            age40f: true,
            age50m: false,
            age50f: false,
            age60m: false,
            age60f: false,
          },
          coverage: 200,
          passenger: 25,
//          majorityRate: 0,
        },

        stores: {
          weight:{
            floor: 10,
          },
          inSameIndustry:[
          ],
          inSimilarIndustry:[
          ],
          same: 0,
          similar: 0,
        },

        rows:
        [
          {
            no: def.rows.no,
            name: def.rows.name,
            value: def.rows.value,
            unit: def.rows.unit,
            desc: def.rows.desc,
          }
        ]
      }
    })

    const prev = SimData.get(projId)
    SimData.set( projId, {...prev, ...result[projId]} )
  },
  empty: (projId) => {
    bundle.set(projId, bundle.key.sim_data, {})
  },
  emptyAll: () => {
//    SimData.set( {} )
    bundle.set('default', bundle.key.sim_data, {})
    bundle.set('demo', bundle.key.sim_data, {})
  },

  delete: (projId, mainCatId, subCatId, itemNo, data ) =>
  {
    var i = 1;
    let after = data
    const rows = after.rows.filter( (it) => it.no !== itemNo )
              .map( (item) => {
                    item.no = i++;
                    return item;
              })

    after.rows = rows
    SimData.update.rows(projId, mainCatId, subCatId, after )

    return after

  },

  update:
  {
    rows: (projId, mainCatId, subCatId, rec ) =>
    {
      var sim = SimData.get(projId);

      const total = rec.totalby === 'sum'
                 ? d3.sum (rec.rows.map( (d) => { return (d.value) }))
                 : d3.mean(rec.rows.map( (d) => { return (d.value) }))

      rec.total = total;
      sim[mainCatId].total = sim[mainCatId].total + total 
      sim[mainCatId][subCatId] = rec

      SimData.set(projId, sim)

    },
    
    totalby: (projId, mainCatId, subCatId, val ) =>
    {
      var sim = SimData.get(projId);
      var rec = sim[mainCatId][subCatId]

      rec.totalby = val

      rec.total = rec.totalby === 'sum'
                 ? d3.sum (rec.rows.map( (d) => { return (d.value) }))
                 : d3.mean(rec.rows.map( (d) => { return (d.value) }))

      rec.total = Math.round(rec.total * 100)/100

      sim[mainCatId][subCatId] = rec
      SimData.set(projId, sim)
    },

    unit: (projId, mainCatId, subCatId, val ) =>
    {
      var sim = SimData.get(projId);
      var rec = sim[mainCatId][subCatId]

      rec.unit = val

      sim[mainCatId][subCatId] = rec
      SimData.set(projId, sim)
    },

    unitEtc: (projId, mainCatId, subCatId, val ) =>
    {
      var sim = SimData.get(projId);
      var rec = sim[mainCatId][subCatId]

      rec.unitEtc = val

      sim[mainCatId][subCatId] = rec
      SimData.set(projId, sim)
    },


    popinfo: {
      age: (projId, mainCatId, subCatId, age, checked, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].popinfo.ages[age] = checked
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },

      coverage: (projId, mainCatId, subCatId, val, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].popinfo.coverage = val
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },

      passenger: (projId, mainCatId, subCatId, val, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].popinfo.passenger = val
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },


      total: (projId, mainCatId, subCatId, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },

    },

    stores: {
      weight: {
        floor: (projId, mainCatId, subCatId, val, total ) =>
        {
          var sim = SimData.get(projId);
          sim[mainCatId][subCatId].stores.weight.floor = val
          sim[mainCatId][subCatId].total = total

          SimData.set(projId, sim)
        },
      },
      inSameIndustry: (projId, mainCatId, subCatId, arr, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].stores.inSameIndustry = arr
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },
      inSimilarIndustry: (projId, mainCatId, subCatId, arr, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].stores.inSimilarIndustry = arr
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },
      total: (projId, mainCatId, subCatId, total ) =>
      {
        var sim = SimData.get(projId);
        sim[mainCatId][subCatId].total = total

        SimData.set(projId, sim)
      },

    },
  },

  get: (projId) =>
  {
    return bundle.get(projId, bundle.key.sim_data)
  },

  set: (projId, data) =>
  {
    bundle.set(projId, bundle.key.sim_data, data)
  },

  getTotal: (projId) =>
  {

    const sim = SimData.get(projId)

    let vals = Object.values(sim)
    let sum = 0;

    vals.forEach( (d,i) => {
      sum = sum + SimData.getCatTotal( projId, d.mainCatId );
    })

    return sum
  },

  getCatTotal: (projId, mainCatId) =>
  {

    const sim = SimData.get(projId)

    let vals = Object.values(sim[mainCatId])
    let sum = 0;
    vals.forEach( (d,i) => {

      if( typeof d === 'object' )
      {
        if( d.op.length > 0 )
        {
          const total = d.unit === S.tab_radio_unit1 ? d.total / 100 : d.total
          sum = i > 0 ? SimData.operator[d.op.trim()](sum, total)
                      : total;
          sum = Math.round( sum * 100 ) / 100
        }
      }

    })
    return sum
  },
}
