import React from 'react';
////import PropTypes from "prop-types";

import DialogTitle   from '@material-ui/core/DialogTitle';
import Dialog        from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AddIcon       from '@material-ui/icons/Add';
import EditIcon      from '@material-ui/icons/Edit';
import ClearIcon     from '@material-ui/icons/Clear';
import FolderIcon    from '@material-ui/icons/Folder';
//import FolderIcon    from '@material-ui/icons/FolderOpen';
//import FolderIcon    from '@material-ui/icons/CreateNewFolder';
import {yellow}  from '@material-ui/core/colors';

import { featured as FEATURED } from 'shared/featured';

import { TabView, }             from "components/Controls/TabView";


//import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { ItemButton, 
         OpButton, 
         Button, 
         ApplyButton, 
         TextView, 
         TextInput, 
         Actionbar,
         TemplateButton } from "./exports";

import { strings } from 'shared/strings';


const S = strings.CalDlg;


export default function CalDlg( {onClose, onCalc, open, project, category, catData} ) 
{

  const INIT = ""
  const ADDITION = "+"
  const SUBTRACTION = "-"
  const MULTIPLICATION = "x"
  const DIVISION = "÷"
  let catId = 'inhouse';

  if( category === "점내 판매" ) {
    catId = 'inhouse';
  }
  else if( category === "배달 판매" ) {
    catId = 'delivery';
  }
  else if( category === "테이크아웃 판매" ) {
    catId = 'takeout';
  }

  const [subopen, setSubopen] = React.useState(false);
  const [ openHelpDlg, setOpenHelpDlg] = React.useState(false);
  const [ openSamplesDlg, setOpenSamplesDlg] = React.useState(false);
  const [ itemId, setItemId ] = React.useState('');
  const [ itemname, setItemname ] = React.useState('');
  const [ id00, setId00 ] = React.useState(catData && catData[0] && catData[0].id);
  const [ id01, setId01 ] = React.useState(catData && catData[1] && catData[1].id);
  const [ id02, setId02 ] = React.useState(catData && catData[2] && catData[2].id);
  const [ id03, setId03 ] = React.useState(catData && catData[3] && catData[3].id);
  const [ id04, setId04 ] = React.useState(catData && catData[4] && catData[4].id);
  const [ id05, setId05 ] = React.useState(catData && catData[5] && catData[5].id);
  const [ id06, setId06 ] = React.useState(catData && catData[6] && catData[6].id);
  const [ id07, setId07 ] = React.useState(catData && catData[7] && catData[7].id);
  const [ id08, setId08 ] = React.useState(catData && catData[8] && catData[8].id);
  const [ id09, setId09 ] = React.useState(catData && catData[9] && catData[9].id);
  const [ id10, setId10 ] = React.useState(catData && catData[10] && catData[10].id);
  const [ id11, setId11 ] = React.useState(catData && catData[11] && catData[11].id);
  const [ formobj, setFormobj ] = React.useState(catData);  // formula object
//  const [ formstr, setFormstr ] = React.useState("");    // formula string
  const [ displayText,  setDisplayText ]  = React.useState([]);
  const [ operator,  setOperator ]  = React.useState(ADDITION);
  const [ refresh,  setRefresh ]  = React.useState(false);
//  const [ projId,   setProjId ]  = React.useState(project);
  const projId = project;

  const handleClose = () => {
    onClose();
  };

  const isOperator = (val) => {
  
    const op = val ? val.trim() : null;
    switch( op )
    {
      case null:
      case INIT.trim():
      case ADDITION.trim():
      case SUBTRACTION.trim():
      case MULTIPLICATION.trim():
      case DIVISION.trim():
        return true;
      default:
        return false;
    }
  }

  const getLastItem = () => {
    const idx = displayText.length;
    const retval = idx === 0 ? null : displayText[idx-1]

    return( retval );
  }

  const replaceLastItem = (val) => {
    const idx = displayText.length - 1
    const results = displayText.map( (d,i) => {
        if( i === idx ){
          return val
        }
        else
        {
          return d
        }
    })

    setDisplayText(results)
  }

  const deleteLastItem = (val) => {

    const idx = displayText.length - 1
    const results = displayText.slice(0,idx)

    setDisplayText(results)
  }

  const handleItems = (e) => {
    
    const val = e.target.innerText ? e.target.innerText.trim() : '';
    if( val.length > 0 ) {
      const lastitem = getLastItem()
      if( isOperator(lastitem) ) {
        setDisplayText( [...displayText, val] )
      }
      else
      {
        replaceLastItem(val)
      }
    }
  }

  const createFormula = (catId, _formula) => {

    const featured = FEATURED.get(projId)[catId];

    const items = []
    var item = {op: ADDITION, id: '', name: '', val: 0, unit:"", desc: ""}

    _formula.forEach( (d,i) => {
    
      if( i % 2 === 0 ) {
        const key = d.replace(/\s/g, '')

        item.id   = featured[key] ? featured[key].id   : d
        item.val  = featured[key] ? featured[key].val  : ''
        item.unit = featured[key] ? featured[key].unit : '%'
        item.desc = featured[key] ? featured[key].desc : ''
        item.name = featured[key] ? featured[key].name : d
//        item.name = d
        items.push( item);
        item = {}
      }
      else {
        item.op = ' ' + d + ' '
      }
    })

    return( items );
  }

  const handleEqual = (category, displayText) => {

    const formula = createFormula(category, displayText)

    formula.length >0 && onCalc( formula );
  }

  const handleOperator = (e) => {
    const val = ' ' + e.target.innerHTML.trim() + ' ';
    const lastitem = getLastItem()

    if( isOperator(lastitem) ) {
      replaceLastItem(val)
    }
    else
    {
      setDisplayText( [...displayText, val] )
    }

    setOperator( val )
  }

  const handleButtonBackspace = (e) => {

    const lastitem = getLastItem()
    deleteLastItem(lastitem)
  }

  const handleButtonC = (e) => {

    setDisplayText( [] );
  }


  const handleEdit = (id) => {
    setItemId(id)
    setItemname('')
    setSubopen(true)
  }

  const handleChange = (id, name) => {
    setItemId(id)
    setItemname(name)
    setSubopen(true)
  }


  const handleSubclose = () => {
    setSubopen(false)
  };

  const onNameChange = (event ) => {
    setItemname( event.target.value );
  }

  const handleApply = (event) => {
    
    if( formobj ) {
      formobj.push({op: operator, id: itemname, name: itemname, val: 0, unit:'', desc: ''});
      setFormobj(formobj)
    } else {
      setFormobj([{op: operator, id: itemname, name: itemname, val: 0, unit:'', desc: ''}])
    }

    switch( itemId ) {
      case 'ITEM01':
           setId00(itemname)
           break;

      case 'ITEM02':
           setId01(itemname)
           break;

      case 'ITEM03':
           setId02(itemname)
           break;

      case 'ITEM11':
           setId03(itemname)
           break;

      case 'ITEM12':
           setId04(itemname)
           break;

      case 'ITEM13':
           setId05(itemname)
           break;

      case 'ITEM21':
           setId06(itemname)
           break;

      case 'ITEM22':
           setId07(itemname)
           break;

      case 'ITEM23':
           setId08(itemname)
           break;

      case 'ITEM31':
           setId09(itemname)
           break;

      case 'ITEM32':
           setId10(itemname)
           break;

      case 'ITEM33':
           setId11(itemname)
           break;


      default: break;
    }
    setSubopen(false)
  };

  const selectFormula = (catId, val) => {

    const formBuf = createFormula(catId, val.split('\t'))

    const arr = []
    formBuf.map( (d,i) => {
      i > 0 && arr.push( d.op )
      arr.push( d.name )
      return 0
    })
    setDisplayText(arr)
    setFormobj( formBuf )
    setRefresh(!refresh)

    setOpenSamplesDlg(false)
  }

  const deleteItem = (idx) => {

    formobj.splice(idx,1)

    setRefresh( !refresh );
  }

  React.useEffect( () => {

    const func = [ {func:setId00},
                   {func:setId01},
                   {func:setId02},
                   {func:setId03},
                   {func:setId04},
                   {func:setId05},
                   {func:setId06},
                   {func:setId07},
                   {func:setId08},
                   {func:setId09},
                   {func:setId10},
                   {func:setId11},
    ]

    func.forEach( (d,i) => {
      func[i].func('')
    })
    formobj && 
    formobj.forEach( (d,i) => {
      func[i].func(d.name)
    })

     // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [refresh ]);
//  }, [refresh, formobj]);

  React.useEffect( () => {
//    const arr = [S.displayText]
    const arr = []
    catData && catData.forEach( (d,i) => {
      i > 0 && arr.push( d.op )
      arr.push( d.name )
    })
    setDisplayText(arr)
    setFormobj( catData )
    setRefresh( !refresh );

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catData ]);
//  }, [catData, refresh ]);

  return (
    <div>
    <Dialog onClose={handleClose} fullWidth={true} maxWidth={'md'}  open={open} >
      <Actionbar className="text-left">
        <TextView  text='매출공식 작성'  className="font-bold py-2" />
      </Actionbar>

      <div  className="flex h-24 m-2 items-center bg-blue-200">
        <TextView text={displayText} className="break-words text-xl w-full text-right p-2" />
      </div>
      
      <div className="flex px-6">

        <TemplateButton text='템플릿 선택' className="w-36" onClick={() => {setOpenSamplesDlg(true)}}>
          <FolderIcon style={{fontSize:'56', color:yellow[600] }} />
        </TemplateButton>

        <div className="flex px-4 items-center">
          <TextView text={S.displayText} className="ml-4 text-xl text-left text-gray-400" />
        </div>
      </div>

      <div className="grid grid-cols-8 mx-4 my-0 gap-2 pb-4">

        <ItemButton id="ITEM01" text={id00} onClick={handleItems} 
                    className="col-start-1 col-end-3" >
          {
            id00 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM01", id00)}}  /> 
                     <ClearIcon fontSize="small" id="ITEM01" onClick={(e) => {deleteItem(0)}}  />
                   </div>
                 : <div>
                     <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM01")}}/>
                   </div>
          }
        </ItemButton>
        <ItemButton id="ITEM02" text={id01} onClick={handleItems} 
                    className="col-start-3 col-end-5" >
          {
            id01 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM02", id01)}}  />
                     <ClearIcon fontSize="small" id="ITEM02" onClick={(e) => {deleteItem(1)}}  />
                   </div>
                 : id00 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM02")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM03" text={id02} onClick={handleItems}
                    className="col-start-5 col-end-7" >
          {
            id02 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM03", id02)}}  />
                     <ClearIcon fontSize="small" id="ITEM03" onClick={(e) => {deleteItem(2)}}  />
                   </div>
                 : id01 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM03")}}/>
          }
        </ItemButton>
        <OpButton   id="ID53" text="←" onClick={handleButtonBackspace}
                    className="col-start-7 col-end-8" />
        <OpButton   id="ID03" text="C" onClick={handleButtonC} 
                    className="col-start-8 col-end-9" />

        <ItemButton id="ITEM11" text={id03} onClick={handleItems} 
                    className="col-start-1 col-end-3" >
          {
            id03 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM11", id03)}}  />
                     <ClearIcon fontSize="small" id="ITEM11" onClick={(e) => {deleteItem(3)}}  />
                   </div>
                 : id02 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM11")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM12" text={id04} onClick={handleItems}
                    className="col-start-3 col-end-5" >
          {
            id04 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM12", id04)}}  />
                     <ClearIcon fontSize="small" id="ITEM12" onClick={(e) => {deleteItem(4)}}  />
                   </div>
                 : id03 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM12")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM13" text={id05} onClick={handleItems}
                    className="col-start-5 col-end-7" >
          {
            id05 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM13", id05)}}  />
                     <ClearIcon fontSize="small" id="ITEM13" onClick={(e) => {deleteItem(5)}}  />
                   </div>
                 : id04 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM13")}}/>
          }
        </ItemButton>
        <OpButton   id="ID03" text={ADDITION} onClick={handleOperator}
                    className="col-start-7 col-end-8" />
        <OpButton   id="ID13" text={SUBTRACTION} onClick={handleOperator}
                    className="col-start-8 col-end-9" />

        <ItemButton id="ITEM21" text={id06} onClick={handleItems}
                    className="col-start-1 col-end-3" >
          {

            id06 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM21", id06)}}  />
                     <ClearIcon fontSize="small" id="ITEM21" onClick={(e) => {deleteItem(6)}}  />
                   </div>
                 : id05 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM21")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM22" text={id07} onClick={handleItems}
                    className="col-start-3 col-end-5" >
          {
            id07 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM22", id07)}}  />
                     <ClearIcon fontSize="small" id="ITEM22" onClick={(e) => {deleteItem(7)}}  />
                   </div>
                 : id06 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM22")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM23" text={id08} onClick={handleItems}
                    className="col-start-5 col-end-7" >
          {
            id08 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM23", id08)}}  />
                     <ClearIcon fontSize="small" id="ITEM23" onClick={(e) => {deleteItem(8)}}  />
                   </div>
                 : id07 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM23")}}/>
          }
        </ItemButton>
        <OpButton   id="ID23" text={MULTIPLICATION} onClick={handleOperator}
                    className="col-start-7 col-end-8" />
        <OpButton   id="ID33" text={DIVISION} onClick={handleOperator}
                    className="col-start-8 col-end-9" />


        <ItemButton id="ITEM31" text={id09} onClick={handleItems} 
                    className="col-start-1 col-end-3" >
          {
            id09 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM31", id09)}}  />
                     <ClearIcon fontSize="small" id="ITEM31" onClick={(e) => {deleteItem(9)}}  />
                   </div>
                 : id08 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM31")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM32" text={id10} onClick={handleItems}
                    className="col-start-3 col-end-5" >
          {
            id10 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM32", id10)}}  />
                     <ClearIcon fontSize="small" id="ITEM32" onClick={(e) => {deleteItem(10)}}  />
                   </div>
                 : id09 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM32")}}/>
          }
        </ItemButton>
        <ItemButton id="ITEM33" text={id11} onClick={handleItems}
                    className="col-start-5 col-end-7" >
          {
            id11 ? <div className="w-10 mx-auto">
                     <EditIcon  fontSize="small" onClick={() => {handleChange("ITEM33", id11)}}  />
                     <ClearIcon fontSize="small" id="ITEM33" onClick={(e) => {deleteItem(11)}}  />
                   </div>
                 : id10 && <AddIcon     fontSize="small" onClick={() => {handleEdit("ITEM33")}}/>
          }
        </ItemButton>
        <OpButton   id="ID43" text="＝" onClick={() => handleEqual(catId, displayText)} 
                    className="col-start-7 col-end-9" />

      </div>
      <Dialog onClose={handleSubclose} fullWidth={true} open={subopen} className="inline-block left-0 bottom-0">
        <form action="#" method="get" className="px-4 py-2">
          <TextInput hint="항목입력" text={itemname || ''} onChange={onNameChange}/>
          <ApplyButton   id="ID99" text="적용" onClick={() => {handleApply()}}/>
        </form>
      </Dialog>

      <Dialog onClose={() => {setOpenHelpDlg(false)}} fullWidth={true} maxWidth={'md'} open={openHelpDlg} >
        <DialogTitle> <span className="font-bold">{S.help.title}</span> </DialogTitle>
        <DialogContent> 
          <Help />
        </DialogContent>
      </Dialog>

      <Dialog onClose={() => {setOpenSamplesDlg(false)}} 
              fullWidth={true} maxWidth={'md'} open={openSamplesDlg} >
        <DialogTitle> 
          <span className="font-bold text-blue-600">{`${category} `}</span> 
          <span className="font-bold">{`${S.samples.title}`}</span> 
        </DialogTitle>
        <DialogContent >
          <Samples projId={projId} catId={catId} selectFormula={selectFormula}/>
        </DialogContent>
      </Dialog>

    </Dialog>

    </div>
  );

}

const Samples = ({projId, selectFormula, catId}) => {

  const classKey = "font-bold col-start-1 col-end-11";
  const classSubKey = "font-bold col-start-1 col-end-2 pl-0";
  const classVal = "col-start-2  col-end-10 pl-4"
  const classBtn = "col-start-10 col-end-11"
  const styles = {key: classKey, subkey: classSubKey, val: classVal, btn: classBtn}

  let labels = []
  if( projId === "demo" ) {
    labels = [ S.samples.inhouse.kfood_key ];
  }
  else {
    labels = [ S.samples.inhouse.kfood_key, 
               S.samples.inhouse.cafe_key, 
               S.samples.inhouse.fastfood_key,
               S.samples.inhouse.japanese_key,
               S.samples.inhouse.chinese_key,
               S.samples.inhouse.casual_key,
               S.samples.inhouse.coffee_key,
               S.samples.inhouse.western_key,
               S.samples.inhouse.foreign_key,
               S.samples.inhouse.chicken_key,
               S.samples.inhouse.bakery_key,
               S.samples.inhouse.bar_key,
               S.samples.inhouse.catering_key,
               S.samples.inhouse.foodtruck_key,
             ];
  }


  return(

    <div>
    {
      <div className={`gap-y-1 divide-y-0 items-center ${true}`}>

        <TabView labels={labels} tabidx={1} >
          {/* --- [ 한식 ] ---------- */}
          <div key={0} id={"tabInhouse"} tabno={1}>
            <TabKfood styles={styles} projId={projId} catId={catId} selectFormula={selectFormula} />
          </div>

          {/* --- [ 구내식당 ] ---------- */}
          <div key={1} id={"tab2"} tabno={2} >
            <TabCafe styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 패스트푸드 ] ---------- */}
          <div key={2} id={"tab3"} tabno={3} >
            <TabFastfood styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 일식 ] ---------- */}
          <div key={3} id={"tab4"} tabno={4} >
            <TabJapanese styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 중식 ] ---------- */}
          <div key={4} id={"tab5"} tabno={5} >
            <TabChinese styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 분식 및 김밥 ] ---------- */}
          <div key={5} id={"tab6"} tabno={6} >
            <TabCasual styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 커피 전문점 ] ---------- */}
          <div key={6} id={"tab7"} tabno={7} >
            <TabCoffee styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 서양식 ] ---------- */}
          <div key={7} id={"tab8"} tabno={8} >
            <TabWestern styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 기타 외국 음식 ] ---------- */}
          <div key={8} id={"tab9"} tabno={9} >
            <TabForeign styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 치킨 전문점 ] ---------- */}
          <div key={9} id={"tab10"} tabno={10} >
            <TabChicken styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 제과제빵 ] ---------- */}
          <div key={10} id={"tab11"} tabno={11} >
            <TabBakery styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 주점 ] ---------- */}
          <div key={11} id={"tab12"} tabno={12} >
            <TabBar styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 출장음식 ] ---------- */}
          <div key={12} id={"tab13"} tabno={13} >
            <TabCatering styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

          {/* --- [ 이동음식 ] ---------- */}
          <div key={13} id={"tab14"} tabno={14} >
            <TabFoodtruck styles={styles} catId={catId} selectFormula={selectFormula}/>
          </div>

        </TabView>

      </div>
    }

    </div>
  )
}


const TabKfood = ({styles, projId, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  if( projId === 'demo' ){
    return(
      <div>
      {
        <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>
          {/* --- [ 한식-면류전문점 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_noodle_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_noodle_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_noodle_value1)}} />
        </div>
      }
      </div>
    )
  }
  else {

    return(
      <div>
      {
        <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

          {/* --- [ 한식-일반한식 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_general_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_general_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_general_value1)}} />

          {/* --- [ 한식-육류구이 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_meat_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_meat_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_meat_value1)}} />

          {/* --- [ 한식-국.탕.찜 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_stew_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_stew_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_stew_value1)}} />

          {/* --- [ 한식-한정식 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_set_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_set_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_set_value1)}} />

          {/* --- [ 한식-해산물전문점 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_seafood_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_seafood_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_seafood_value1)}} />

          {/* --- [ 한식-면류전문점 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_noodle_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_noodle_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_noodle_value1)}} />

          {/* --- [ 한식-죽/도시락 ] ---------- */}
          <TextView className={classSubKey} text={`${S.samples[cat].kfood_congee_key}`} />
          <TextView className={classVal} text={S.samples[cat].kfood_congee_value1} />
          <Button className={classBtn} color="blue" text={S.btn_select}
                  onClick={() => {selectFormula(catId, S.samples[cat].kfood_congee_value1)}} />

        </div>
        }
      </div>
    )
  }
}


const TabCafe = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(
    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 구내식당 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].cafe_key}`} />
        <TextView className={classVal} text={S.samples[cat].cafe_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].cafe_value1)}} />
      </div>
    }
    </div>

  )
}


const TabFastfood = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 패스트푸드 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].fastfood_key}`} />
        <TextView className={classVal} text={S.samples[cat].fastfood_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].fastfood_value1)}} />
      </div>
    }
    </div>
  )

}

const TabJapanese = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 일식 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].japanese_key}`} />
        <TextView className={classVal} text={S.samples[cat].japanese_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].japanese_value1)}} />
      </div>
    }
    </div>
  )
}

const TabChinese = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 중식 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].chinese_key}`} />
        <TextView className={classVal} text={S.samples[cat].chinese_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].chinese_value1)}} />
      </div>
    }
    </div>
  )
}

const TabCasual = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 분식/김밥 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].casual_key}`} />
        <TextView className={classVal} text={S.samples[cat].casual_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].casual_value1)}} />
      </div>
    }
    </div>
  )
}

const TabCoffee = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 커피 전문점 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].coffee_key}`} />
        <TextView className={classVal} text={S.samples[cat].coffee_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].coffee_value1)}} />
      </div>
    }
    </div>
  )
}

const TabWestern = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 서양식 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].western_key}`} />
        <TextView className={classVal} text={S.samples[cat].western_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].western_value1)}} />
      </div>
    }
    </div>
  )
}

const TabForeign = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 기타 외국 음식 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].foreign_key}`} />
        <TextView className={classVal} text={S.samples[cat].foreign_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].foreign_value1)}} />
      </div>
    }
    </div>
  )
}

const TabChicken = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 치킨 전문점 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].chicken_key}`} />
        <TextView className={classVal} text={S.samples[cat].chicken_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].chicken_value1)}} />
      </div>
    }
    </div>
  )
}

const TabBakery = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 제과/제빵 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].bakery_key}`} />
        <TextView className={classVal} text={S.samples[cat].bakery_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].bakery_value1)}} />
      </div>
    }
    </div>
  )
}

const TabBar = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 주점 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].bar_key}`} />
        <TextView className={classVal} text={S.samples[cat].bar_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].bar_value1)}} />
      </div>
    }
    </div>
  )
}

const TabCatering = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 출장음식 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].catering_key}`} />
        <TextView className={classVal} text={S.samples[cat].catering_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].catering_value1)}} />
      </div>
    }
    </div>
  )
}

const TabFoodtruck = ({styles, catId, selectFormula, ...rest} ) =>
{
  const classSubKey  = styles.subkey;
  const classVal     = styles.val;
  const classBtn     = styles.btn;
  const cat = catId;      // category

  return(

    <div>
    {
      <div className={`grid grid-cols-10 gap-y-4 py-8 items-center`}>

        {/* --- [ 이동음식점 ] ---------- */}
        <TextView className={classSubKey} text={`${S.samples[cat].foodtruck_key}`} />
        <TextView className={classVal} text={S.samples[cat].foodtruck_value1} />
        <Button className={classBtn} color="blue" text={S.btn_select}
                onClick={() => {selectFormula(catId, S.samples[cat].foodtruck_value1)}} />
      </div>
    }
    </div>
  )
}








const Help = () => {

  const classKey = "py-2 text-center col-start-1 col-end-3 font-bold";
  const classVal = "py-2 col-start-3 col-end-13"
  const style = {whiteSpace: 'pre-line'}
  return(

    <div className="grid grid-cols-12 divide-y-2">
      <TextView text="" />

      <span style={style} className={classKey} >
        {S.help.inhouse.customers_key}
      </span>
      <span style={style} className={classVal} >
        {S.help.inhouse.customers_value}
      </span>

      <span style={style} className={classKey} >
        {S.help.inhouse.price_key}
      </span>
      <span style={style} className={classVal} >
        {S.help.inhouse.price_value}
      </span>

      <span style={style} className={classKey} >
        {S.help.inhouse.dineout_key}
      </span>
      <span style={style} className={classVal} >
        {S.help.inhouse.dineout_value}
      </span>

      <span style={style} className={classKey} >
        {S.help.inhouse.noodle_key}
      </span>
      <span style={style} className={classVal} >
        {S.help.inhouse.noodle_value}
      </span>

      <span style={style} className={classKey} >
        {S.help.inhouse.household_key}
      </span>
      <span style={style} className={classVal} >
         {S.help.inhouse.household_value}
      </span>

      <span style={style} className={classKey} >
        {S.help.inhouse.apt_key}
      </span>
      <span style={style} className={classVal} >
         {S.help.inhouse.apt_value}
      </span>

    </div>

  )
}
