import { join } from 'path';

export async function sourceFromUrl(url) {
  const res = await fetch(url);
  const metadata = await res.json();
  const source = {
    type: 'vector',
    bounds: metadata.bounds,
    center: metadata.center,
    minzoom: 11,
    maxzoom: metadata.maxzoom,
    promoteId: 'key_code',
    tiles: [join(url, `{z}/{x}/{y}.${metadata.format}`)],
  };
  return source;
}
