//import * as d3 from 'd3';
//import { transition } from 'd3-transition'

export default function pbar(element, config) {

    const width  = config.width;
    const height = config.height;
    const msg    = config.msg;
//    const radius = Math.round(Math.min(width, height) / 15)
   
//    var tau = 2 * Math.PI;

/**
    var arc = d3.arc()
            .innerRadius(radius*0.5)
            .outerRadius(radius*0.9)
            .startAngle(0);
**/

    // root component
    const root = element
      .append('g')
      .attr("transform", "translate(0,0)")

    // background
    root
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', "100%")
      .attr('height', "100%")
      .attr('fill', '#fff')

    // progress bar
    root
      .append("text")
      .text( msg )
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
      .attr("font-size", "24")
      .attr('fill', '#4D4D4D')
//      .attr('dx', 0 )
      .style('text-anchor', 'middle')



/**
    const pbar = root
      .append("path")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
      .datum({endAngle: 0.77*tau})
      .attr("d", arc)
      .attr('fill', '#4D4D4D')
**/

}
