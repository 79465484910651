import React from "react";
//import CalDlg from 'components/CalDlg/CalDlg';
import {
  Button       as __Button,
  IconButton   as __IconButton,
  ItemButton   as __ItemButton,
  TextView     as __TextView,
  PillText   as __PillText,
  CircleButton as __CircleButton,
  Radio   as __Radio,
  TabView   as __TabView,
  TabContent   as __TabContent,
  ImageButton   as __ImageButton,
  Dropdown   as __Dropdown,
//  Select   as __Select,
  Checkbox   as __Checkbox,
} from "exports.js";

export const TextView = ( {className, ...props} ) => {

  var clsName = '';
  switch( props.type )
  {
    case 'title' :
         clsName = `bg-transparent rounded py-2 px-3
                    text-gray-600
                      text-2xl font-bold ${className}`
         break
    case 'large' :
    case 'subtitle' :
         clsName = `bg-transparent rounded py-2 px-3
                      text-gray-600
                      text-xl font-semibold ${className}`
         break
    case 'small' :
         clsName = `bg-transparent rounded
                      text-xs font-normal ${className}`
         break
    case 'normal' :
    default :
         clsName = `bg-transparent rounded 
                      text-gray-600
                      font-normal ${className}`
         break
  }

  return __TextView( clsName, props )
}

export function PillText( {className, color, ...props} ) {

  const classname
        = ` bg-transparent hover:bg-transparent text-${color}-400
           ${className}
          `;

  return __PillText( classname, props )
}

export function TextInput( prop ){

  const classname = `shadow appearance-none border rounded
                     py-2 px-3 text-indigo-700 leading-tight
                     focus:outline-none focus:shadow-outline 
                     ${prop.className}`;
//                     ${prop.align} ${prop.visibility} ${prop.className}`;

//             id="username" type={prop.type}
  return(
      <input className={classname} 
             type={prop.type}
             placeholder={prop.hint} value={prop.text} 
             onClick={prop.onClick} onChange={prop.onChange}/>
  )
}


export function Button( {className, color, ...props} ){
  var classname = ""
  if( props.invert ) {
      classname = `font-normal rounded text-sm 
                   border border-${color}-700 
                   text-${color}-700 hover: bg-gray-300
                   bg-${color}-500 hover:bg-${color}-700 
                   ${className}`
  }
  else {
      classname = `font-normal rounded 
                   py-2 px-4
                   bg-${color}-500 hover:bg-${color}-700 text-white disabled
                   ${className}`
  }

  return __Button( classname, props )

}

export function IconButton( {className, color, ...props} ){

  var clsName = ""
  if( props.invert ) {
      clsName = `text-${color}-700 font-normal bg-transparent
                 py-2 px-4 border border-${color}-500 rounded
                 h-full hover:bg-gray-300
                 ${className}`
  }
  else {
      clsName = `font-normal bg-${color}-500 text-white
                 py-2 px-4 rounded h-full hover:bg-${color}-700
                 ${className}`
  }

  const align = props.align === 'column' ? 'none' : 'inline-flex';

  return __IconButton( clsName, align, props )
}

export function ItemButton( {...props} ){

  return __ItemButton( props )
}

export function CircleButton( {className, ...props} ) {

  const clsName = `bg-red-200
                     ${className}`;
  
  return __CircleButton( clsName, props )
}

export function JobIndicator( {color, ...props} ) {

//  const clsName = `border-4 
  const clsName = `border-4 border-${color}-700
                   rounded-full w-8 h-8 flex items-center justify-center
                   hover:bg-gray-300
                   ${props.className}`;

  return (
    <div style={{borderColor:color}} className={clsName} >
      {
        React.Children.map( props.children, (child, i) => {

          const classnm = child.props.className;
//          const tabno = child.props.tabno;
          return React.cloneElement(
                 child,
                 {className:classnm + " block" } )
        })
      }
    </div>
  )
}

export function ImageButton( {className, ...props} )
{
  const clsName = `h-full 
                   ${className}`;


  return __ImageButton( clsName, props )
}

export function Select( {className, options, ...props} ){
  const classname = `border rounded text-gray-600 h-10 pl-5 pr-10 
                     ${className}`;
  
  return(
    <select className={classname}  onChange={props.onChange} >
      { 
        options.map( (d,i) => { return(
            <option key={i}  > {d} </option>
          )
        }) 
      }
    </select>
  )
}

export function TabView( props ) {
  return __TabView( props )
}

export function TabContent( props ) {
  return __TabContent( props )
}

export function Radio( {className, ...props} ) {
//console.log( props.isSelected );
  const clsName = `items-center ${className} pl-6`
  return __Radio( clsName, props )
}


export function Checkbox( {className, ...props} ) {
  const clsName = `items-center ${className} pl-6`
  return __Checkbox( clsName, props )
}

export function Dropdown( prop, override ){

  return __Dropdown( prop, override )
}
