import mapboxgl from 'mapbox-gl';
import React, { useEffect } from "react";
import MapToolbar from './SearchMapTbar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MBMap.scss';
import { sourceFromUrl } from './sources';
import * as Config from 'config';
import * as defaults from 'shared/defaults';

import { bundle }               from 'shared/helpers';

import { DataGrid,
//         GridToolbar,
}                           from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  grid,
  population,
  krPopTrendLayer,
  cvs,
  popHighlighted,
  classinfo,
  cellSelected,
  housetype,
} from './layers';

import { popScheme } from './styles';

//import SmallbizData from "components/Table/Smallbiz";

const Stores = ({show, data, onItemClick, ...props}) =>
{
//  const labels = [ "인구구성", "인구변동", "주거형태" ]

  const handleItemClick = (e) => {

    onItemClick(e)
  }

  return(
    <div  className={`absolute h-88 z-20 left-0 ml-2 mt-20 bg-white w-1/3 ${show}`} >
      <div className="py-4 pl-4 bg-blue-50 text-gray-700"> 
        원하는 행을 클릭하면 해당 상권으로 맵이 이동하고 관련 챠트가 팝업 표시 됩니다. 
      </div>

      <div elevation="5" className={`h-72 ${show}` }>
        <DataGrid
          density='compact'
          columns={data.columns}
          rows={data.rows}
          autoHeight={false}
          pageSize={5}
          loading={false}
          disableColumnMenu={true}
          checkboxSelection={false}
          onRowClick={ (param) => {handleItemClick(param)} }
        />
      </div>
    </div>
  )
}


var map;
var selected = new Map();
const Main = React.forwardRef( ({ onSelectionChange, onTargetChange, reset, SPARAM }, ref ) => 
{

  const uid = bundle.getUid()
  bundle.set( uid, bundle.key.search_sparam, SPARAM);

//  const SEOUL_COORDS = [37.532600, 127.024612];
  const cfg = Object.assign({}, Config, defaults);

  const LOADING_INIT       = 10001;
  const LOADING_INPROGRESS = 10002;
  const LOADING_LIST_DONE  = 10091;
  const LOADING_CHART_DONE = 10092;
  const LOADING_ERROR      = 90001;

  var mapContainer;
//  var selected = new Map();

  const stateDef = {
      lng   : cfg.map.coords.SEOUL.lng,
      lat   : cfg.map.coords.SEOUL.lat,
      zoom  : cfg.map.zoom,
      tool  : 'select',
      action: 'new',
  };

  const [loadingState, setLoadingState] = React.useState(LOADING_INIT)
  const [storeList,    setStoreList   ] = React.useState(null);
//  const [show,         setShow        ] = React.useState(reset === true ? "hidden" : "show" );
  const [show,         setShow        ] = React.useState("hidden")
  const [state,        setState       ] = React.useState(stateDef);
  const [didMount,     setDidMount    ] = React.useState( false )
//  const [selection,    setSelection   ] = React.useState( null )
//  const [ppop,         setPpop        ] = React.useState(SPARAM.pop);   // param for population
//  const [pdist,        setPdist       ] = React.useState(SPARAM.dist);  // param for distance
//  const [psic,         setPsic        ] = React.useState(SPARAM.sic);   // Standard Industry Code
//  const [pcells ,      setPcells      ] = React.useState([]);           // cells(keycodes) selected

  useEffect( () => {

    if( didMount ) {
      compWillUpdate()
    }
    else 
    {
      compDidMount()
      setDidMount(true)
    }

//  }, [selection] );
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] );

/***
  useEffect( () => {
//    setShow( reset === true ? "hidden" : "show" );

    if( reset === true ) {
      setShow( "hidden" )
      setStoreList(null);
    }
    else {
      setShow( "show" )
    }
  }, [reset] );
**/

  const compDidMount = () => {

    map = new mapboxgl.Map({
      container: mapContainer,
      style: cfg.maptiler.styleUrl,
      center: [state.lat, state.lng],
      zoom: state.zoom,
    });

    map.boxZoom.disable();

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    map.addControl(scale);
    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', onMapLoad);
    map.on('move', () => {
      state.lng = map.getCenter().lng.toFixed(4)
      state.lat = map.getCenter().lat.toFixed(4)
      state.zoom = map.getZoom().toFixed(2)
      setState( state )
    });

    disableDragRotate();
  }

  const compWillUpdate = () => {
    loadSelection(true);
  }

  const loadSelection = (selection) => {
    if (selection) {
      selected = new Map(selection.population);
      map.setFilter('population-highlighted', [
        'in',
        'key_code',
        ...Array.from(selected.keys()),
      ]);
    }
  }

  const onContextMenu = (e) => {}

  const disableDragRotate = () => {
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    map.on('contextmenu', onContextMenu);
  }

  async function fetchStoreList(dist, pop, sic, cells ) {

    if(cells.length === 0 ){
       setShow('hidden');
       return;
    }

    setShow('show');
    setLoadingState( LOADING_INPROGRESS )

    const buf = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/stores/list`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({population: pop, distance: dist, class3Cd: sic, keyCodes: cells })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {

       if( data.length > 0 ) {

         data = data.map( (d,i) => { return( Object.assign(
                                           {
                                             id : i+1,
                                             nocvs: d.nocvs ? parseInt(d.nocvs) : 0,
                                             pop: d.pop ? parseInt(d.pop) : 0,
                                             rate: d.rate ? (d.rate) : 0,
                                             key_code: d.key_code ? (d.key_code) : '',
                                             lat: d.lat ? (d.lat) : 0,
                                             lng: d.lng ? (d.lng) : 0,
                                           }, d
                                   ))
                      })

         buf['columns'] = theaderStores(Object.keys(data[0]))
         buf['rows'] = data
         setStoreList(buf);

      }
      else {

         const dummy = [
           {id:'', dummy:"해당 업종의 경쟁 업소가 없는 지역입니다." }
         ]
         buf['columns'] = theaderStores(Object.keys(dummy[0]))
         buf['rows'] = dummy
         setStoreList(buf);

      }
      setLoadingState( LOADING_LIST_DONE )
    })
    .catch( error => {
       setLoadingState( LOADING_ERROR )
       setStoreList(null);
    });
  }

  const theaderStores = (header) => {

    const format = [
          {'hid': 'id',        'headerName': 'NO',            'hide' : false,
                               'resizable' : false,           'width': 90, 'type': 'number'},
          {'hid': 'pop',       'headerName': '인구',          'hide' : false,
                               'resizable' : false,           'width': 170, 'type': 'number'},
          {'hid': 'nocvs',     'headerName': '기존업소 수',    'hide' : false,
                               'resizable' : false,           'width': 170, 'type': 'number'},
          {'hid': 'rate',      'headerName': '점포당 인구',   'hide' : false,
                               'resizable' : false,           'width': 170, 'type': 'number'},
          {'hid': 'key_code',  'headerName': 'KEY_CODE 인구', 'hide' : true,
                               'resizable' : false,           'width': 150, 'type': 'string'},
          {'hid': 'lat',       'headerName': 'lat',           'hide' : true,
                               'resizable' : false,           'width': 100, 'type': 'number'},
          {'hid': 'lng',       'headerName': 'lng',           'hide' : true,
                               'resizable' : false,           'width': 100, 'type': 'number'},
          {'hid': 'dummy',     'headerName': ' ',             'hide' : false,
                               'resizable' : false,           'width': 500, 'type': 'string'},
    ]
    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i]
      const item = format.find( it => it.hid === hid )

      columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'headerName': item ? item.headerName : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string',
          'valueGetter': item ? item.valueGetter : '',
          'headerAlign': 'center'
      })
    }

    return( columns )
  }



  async function onMapLoad () {

    const canvas = map.getCanvasContainer();

    // Add vector tile sources and layers
    map.addSource(
      'population',
      await sourceFromUrl(`${cfg.net.enabled.url}:${cfg.net.enabled.port.mbtiles}/kr.cvsWithPopHtype`),
    );


    map.addLayer(grid);
    map.addLayer(population);
    map.addLayer(popHighlighted);

    map.addLayer(krPopTrendLayer);

    map.addLayer(cvs);
    map.addLayer(classinfo);
    map.addLayer(cellSelected);
    map.addLayer(housetype);


    /** Load selection */
//    loadSelection(selection);

    let selStart;
    let selCurrent;
    let selBoxElement;
    let selButton;

    // Return the xy coordinates of the mouse position
    const mousePos = e => {
      var rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    };

    const mouseDown = e => {
      const { tool } = state;

      selButton = e.button;

      if (!(tool === 'select' || tool === 'deselect')) return;

      if( (e.shiftKey && e.button === 0) ) return;

      // Disable default drag panning when the shift key is held down.
      map.dragPan.disable();

      // Call functions for the following events
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);

      // Capture the first xy coordinates
      selStart = mousePos(e);
    };

    const onMouseMove = e => {
      // Capture the ongoing xy coordinates
      selCurrent = mousePos(e);

      // Append the box element if it doesnt exist
      if (!selBoxElement) {
        selBoxElement = document.createElement('div');
        selBoxElement.classList.add('boxdraw');
        canvas.appendChild(selBoxElement);
      }

      var minX = Math.min(selStart.x, selCurrent.x),
          maxX = Math.max(selStart.x, selCurrent.x),
          minY = Math.min(selStart.y, selCurrent.y),
          maxY = Math.max(selStart.y, selCurrent.y);

      // Adjust width and xy position of the box element ongoing
      var pos = 'translate(' + minX + 'px,' + minY + 'px)';
      selBoxElement.style.transform = pos;
      selBoxElement.style.WebkitTransform = pos;
      selBoxElement.style.width = maxX - minX + 'px';
      selBoxElement.style.height = maxY - minY + 'px';

    };

    const onMouseUp = (e) => {
      // Capture xy coordinates
      finish([selStart, mousePos(e)]);
    };

    const onKeyDown = (e) => {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    };

    const finish = (bbox) => {
      const { action } = state;
      const rightClicked = selButton === 2;

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mouseup', onMouseUp);

      if (selBoxElement) {
        selBoxElement.parentNode.removeChild(selBoxElement);
        selBoxElement = null;
      }

      if (bbox) {

        const featPop = map.queryRenderedFeatures(bbox, {
          layers: ['population' ],
        });

        const featPopTimeseries = map.queryRenderedFeatures(bbox, {
          layers: ['krPopTrendLayer'],
        });


/**
        const featClass2 = map.queryRenderedFeatures(bbox, {
          layers: ['classinfo'],
        });
**/
        const featHousetype = map.queryRenderedFeatures(bbox, {
          layers: ['housetype'],
        });

        const features = {
          'population': featPop,
          'ptrend': featPopTimeseries,
          'housetype': featHousetype
        }

        if (features.length >= 5000) {
          return window.alert('Select a smaller number of features');
        }

        if (action === 'new') {
          if (!rightClicked) {
            clearSelection();
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'add') {
          if (!rightClicked) {
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'remove') {
          if (!rightClicked) {
            unselectAll(features);
          } else {
            selectAll(features);
          }
        } else {
          console.log('Invalid action.');
        }

        // true: selection by row click, false: selection by mouse drag
        onSelectionChange(features, map.getCenter() );
        loadSelection(selected);

//        setLoadingState( LOADING_INPROGRESS )
        const cells  = featPop.map(d => { return d.id });
        const sparam = bundle.get( "default", bundle.key.search_sparam );
        fetchStoreList( sparam.dist, sparam.pop, sparam.sic, cells );

      }
      map.dragPan.enable();
    };

    // dragging behaviour.
    canvas.addEventListener('mousedown', mouseDown, true);
  }

  const selectAll = (features) => {

    const { population, ptrend, housetype } = features;

    var featPop = new Map()
    var featPtrend = new Map()
    var featHousetype = new Map()

    population.forEach(feature => {
      select(feature.id, feature.properties, featPop);
    });

    ptrend.forEach(feature => {
      select(feature.id, feature.properties, featPtrend);
    });

    housetype.forEach(feature => {
      select(feature.id, feature.properties, featHousetype);
    });

    selected = {
        'population': featPop,
        'ptrend': featPtrend,
        'htype': featHousetype,
    }
  }

  const select = (key_code, feature, buf) => {

    buf.set( key_code, feature )
  }

  const unselectAll = (features) => {
    features.forEach(feature => {
      unselect(feature.properties.key_code);
    });
  }


  const unselect = (gid) => {
    selected.delete(gid);
  }
  const clearSelection = () => {
    if( selected.cvsclass2  ) selected.cvsclass2.clear()
    if( selected.poptrend   ) selected.poptrend.clear()
    if( selected.population ) selected.population.clear()
  }

  const handleToolChange = (newTool) => {
    if (newTool === 'clearAll') {
      map.setFilter('highlightedLayer', ['in', 'key_code', '']);
    }
    state.tool = newTool
    setState( state );

  }

  const handleActionChange = (newAction) => {
    state.action = newAction
    setState( state )
  }

  const handleRowClick = ( param ) => {

    const coord = { lng: parseFloat(param.row.lng, 4).toFixed(4),
                    lat: parseFloat(param.row.lat, 4).toFixed(4) }

    setLoadingState( LOADING_INPROGRESS )
    map.jumpTo( {center: [coord.lng, coord.lat], zoom: 15} )

    map.setFilter('cell-selected', [
      '==',
      'key_code',
      param.row.key_code
    ]);

    map.once('idle', (e) =>  {

      const population = map.querySourceFeatures('population', {
          sourceLayer: 'krpop',
          filter: ['==', 'key_code', param.row.key_code ]
      });

      const poptrend = map.querySourceFeatures('population', {
          sourceLayer: 'krpoptrend',
          filter: ['==', 'key_code', param.row.key_code ]
      });

/**
      const cvsclass2info = map.querySourceFeatures('population', {
          sourceLayer: 'krretailcvsclass2',
          filter: ['==', 'key_code', param.row.key_code ]
      });
**/

      const housetype = map.querySourceFeatures('population', {
        sourceLayer: 'krhousetype',
        filter: ['==', 'key_code', param.row.key_code ]
      });

      const cellInfo = {
        'population': population,
        'ptrend': poptrend,
//        'cvsclass2': cvsclass2info,
        'housetype': housetype
      }
      clearSelection();
      selectAll(cellInfo);
      onTargetChange(selected, map.getCenter() );
      setLoadingState( LOADING_CHART_DONE )

    });
  }

  return (

    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"}} >
      <div style={{width: "100%"}  }>


        <div className="relative" style={{height: "600px", width: "100%"  }}>

        {
          loadingState === LOADING_INPROGRESS &&
          <div style={{height: "600px"}} className={`absolute flex w-full z-10 justify-center`} >
            <div className="flex items-center">
              <CircularProgress color={'secondary'} size={100} thickness={4} />
            </div>
          </div>
         }
          <div className="interactive-map">
            <div
                ref={node => (mapContainer = node)}
                className="mapContainer"
            />
            {
              loadingState !== LOADING_INIT && storeList &&
              <Stores data={storeList} onItemClick={handleRowClick} show={show} />
            }

            <div className="overlay">
              <MapToolbar
                  className="toolbar"
                  onToolChange={handleToolChange}
                  onActionChange={handleActionChange}
              />
              <div className="legend">
                {popScheme.colors.map((c, i) => (
                  <div key={"legendScheme"+i}>
                    <span style={{ backgroundColor: c }}></span>
                    {popScheme.thresholds[i]}
                  </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
});

export default Main;
