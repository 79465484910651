import mapboxgl from 'mapbox-gl';
import React, { useEffect } from "react";
import MapToolbar from './MapToolbar';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MBMap.scss';
import { sourceFromUrl } from './sources';
import * as Config from 'config';


import {
  grid,
  population,
  popHighlighted,
  krPopTrendLayer,
  highlightedGridLayer,
  housetype,
} from './layers';
import { populationScheme } from './styles';

//const TOKYO_COORDS = [37.6762, 139.6503];
const SEOUL_COORDS = [37.532600, 127.024612];
var   Gmap;

/**
const MBMap = React.forwardRef(
               ({onSelectionChange, onBookmarkAdd }, ref ) => {
//               ({selection, onSelectionChange, onBookmarkAdd }, ref ) => {
**/
export default function MBMap( {mapCenter, onSelectionChange, onBookmarkAdd } )
{

console.log( mapCenter );
  const stateDef = {
      lng: SEOUL_COORDS[0],
      lat: SEOUL_COORDS[1],
      zoom: 11,
      tool: 'select',
      action: 'new',
  };

  var mapContainer;
  var selected = new Map();
  var popSelected = new Map();
  var ptrendSelected = new Map();
  var housetypeSelected = new Map();

  const cfg = Object.assign({}, Config);

  const [ didMount,   setDidMount  ] = React.useState( false )
  const [ state,      setState     ] = React.useState( stateDef );
  const [ selection,  setSelection ] = React.useState( null );
//  const [ popSelection,  setPopSelection ] = React.useState( null );
//  const [ ptrendSelection,  setPtrendSelection ] = React.useState( null );
//  const [ housetypeSelection,  setHousetypeSelection ] = React.useState( null );

  const compWillUpdate = (sel) => {
    if (sel) {
      selected = new Map(sel);
/***
    const coord = { lng: parseFloat(mapCenter[0], 4).toFixed(4),
                    lat: parseFloat(mapCenter[1], 4).toFixed(4) }

    Gmap.jumpTo( {center: [coord.lng, coord.lat]} )
***/

      Gmap.setFilter('grid-highlighted', [
        'in',
        'key_code',
        ...Array.from(selected.keys()),
      ]);
    }
  }

  const compDidMount = () => {

    Gmap = new mapboxgl.Map({
      container: mapContainer,
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=ElkgcjiPHXyLvEovADXa',
//      center: [127.024612, 37.532600],
      center: mapCenter,
      zoom: 12,
    });

    Gmap.boxZoom.disable();

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    Gmap.addControl(scale);
    Gmap.addControl(new mapboxgl.NavigationControl());

/**
    const coord = { lng: parseFloat(mapCenter[0], 4).toFixed(4),
                    lat: parseFloat(mapCenter[1], 4).toFixed(4) }

    Gmap.jumpTo( {center: [coord.lng, coord.lat]} )
**/
    Gmap.on('load', onMapLoad);
    Gmap.on('move', () => {
      state.lng = Gmap.getCenter().lng.toFixed(4)
      state.lat = Gmap.getCenter().lat.toFixed(4)
      state.zoom = Gmap.getZoom().toFixed(2)
      setState( state )
    });

    disableDragRotate();
  }

  useEffect( () => {

    if( didMount ) {
      compWillUpdate(selection)
    }
    else
    {
      compDidMount()
      setDidMount(true)
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, didMount] );

  const onContextMenu = (e) => {}

  const disableDragRotate = () => {
    Gmap.dragRotate.disable();
    Gmap.touchZoomRotate.disableRotation();
    Gmap.on('contextmenu', onContextMenu);
  }

  async function onMapLoad() {

    const canvas = Gmap.getCanvasContainer();

    // Add vector tile sources and layers
    Gmap.addSource(
      'population',
//      await sourceFromUrl(`${cfg.aws.ec2.url}:${cfg.aws.ec2.port.mbtiles}/kr.popAndTrend`),
      await sourceFromUrl(`${cfg.net.enabled.url}:${cfg.net.enabled.port.mbtiles}/kr.cvsWithPopHtype`),
//      await sourceFromUrl(`${cfg.aws.ec2.url}:${cfg.aws.ec2.port.mbtiles}/kr.cvsWithPop`),
    );

    Gmap.addLayer(grid);
    Gmap.addLayer(population);
    Gmap.addLayer(popHighlighted);

    Gmap.addLayer(krPopTrendLayer);
    Gmap.addLayer(highlightedGridLayer);
    Gmap.addLayer(housetype);

    let selStart;
    let selCurrent;
    let selBoxElement;
    let selButton;

    // Return the xy coordinates of the mouse position
    const mousePos = e => {
      var rect = canvas.getBoundingClientRect();
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    };

    const mouseDown = e => {
      const { tool } = state;

      selButton = e.button;
      const rightClicked = selButton === 2;

      if( rightClicked ) return;

//      if (!(tool === 'select' || tool === 'deselect')) return;
      if( tool === 'pan' ) return;

      if (e.shiftKey && e.button === 0) return;

      // Disable default drag panning when the shift key is held down.
      Gmap.dragPan.disable();

      // Call functions for the following events
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);

      // Capture the first xy coordinates
      selStart = mousePos(e);
    };

    const onMouseMove = e => {
      // Capture the ongoing xy coordinates
      selCurrent = mousePos(e);

      // Append the box element if it doesnt exist
      if (!selBoxElement) {
        selBoxElement = document.createElement('div');
        selBoxElement.classList.add('boxdraw');
        canvas.appendChild(selBoxElement);
      }

      var minX = Math.min(selStart.x, selCurrent.x),
        maxX = Math.max(selStart.x, selCurrent.x),
        minY = Math.min(selStart.y, selCurrent.y),
        maxY = Math.max(selStart.y, selCurrent.y);

      // Adjust width and xy position of the box element ongoing
      var pos = 'translate(' + minX + 'px,' + minY + 'px)';
      selBoxElement.style.transform = pos;
      selBoxElement.style.WebkitTransform = pos;
      selBoxElement.style.width = maxX - minX + 'px';
      selBoxElement.style.height = maxY - minY + 'px';
    };

    const onMouseUp = e => {
      // Capture xy coordinates
      finish([selStart, mousePos(e)], e);
//      finish([selStart, mousePos(e)]);
    };

    const onKeyDown = e => {
      // If the ESC key is pressed
      if (e.keyCode === 27) finish();
    };

    const finish = (bbox, e) => {
      const { action } = state;
      const rightClicked = selButton === 2;

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mouseup', onMouseUp);

      if (selBoxElement) {
        selBoxElement.parentNode.removeChild(selBoxElement);
        selBoxElement = null;
      }

      if (bbox) {

        const featPop = Gmap.queryRenderedFeatures(bbox, {
          layers: ['population'],
        });

        const featPopTimeseries = Gmap.queryRenderedFeatures(bbox, {
          layers: ['krPopTrendLayer'],
        });

        const featHousetype = Gmap.queryRenderedFeatures(bbox, {
          layers: ['housetype'],
        });


        const features = {
          'population': featPop,
          'ptrend': featPopTimeseries,
          'housetype': featHousetype
        }

        if (features.length >= 5000) {
          return window.alert('Select a smaller number of features');
        }

        if (action === 'new' || action == null) {
          if (!rightClicked) {
            clearSelection();
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'add') {
          if (!rightClicked) {
            selectAll(features);
          } else {
            unselectAll(features);
          }
        } else if (action === 'remove') {
          if (!rightClicked) {
            unselectAll(features);
          } else {
            selectAll(features);
          }
        } else {
          console.log('Invalid action.');
        }

console.log( selected );
        onSelectionChange(selected, Gmap.getCenter());
      }
      Gmap.dragPan.enable();
    };

    canvas.addEventListener('mousedown', mouseDown, true);
  }

//  const onMapClick = (e) => {}

  const selectAll = (features) => {

    const { population, ptrend, housetype } = features;
    const { population: popPrev } = popSelected;
    const { ptrend: ptPrev }    = ptrendSelected;
    const { housetype: htPrev }    = housetypeSelected;

    var featPop = new Map()
    var featPtrend = new Map()
    var featHousetype = new Map()

    population.forEach(feature => {
      select(feature.id, feature.properties, featPop);
    });

    ptrend.forEach(feature => {
      select(feature.id, feature.properties, featPtrend );
    });

    housetype.forEach(feature => {
      select(feature.id, feature.properties, featHousetype );
    });


    if(  popPrev ) {
      popPrev.forEach((value, key) => {
        select(key, value, featPop);
      });
      ptPrev.forEach((value, key) => {
        select(key, value, featPtrend);
      });
      htPrev.forEach((value, key) => {
        select(key, value, featHousetype);
      });
    }

    selected = {
        'population': featPop,
        'ptrend': featPtrend,
        'housetype': featHousetype,
    }

    popSelected = {
        'population': featPop,
    }
    ptrendSelected = {
        'ptrend': featPtrend,
    }
    housetypeSelected = {
        'housetype': featHousetype,
    }

//        'ptrend': featPtrend,
    setSelection( featPop );
//    setPopSelection( featPop );
//    setPtrendSelection( featPtrend );
//    setHousetypeSelection( featHousetype );
  }

  const select = (gid, feature, buf) => {

    buf.set(gid, feature )
  }

  const unselectAll = (features) => {

    features.forEach(feature => {
      unselect(feature.properties.key_code);
//      this.unselect(feature.properties.key_code);
    });
  }

  const unselect = (gid) => {
    selected.delete(gid);
  }

  const clearSelection = () => {
    if( selected.population ) selected.population.clear()
    if( selected.ptrend     ) selected.ptrend.clear()
    if( selected.housetype  ) selected.housetype.clear()
  }

  const handleToolChange = (newTool) => {

    if (newTool === 'clearAll') {
      Gmap.setFilter('grid-highlighted', ['in', 'key_code', '']);
    }

    state.tool = newTool
    setState( state );
  }

  const handleActionChange = (newAction) => {
    state.action = newAction      // null: new, 'add': add
    setState( state )
  }

  const handleBookmarkAdd = () => {
    const center = Gmap.getCenter();
    onBookmarkAdd(selected, center);
  }

  return (

    <>
      {/* eslint-disable-next-line no-return-assign */}
      <div className="interactive-map">
        <div 
          ref={node => (mapContainer = node)}
          className="mapContainer"
        />
        <div className="overlay">
          <MapToolbar
            className="toolbar"
            onToolChange={handleToolChange}
            onActionChange={handleActionChange}
            onBookmarkAdd={handleBookmarkAdd}
          />
          <div className="legend">
            {populationScheme.colors.map((c, i) => (
              <div key={"legendScheme"+i}>
                <span style={{ backgroundColor: c }}></span>
                {populationScheme.thresholds[i]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
//});

//export default MBMap;
