export const net = {
  enabled : {
//    url: 'http://ec2-13-209-69-32.ap-northeast-2.compute.amazonaws.com',
    url: 'https://api.ubimemes.com',
//    url: 'http://192.168.4.32',
    port : {
      be: 3002,
      mbtiles: 5000
    }
  },
  ec2 : {
    url: 'https://api.ubimemes.com',
    port : {
      be: 3002,
      mbtiles: 5000
    }
  },
  dev : {
    url: 'http://192.168.4.32',
    port : {
      be: 3002,
      mbtiles: 5000
    }
  }
};
export const maptiler = {
  styleUrl: 'https://api.maptiler.com/maps/streets/style.json?key=4gVkDqVUee77OM3olijM',
};
