import * as d3 from 'd3';

export const strings  = {
  watermark: "Ubimemes Research Inc.",
  norec:     "No data is available."
};

export const pyramid = {
  legendGutter: 30,
  margin: { top: 10, bottom: 20, left: 20, right: 20, middle: 28 },
  maxValue: 5.0,
  wrapWidth: 60,
  aspectRatio: 1.0,
  formatString: '.1s',
  fontSize: 12,
  rangeFactor: 1.0,
  numTicks: 5,
  useLegend: true,
  units: '(단위:명)',
  colors: ['steelblue', 'firebrick' ],
};
export const mline = {
  margin: { top: 40, bottom: 20, left: 50, right: 30 },
  legendGutter: 30,
  formatString: '.3s',
  fontSize: 12,
  pointSize: 14,
  rangeFactor: 1.25,
  numTicks: 5,
  strokeWidth: 3,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
  useLegend: true,
  units: '단위:개',
};
/** Shaded area radar */
export const radar3 = {
  width: 480,
  height: 480,
  margin: { top: 50, bottom: 0, left: 40, right: 40 },
  levels: 5,
  maxValue: 5,
  labelFactor: 1.2,
  wrapWidth: 60,
  opacityArea: 0.9,
  dotRadius: 8,
  opacityCircles: 0.1,
  strokeWidth: 2,
  roundStrokes: false,
  aspectRatio: 1.0,
  fontSize: 12,
  formatString: '.2f',

//  labelGutter: 1.2,
//  legendGutter: 50,
  labelGutter: 1.4,
  legendGutter: 50,
};

export const barwide = {
  width: 2400,
  margin: { top: 40, right: 40, bottom: 80, left: 60 },
  legendGutter: 0,
  maxValue: 0,
  wrapWidth: 60,
  aspectRatio: 1.0,
//  formatString: '.3s',
  numFormat: '.3s',
  fontSize: 14,
  rangeFactor: 1.25,
//  rangeFactor: 2.5,
  numTicks: 5,
  useLegend: false,
  colors: d3.schemeCategory10,
  firstColor: null,
  lastColor: null,
};

export const map = {
  coords : {
    SEOUL: {lng: 37.532600, lat: 127.024612},
  },
  zoom: 13,
}

