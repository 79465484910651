import { Button   as __Button   } from "components/Controls/Buttons";
import { TextView as __TextView } from "components/Controls/TextView";


export const Btn = ( {color, ...props} ) =>
{
  const clsName = `py-0 px-2 rounded text-sm
                   bg-red-700 hover:bg-gray-300 text-white
                   ${props.className} `;

  return __Button( clsName, props )
}
export const TextView = ( {color, ...props} ) =>
{

/**
  var clsName = `px-3 text-gray-700 hover:text-${color}-700 cursor-pointer
                  ${props.className}`;
**/
  var clsName = `px-3 ${props.className}`;

  return __TextView( clsName, props )
}

export const MenuItem = ( {...props} ) =>
{
/**
  const clsName = `flex-inline px-4 text-sm text-left
                   text-white font-semibold
                   hover:text-blue-100 cursor-pointer
                   ${props.className} `;
**/

/**
  const clsName = `{
                    (props.transparent 
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " flex flex-col lg:flex-row list-none" +
                    " px-3 py-4 lg:py-2 flex items-center text-xs font-bold" +
                    (props.className) 
                  }` 
**/
  const clsName = (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                   ` flex flex-col lg:flex-row list-none
                     px-3 lg:py-2 flex items-center text-xs font-bold
                    (props.className) 
                   `



  return __Button( clsName, props )
}

export const SignButton = ( {color, ...props} ) =>
{
  let clsName = "";
    clsName = `bg-transparent text-blue-600 text-xl py-2 
               ${props.className}`;

  return __Button( clsName, props )
}
