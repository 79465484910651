import React  from "react";

export const Button = ( clsName, props ) =>
{
  const classname = `disabled:opacity-50 ${props.disabled}
                     ${clsName} `;
  return(
    <button className={classname} disabled={props.disabled} 
            onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export const IconButtonL = ( clsName, props ) =>
{
  const classname = `disabled:opacity-50 ${props.disabled}
                     pr-2
                     ${clsName} `;
  return(
    <div className="flex">
      <button className={classname} disabled={props.disabled}
              onClick={props.onClick}>
        {props.children}
      </button>
      {props.text}
    </div>
  )
}

export const IconButtonR = ( clsName, props ) =>
{
  const classname = `disabled:opacity-50 ${props.disabled}
                     pl-2
                     ${clsName} `;
  return(
    <div className="flex">
      {props.text}
      <button className={classname} disabled={props.disabled}
              onClick={props.onClick}>
        {props.children}
      </button>
    </div>
  )
}

export const ImageButton = ( clsName, props ) =>
{
  const classname = `h-full
                     font-normal shadow-lg
                     ${clsName}`;

/**
  return(
    <button className={classname}  onClick={props.onClick} >

        {React.cloneElement( props.children, { className:"bg-transparent", onClick: props.onEdit}) }

      <div className={`${align}`}>
        {props.text}
      </div>
    </button>
  )
**/

  return(
    <button className={classname}  onClick={props.onClick} >
        {props.children}
    </button>
  )


}




/***
export const BlockButton = ( clsName, props ) =>
{
  const classname = `
                     ${clsName}`;

  return(
    <button className={classname} onClick={props.onClick}>
      {props.children}
    </button>
  )
}
***/

