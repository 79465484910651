import React  from "react";
import Paper          from '@material-ui/core/Paper';

export function TextView( clsName, props ){

  const classname = `${clsName}`
  return(
      <div className={classname} >
          {props.text}
          {props.children}
      </div>
  )
}

export function EditText( clsName, color, props )
{

  const fg = "text-" + color + "-700";
  const classname = `shadow appearance-none border rounded
                     w-full py-2 px-3 ${fg} leading-tight
                     focus:outline-none focus:shadow-outline
                     ${clsName}`;

  return(
      <input className={classname} type="text" 
             placeholder={props.hint} value={props.text}
             onChange={props.onChange} />
  )
}


export function Button( clsName, props ){
  const classname = `disabled:opacity-50 ${props.disabled}
                     ${clsName} `;
  return(
    <button className={classname} disabled={props.disabled} 
            onClick={props.onClick} >
      {props.text}
    </button>
  )
}


export function Actionbar( clsName, color, props ){

  const classname = `m-2
                     ${clsName}`;
  return(

    <div className={classname}>
    {
      props.children
/**
      React.Children.map( props.children, (child, i) => {
        return( React.cloneElement( child,
                { className :`m-2 hover:bg-${color}-300 cursor-pointer`, 
                  onClick   : child.props.onClick}
        ))
      })
**/
    }
    </div>
  )
}


export function IconButton( clsName, align, props ){

  const classname = `h-full 
                     font-normal shadow-lg
                     ${clsName}`;

  return(
    <button className={classname}  onClick={props.onClick} >

{/**
        {prop.children}
        {React.cloneElement( props.children, { className:"bg-transparent", onClick: props.onEdit}) }
**/}
        {React.cloneElement( props.children, { className:"bg-transparent" }) }

      <div className={`${align}`}>
        {props.text}
      </div>
    </button>
  )
}


export function ItemButton( prop ){

  const classname = `bg-blue-500 hover:bg-blue-700 text-white text-xs m-2 py-2 px-2 rounded
                     w-full ${prop.className}
                     ${prop.visibility}`;
  return(
    <button className={classname} onClick={prop.onClick} >
      {prop.text}
      <i className="fas fa-pencil-alt" id={prop.id} onClick={prop.onEdit}></i>
    </button>
  )
}



export function ImageButtonOld( prop ){
  const classname = `text-blue-700 font-semibold bg-white shadow
                     py-2 px-4 border border-blue-500 rounded
                     h-full
                     ${prop.visibility} ${prop.className}`;


  return(
    <button className={classname} onClick={prop.onClick}>
      {prop.text}
    <div>
      <i className="fas fa-pencil-alt" id={prop.id} onClick={prop.onEdit}></i>
    </div>
    </button>
  )
}

export function ImageButton( clsName, props ){

  return(
    <button className={clsName} onClick={props.onClick}>
      {props.children}
    </button>
  )
}


export function CircleButton( clsName, props )
{
  const classname = `border border-gray-700
                     rounded-full w-12 h-12 flex items-center justify-center
                     hover:bg-red-500 ${clsName}
                     `;
  return(
    <div className={classname}>
{
        React.Children.map( props.children, (child, i) => {

          const classnm = child.props.className;
          return React.cloneElement(
                 child,
                 {className:classnm + " block" } )
        })
}
    </div>
  )

}


export function PillText( clsName, props ){
/**
  const classname = `border border-gray-300 text-xs 
                     rounded-full py-1 px-6 flex items-center justify-center
                     ${clsName}`;
**/
  const classname = `border border-gray-300 text-xs 
                     rounded-full py-1 px-6 flex items-center justify-center
                     ${clsName}`;
  return(
    <div className={classname} onClick={props.onClick}>
      {props.text}
    </div>
  )
}

export function CircleText( prop, override ){
  const classname = `border border-gray-700 
                     rounded-full w-24 h-24 flex items-center justify-center
                     ${override.color.default}
                     hover:${override.color.hover}
                     ${override.color.text}
                     ${prop.className} ${override.className}`;
  return(
    <div className={classname} onClick={prop.onClick}>
      {prop.text}
    </div>
  )
}
// not in use (as of 11-Mar-2021)
export function Select( prop, override ){
  const classname = `p-4 border rounded outline-none shadow w-full
                     ${prop.visibility}`;

  return(

    <select id={prop.id}
            className={classname} onChange={prop.onChange}>
      {
        prop.items.map( (d, i) => { return(
            <option key={i} className="py-4"> {d} </option>
          )
        })
      }
    </select>
  )
}

export function Dropdown( prop, override ){

  const [ show, setShow ] = React.useState('hidden')
  const select = () => {
    const vis = show === 'hidden' ? 'show' : 'hidden';
    setShow(vis)
  }

  const classname = `flex bg-gray-100 text-gray-800 text-base
                     rounded-lg px-6 text-sm py-3 overflow-hidden
                     border-2 border-gray-300 w-full shadow
                     focus:outline-none focus:border-white
                     ${prop.className}`;

  return(
    <div onClick={select}>
      <button className={classname}>
        <div className="w-full text-left">
            <span>{prop.title}</span>
        </div>
        <div className="text-right">
            {prop.children}
        </div>
      </button>
      <div className={`mt-2 py-2 w-full bg-white rounded-lg shadow-xl ${show}`} >
      {
        prop.items.map( (d) => {
          return(
            <a key={d.id}
               href={d.href}
               className="block px-4 py-2 text-gray-800 mb-2
               hover:bg-indigo-500 hover:text-white">{d.name}</a>
          )

        })
      }
      </div>
    </div>
  )
}


/**
export function Select( clsName, props ) {

  return(
    <select className={clsName}>
      <option>Choose a color</option>
      <option>Red</option>
      <option>Blue</option>
      <option>Yellow</option>
      <option>Black</option>
      <option>Orange</option>
      <option>Purple</option>
      <option>Gray</option>
      <option>White</option>
    </select>

  )
}
**/

export function Radio( clsName, props ){

  const align = props.alignX ? "inline-flex mr-5" : "mr-5"
  const classname = `${align} ${clsName}`;

  return(

    <div className={classname}>
      <input id={props.id} type="radio" name={props.name} className="form-radio" 
             disabled={props.disabled}
             onChange={props.onChange}
             checked={props.isSelected} />
      <label className="inline-flex items-center cursor-pointer">
        <span className="ml-2 cursor-pointer">{props.text}</span>
      </label>
    </div>

  )
//        <input type="radio" className="form-radio" value={props.value} checked={props.checked} onChange={() => {setChecked(!checked)}} />
//             value={props.value} defaultChecked={props.defaultChecked} onClick={props.onClick} onChange={props.onChange} />
}


export function Checkbox( clsName, props ){

  const align = props.alignX ? "inline-flex mr-5" : "mr-5"
  const classname = `${align} ${clsName}`;

  return(
    <div className={classname}>
      <input id={props.id} type="checkbox" name={props.name} className="cursor-pointer"
             onChange={props.onChange}
             checked={props.isSelected} />
      <label className="inline-flex items-center">
        <span className="ml-2 ">{props.text}</span>
      </label>
    </div>
  )
}


export function CardView ( clsName, props )
{

  const classname = `${clsName}`
  return(
    <Paper elevation={5} className={classname} >
    {
      props.children && props.children.length > 1
        ? props.children.map( (d,i) => {
            return d
          })
        : props.children
    }
    </Paper>
  )
}

/**
export function CardView ( clsName, props )
{

  const classname = `w-full h-full
                     flex flex-col h-full justify-center bg-pink      
                     ${clsName}`
  return(
    <Paper elevation={5} className="w-full h-full" >
      <div className={classname} >
    {
      props.children && props.children.length > 1
        ? props.children.map( (d,i) => {
            return d
          })
        : props.children
    }
      </div>
    </Paper>
  )
}
**/
export function TabView( {labels, tabidx, children} ) {

  const actColor = 'red'
  const inactColor = 'gray'

  const [openTab, setOpenTab] = React.useState(tabidx);

  React.useEffect( () => {
    setOpenTab(tabidx);
  }, [tabidx])


  return(
    <div>
      <ul className="flex divide-solid my-2 divide-x divide-gray-400" >
      {
        labels.map( (label, i) => {

          return(
            <li className="-mb-px mr-1 last:mr-0 text-center" key={i}>
              <a className={ "text-xs font-bold uppercase px-3 py-1 rounded block leading-normal " +
                             (openTab === i+1
                                        ? `text-${actColor}-600 bg-transparent`
                                        : `text-${inactColor}-600 bg-transparent`)
/**
                                        ? "text-" + actColor   + "-600 bg-" + 'transparent'
                                        : "text-" + inactColor + "-600 bg-" + 'transparent')
**/
                           }
                 href={`#tab${i+1}`}
                 onClick={e => {
                          e.preventDefault();
                          setOpenTab(i+1);
                         }}
              >
                {label}
              </a>
            </li>
          )
        })
      }
      </ul>

      <div className="bg-gray-300 w-full my-0 border border-gray-300" />

      {

        React.Children.map( children, (child, i) => {

          const classnm = child.props.className;
          const tabno = child.props.tabno;
          return React.cloneElement(
                 child,
                 {className:classnm + (openTab === tabno ? " block" : " hidden") } )
        })
      }

    </div>
  )
}

export function TabContent( props )
{
  return(
    <div className={"p-5 w-full bg-white border border-gray-300 shadow"} >
      {props.children}
    </div>
  )
}

