import React  from "react";

export const TabView = ( {labels, tabidx, children} ) =>
{

  const actColor = 'red'
  const inactColor = 'gray'

  const [openTab, setOpenTab] = React.useState(tabidx);

  React.useEffect( () => {
    setOpenTab(tabidx);
  }, [tabidx])


  return(
    <div>
      <ul className="flex divide-solid my-2 divide-x divide-gray-400" >
      {
        labels.map( (label, i) => {

          return(
            <li className="-mb-px mr-1 last:mr-0 text-center" key={i}>
              <a className={ "text-xs font-bold uppercase px-3 py-1 rounded block leading-normal " +
                             (openTab === i+1
                                       ? `text-${actColor}-600 bg-transparent`
                                       : `text-${inactColor}-600 bg-transparent`)
/**
                                       ? "text-" + actColor   + "-600 bg-" + 'transparent'
                                       : "text-" + inactColor + "-600 bg-" + 'transparent')
**/
                           }
                 href={`#tab${i+1}`}
                 onClick={e => {
                          e.preventDefault();
                          setOpenTab(i+1);
                         }}
              >
                {label}
              </a>
            </li>
          )
        })
      }
      </ul>

      <div className="bg-gray-300 w-full my-0 border border-gray-300" />

      {

        React.Children.map( children, (child, i) => {

          const classnm = child.props.className;
          const tabno = child.props.tabno;
          return React.cloneElement(
                 child,
                 {className:classnm + (openTab === tabno ? " block" : " hidden") } )
        })
      }

    </div>
  )
}

export const TabContent = ( props ) =>
{
  return(
    <div className={"p-5 w-full bg-white border border-gray-300 shadow"} >
      {props.children}
    </div>
  )
}
