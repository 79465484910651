export const firebaseConfig = {

  apiKey: "AIzaSyBJ0d1TK0xrF5DSxp38Y6PFvpdXuPP2EKM",
  authDomain: "www.ubiforecast.com",
  projectId: "ubi-forecast",
  storageBucket: "ubi-forecast.appspot.com",
  messagingSenderId: "643154230070",
  appId: "1:643154230070:web:6e0e7930860262a01d5197",
  measurementId: "G-BW3H2ZRFXY"
};

