import {
//  Button          as __Button,
//  ImageButton     as __ImageButton,
  EditText        as __EditText,
  TextView        as __TextView,
  PillText        as __PillText,
  Dropdown        as __Dropdown,
  Radio           as __Radio,
} from "exports.js";
import {
  Button          as __Button,
  IconButtonL     as __IconButtonL,
//  IconButtonR     as __IconButtonR,
} from "components/Controls/Buttons";

export function Button( {color, ...props} ) 
{
  var clsName = ""
  if( props.outlined )
  {
//    clsName = `py-4 px-16 rounded text-sm 
    clsName = `py-4 lg:px-16 md:px-8 rounded text-sm 
                  bg-transparent hover:bg-gray-300 text-${color}-700
                  border border-${color}-700
                  ${props.className} `;
  }
  else
  {
    clsName = `py-4 lg:px-16 md:px-8 rounded text-sm 
                  bg-${color}-600 hover:bg-${color}-700 text-white
                  ${props.className} `;
  }

  return __Button( clsName, props )
}

export function IconButton( {...props} )
{
  const clsName = `${props.className}`

  return __IconButtonL( clsName, props )
}

export function EditText( {...props} )
{
  const clsName = `${props.className}`;
  const color   = 'gray';

  return __EditText( clsName, color, props )
}

export function TextView( {...props} ) {
  const clsName = `${props.className}`;

  return __TextView( clsName, props )
}

export function PillText( {className, color, ...props} ) {

  const classname = `text-${color}-400 ${className} `;

  return __PillText( classname, props )
}

export function Dropdown( prop, override ){

  return __Dropdown( prop, override )
}

export function Radio( {className, ...props} ) {
  const clsName = `items-center ${className} pl-6`

  return __Radio( clsName, props )
}
