import React                    from "react";
import {useHistory}             from 'react-router-dom';
import * as d3                  from 'd3';

import DeleteIcon               from '@material-ui/icons/DeleteOutline';
import MoreHorizIcon            from '@material-ui/icons/MoreHoriz';
import CheckIcon                from '@material-ui/icons/Check';
import { DataGrid }             from '@material-ui/data-grid';
import Slider                   from '@material-ui/core/Slider';

import * as Config              from "config";

import { bundle }               from 'shared/helpers';
import { strings }              from 'shared/strings';
import { featured as FEATURED } from 'shared/featured';
import { SimData as SIMDATA }   from 'shared/simdata';


import Footer                   from "components/Footer";
import Navbar                   from "components/Navbar.js";
import CalDlg                   from 'components/CalDlg/CalDlg';

import { defData, }             from './default';

import {
//         TextView,
         TextInput,
//         IconButton,
         TabView,
         TabContent,
         Radio,
         JobIndicator,
         ImageButton,
         Checkbox,
}                               from './exports';
import {
         Button,
         IconButtonR,
         TextView,
}                               from './wrapper';


//
// global variables
//
const S        = strings.SimCatMain
const Currency = strings.common.currency;

var   uid = "demo"

// Nok: number of keycodes
var   Nok = bundle.get("default", bundle.key.region_keycodes )
            ? bundle.get("default", bundle.key.region_keycodes ).length
            : 1

const Distance = {
      min: 50,
      max: 250 * Nok,
      datastep: 50,
      labelstep: 250 * Nok / 5,
      unit: 'm',
}
const Marketshare = {
      min: 10,
      max: 100,
      datastep: 5,
      labelstep: 10,
      unit: '%',
}

const Top = ({sales}) => 
{
  const roundup = Math.round(sales);
  const salesStr  = roundup > 0
                    ? '추정 월매출: ' + roundup.toLocaleString() + Currency
                    : '';
  return(
    <div className="top-section relative mx-auto pt-4">
      <div className="grid grid-cols-2">
        <TextView className="text-gray-600"
                      type="title"
                      text={S.page_title}
        />
        <TextView className="text-red-600 text-right"
                      type="title"
                      text={salesStr}
        />
      </div>
    </div>
  )
}

const Row = ( {mainCatId, tabNo, tabTitle, data, 
               cbOnValueChange: handleValueChange, cbOnDelete: handleOnDelete} ) =>
{
  const itemNo = data.no

  const [ itemName,  setItemName  ] = React.useState(data.name)
  const [ itemValue, setItemValue ] = React.useState(data.value)
  const [ itemDesc,  setItemDesc  ] = React.useState(data.desc)
  const [ itemUnit,  setItemUnit  ] = React.useState(data.unit)

  const handleOnNameChange = (e, item) => {

    setItemName(e.target.value)
    handleValueChange(tabTitle, itemNo, e.target.value, itemValue, itemUnit, itemDesc)
  }

  const handleOnDescChange = (e, item) => {

    setItemDesc(e.target.value)
    handleValueChange(tabTitle, itemNo, itemName, itemValue, itemUnit,  e.target.value)
  }

  const handleOnValueChange = (e, item) => {

    setItemValue(e.target.value)
    handleValueChange(tabTitle, itemNo, itemName, e.target.value, itemUnit, itemDesc)
  }

  React.useEffect( () => {
    setItemName(data.name)
    setItemValue(data.value)
    setItemDesc(data.desc)
    setItemUnit(data.unit)
  }, [tabNo, tabTitle, data])

  return(

    <div className="grid grid-cols-8 gap-x-4 ml-4 mb-2">
      <TextInput className="col-start-1 col-end-3" hint={S.tab_row_item} text={itemName} 
                 type="text"
                 onChange={(e) => {handleOnNameChange(e, itemName )} }/>
      <TextInput className="col-start-3 col-end-5 lg:col-end-4" hint={S.tab_row_value} text={itemValue > 0 ? itemValue : ''}   
                 type="number"
                 onChange={(e) => {handleOnValueChange(e, itemName )} } />
      <TextInput className="col-start-5 lg:col-start-4 col-end-8" hint={S.tab_row_desc} text={itemDesc} 
                 type="text"
                 onChange={(e) => { handleOnDescChange(e, itemName )} }/>
      <div className="col-start-8" >
        <ImageButton key={itemNo} className="" value={itemNo} onClick={() => handleOnDelete(itemNo)} >
          <DeleteIcon fontSize="large"  />
        </ImageButton>
      </div>
    </div>

  )
}


const Tab = ( {tabno, title, mainCatId: mainCatSelected, mainCatData: data, cbUpdated } ) =>
{

  const [rownum,  setRownum]  = React.useState(data.rows.length)
  const [record,  setRecord]  = React.useState(data)

  const [updated, setUpdated] = React.useState(false)
  const defsum = new Map()
  const [sum,     setSum]     = React.useState(defsum)
  const [total,   setTotal]   = React.useState(data.total)
  const [method,  setMethod]  = React.useState(data.totalby)   // 1: sum, 2: average
  const [unit,    setUnit]    = React.useState(data.unit)  
  const [unitEtc, setUnitEtc] = React.useState(data.unitEtc)  
  const [showEtc, setShowEtc] = React.useState(data.unit === S.tab_radio_unit_etc 
                                                             ? 'visible' : 'invisible' )
  var disableAdd = false  

  const tot2str = (val) => {
    const unitStr = unit === S.tab_radio_unit_etc ? (unitEtc ? unitEtc : '') : unit;
    setTotal( val > 0 ? '(' + val + ' ' + unitStr + ')' : '' )
  }

  const handleOnAddClick = () => {

    const newNo = record.rows.length + 1;
    setRownum(newNo)

    let rows = record.rows;
    rows = [ ...rows, {
             no      : newNo,
             name    : SIMDATA.default.sub.rows.name,
             value   : SIMDATA.default.sub.rows.value,
             unit    : SIMDATA.default.sub.rows.unit,
             desc    : SIMDATA.default.sub.rows.desc,
           }]

//    SIMDATA.update.totalby( 'default', mainCatSelected, title.id, method ); 
    SIMDATA.update.totalby( uid, mainCatSelected, title.id, method ); 
//    SIMDATA.update.rows   ( 'default', mainCatSelected, title.id, 
    SIMDATA.update.rows   ( uid, mainCatSelected, title.id, 
                           {subCatId: title.id,  subCatNm: title.name,
                            total: total, totalby: method, op: data.op , unit: unit, rows:rows} ); 
  }

  const handleOnDelete = (itemId) => {
//    SIMDATA.delete( 'default', mainCatSelected, title.id, itemId, record )
    SIMDATA.delete( uid, mainCatSelected, title.id, itemId, record )
    setRownum(rownum - 1)
  }

  const handleValueChange = (tabTitle, itemNo, itemName, itemVal, itemUnit, itemDesc) => {
    let buf = new Map(sum);

    buf.set(itemNo, itemVal)

    const tot = method === 'sum' 
                       ?  d3.sum(Array.from( buf.values() ) )
                       :  d3.mean(Array.from( buf.values() ) )
    setSum( buf )

    record.rows.forEach( (d) => {
      if( d.no === itemNo ){
        d.name  = itemName;
        d.value = itemVal;
        d.unit  = itemUnit;
        d.desc  = itemDesc;
      }
    })

    SIMDATA.update.rows( 
            uid,
            mainCatSelected, 
            title.id,
            { 
              subCatId: title.id, 
              subCatNm: title.name, 
              total: tot, 
              totalby: method, 
              op: record.op, 
              unit: record.unit, 
              rows: record.rows
            });

    SIMDATA.update.totalby( uid, mainCatSelected, title.id, method ); 

    tot2str( tot );
    setUpdated(!updated)
  }

  const handleCalTotal = (val) => {

    let buf = new Map(sum);
    const tot = val === 'sum' 
              ?  d3.sum(Array.from( buf.values() ) )
              :  d3.mean(Array.from( buf.values() ) )
    setMethod( val )
    SIMDATA.update.totalby( uid, mainCatSelected, title.id, val )
    tot2str( tot );
    setUpdated(!updated)
  }

  const handleUnitEtc = (val) => {
    setUnitEtc( val )
    SIMDATA.update.unitEtc( uid, mainCatSelected, title.id, val )
    setUpdated(!updated)
  }

  const handleUnit = (val) => {
    setUnit( val )
    SIMDATA.update.unit( uid, mainCatSelected, title.id, val )
    setUpdated(!updated)

    val === S.tab_radio_unit_etc 
         ? setShowEtc('visible')
         : setShowEtc('invisible')
  }

  React.useEffect( () => {
    const sim = SIMDATA.get(uid)
    setMethod(sim[mainCatSelected][title.id].totalby)
    setUnit(sim[mainCatSelected][title.id].unit)
    setUnitEtc(sim[mainCatSelected][title.id].unitEtc)
    setRecord( sim[mainCatSelected][title.id] );
    tot2str( sim[mainCatSelected][title.id].total );
    cbUpdated( !updated )
    setShowEtc(sim[mainCatSelected][title.id].unit === S.tab_radio_unit_etc ? 'visible' : 'invisible' )

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rownum, updated, mainCatSelected ])

  return(

    <TabContent>

      <div className="direct block">
        <TextView className="" type="title" text={`${title.name} ${total}`}/>

        {/* label */}
        <div className="flex flex-row w-full" >
          <TextView className="w-24" type="default" text={S.tab_radio_desc} />
          <div className="w-5/8 grid lg:grid-cols-8 md:grid-cols-4 sm:grid-cols-1"> 
            <Radio id="radio1" name={`radio${tabno}_method`} 
                 className="col-start-1 col-end-2"
                 alignX text={S.tab_radio_item1} isSelected={method === 'sum'}
                 onChange={() =>{handleCalTotal('sum')} }/>
            <Radio id="radio2" name={`radio${tabno}_method`} 
                 className="col-start-2 col-end-3"
                 alignX text={S.tab_radio_item2} isSelected={method === 'avg'}
                 onChange={() =>{handleCalTotal('avg')} } />
          </div>
        </div>

        {/* column for radio buttons */}
        <div className="flex flex-row w-full" >
          <TextView className="w-24" type="default" text={S.tab_radio_unit_desc} />
          <div className="w-5/8 grid lg:grid-cols-8 md:grid-cols-4 sm:grid-cols-1"> 
              <Radio id="unit1" name={`radio${tabno}_unit`}
                     alignX text={S.tab_radio_unit1} isSelected={unit === S.tab_radio_unit1}
                     onChange={(e) => { handleUnit(S.tab_radio_unit1) } }/>
              <Radio id="unit2" name={`radio${tabno}_unit`}
                     alignX text={S.tab_radio_unit2} isSelected={unit === S.tab_radio_unit2}
                     onChange={(e) => { handleUnit(S.tab_radio_unit2) } }/>
              <Radio id="unit3" name={`radio${tabno}_unit`}
                     alignX text={S.tab_radio_unit3} isSelected={unit === S.tab_radio_unit3}
                     onChange={(e) => { handleUnit(S.tab_radio_unit3) } }/>
              <Radio id="unit4" name={`radio${tabno}_unit`}
                     alignX text={S.tab_radio_unit4} isSelected={unit === S.tab_radio_unit4}
                     onChange={(e) => { handleUnit(S.tab_radio_unit4) } }/>
              <Radio id="unit4" name={`radio${tabno}_unit`}
                     alignX text={S.tab_radio_unit5} isSelected={unit === S.tab_radio_unit5}
                     onChange={(e) => { handleUnit(S.tab_radio_unit5) } }/>
              <Radio id="unit_etc" name={`radio${tabno}_unit`}
                     alignX text={S.tab_radio_unit_etc} isSelected={unit === S.tab_radio_unit_etc}
                     onChange={(e) => { handleUnit(S.tab_radio_unit_etc) } }/>
              <TextInput className={`w-32 ${showEtc}`} type="text" text={unitEtc}
                     onChange={(e) => { handleUnitEtc(e.target.value)} }/>
          </div>
        </div>

        <div className="grid grid-cols-8 gap-x-4 ml-4 mt-4 bg-gray-000">
          <TextView  className="col-start-1 col-end-3" type="small" text="항목" />
          <TextView  className="col-start-3 col-end-5 lg:col-end-4" type="small" text="값" />
          <TextView  className="col-start-5 lg:col-start-4 col-end-8" type="small" text="설명 (선택)" />
        </div>

        {
          record.rows &&
          record.rows.map( (d,i) => {
               return( 
                 <Row key={i+1} mainCatId={mainCatSelected} tabNo={tabno} tabTitle={title.name}
                                data={d} 
                                cbOnDelete={handleOnDelete}
                                cbOnValueChange={handleValueChange} />
               )
             })
        }
        <Button className="ml-4 mt-4" color="blue" disabled={disableAdd} onClick={handleOnAddClick} >
          Add
        </Button>

      </div>

    </TabContent>
  )
}

const TabPop = ( {tabno, title, mainCatId: mainCatSelected, mainCatData: data, cbUpdated } ) =>
{

  const krpop = bundle.get( uid, bundle.key.region_pop_pyramid )

  const age10 = krpop.filter( it => (it.group === "10-14" || it.group === "15-19") )
  const age20 = krpop.filter( it => (it.group === "20-24" || it.group === "25-29") )
  const age30 = krpop.filter( it => (it.group === "30-34" || it.group === "35-39") )
  const age40 = krpop.filter( it => (it.group === "40-44" || it.group === "45-49") )
  const age50 = krpop.filter( it => (it.group === "50-54" || it.group === "55-59") )
  const age60 = krpop.filter( it => (it.group === "60-64" || it.group === "65-69") )

  const age10m = d3.sum(age10.map( (d) => {return d.male  } ) );
  const age10f = d3.sum(age10.map( (d) => {return d.female} ) );
  const age20m = d3.sum(age20.map( (d) => {return d.male  } ) );
  const age20f = d3.sum(age20.map( (d) => {return d.female} ) );
  const age30m = d3.sum(age30.map( (d) => {return d.male  } ) );
  const age30f = d3.sum(age30.map( (d) => {return d.female} ) );
  const age40m = d3.sum(age40.map( (d) => {return d.male  } ) );
  const age40f = d3.sum(age40.map( (d) => {return d.female} ) );
  const age50m = d3.sum(age50.map( (d) => {return d.male  } ) );
  const age50f = d3.sum(age50.map( (d) => {return d.female} ) );
  const age60m = d3.sum(age60.map( (d) => {return d.male  } ) );
  const age60f = d3.sum(age60.map( (d) => {return d.female} ) );

  const [state, setState] = React.useState({
        ages:{ 
          age10m: {checked: data.popinfo.ages.age10m, value: age10m}, 
          age10f: {checked: data.popinfo.ages.age10f, value: age10f}, 
          age20m: {checked: data.popinfo.ages.age20m, value: age20m}, 
          age20f: {checked: data.popinfo.ages.age20f, value: age20f}, 
          age30m: {checked: data.popinfo.ages.age30m, value: age30m}, 
          age30f: {checked: data.popinfo.ages.age30f, value: age30f}, 
          age40m: {checked: data.popinfo.ages.age40m, value: age40m}, 
          age40f: {checked: data.popinfo.ages.age40f, value: age40f}, 
          age50m: {checked: data.popinfo.ages.age50m, value: age50m}, 
          age50f: {checked: data.popinfo.ages.age50f, value: age50f}, 
          age60m: {checked: data.popinfo.ages.age60m, value: age60m}, 
          age60f: {checked: data.popinfo.ages.age60f, value: age60f}, 
        },
        popSelected: '',
        popAdjusted: '',
  });

  var  updated = false
  const [coverage,      setCoverage]  = React.useState(data.popinfo.coverage);
  const [passenger,     setPassenger] = React.useState(data.popinfo.passenger);

  const updateTotal = (selected, adjusted) => {

    const sel = selected > 0 ? '(' + selected + ' 명)' : '';
    const adj = adjusted > 0 ? '(' + adjusted + ' 명)' : '';
    
    return( [sel, adj] )

  }

  const getPopSelected = (ages) => {
    const values = Object.values(ages)
    const filtered = values.filter( (d) => d.checked === true )
    const tot = d3.sum(filtered.map( (d) => {return d.value} ) );

    return( tot );
  }

  const getPopAdjusted = (selected) => {

    // 잠재고객 * (설정거리 / (250m * number_of_keycodes)) * (passenger / 100) );
    const grandtotal = Math.round(selected * (coverage/(250*Nok)) * (passenger / 100) ) ;

    return( grandtotal );
  }

  const handleCheck = (e) => {

    // 연령층 선택 체크박스 상태 보존
    const age = e.target.id;
    state.ages[age].checked = !state.ages[age].checked

    // 선택 연령층 인구 합산 결과 저장
    const selected = getPopSelected(state.ages);
//    setPopSelected( selected )


    // local storage 업데이트
    const adjusted = getPopAdjusted(selected);
    SIMDATA.update.popinfo.age(uid, mainCatSelected, title.id, age, state.ages[age].checked, adjusted )

    // 통행량, 선택 연령층 합계 갱신
    const [sel, adj] = updateTotal(selected, adjusted);
    state.popSelected = sel;
    state.popAdjusted = adj;

    setState( state )

    cbUpdated( !updated )
  }

  React.useEffect( () => {
    const selected = getPopSelected(state.ages);
    const adjusted = getPopAdjusted(selected);

    const [sel, adj] = updateTotal( selected, adjusted )
    state.popSelected = sel;
    state.popAdjusted = adj;
    setState( state );
    SIMDATA.update.popinfo.coverage(uid, mainCatSelected, title.id, coverage, adjusted );

    cbUpdated( !updated )

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ coverage ])

  React.useEffect( () => {
    const selected = getPopSelected(state.ages);
    const adjusted = getPopAdjusted(selected);

    const [sel, adj] = updateTotal( selected, adjusted )
    state.popSelected = sel;
    state.popAdjusted = adj;
    setState( state );
    SIMDATA.update.popinfo.passenger(uid, mainCatSelected, title.id, passenger, adjusted );

    cbUpdated( !updated )

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ passenger ])

  React.useEffect( () => {
    state.ages.age10m.checked = data.popinfo.ages.age10m
    state.ages.age10f.checked = data.popinfo.ages.age10f
    state.ages.age20m.checked = data.popinfo.ages.age20m
    state.ages.age20f.checked = data.popinfo.ages.age20f
    state.ages.age30m.checked = data.popinfo.ages.age30m
    state.ages.age30f.checked = data.popinfo.ages.age30f
    state.ages.age40m.checked = data.popinfo.ages.age40m
    state.ages.age40f.checked = data.popinfo.ages.age40f
    state.ages.age50m.checked = data.popinfo.ages.age50m
    state.ages.age50f.checked = data.popinfo.ages.age50f
    state.ages.age60m.checked = data.popinfo.ages.age60m
    state.ages.age60f.checked = data.popinfo.ages.age60f

    const selected = getPopSelected(state.ages);
    const adjusted = getPopAdjusted(selected);

    const [sel, adj] = updateTotal( selected, adjusted )
    state.popSelected = sel;
    state.popAdjusted = adj;

    setState( state );

    setCoverage( data.popinfo.coverage )
    setPassenger( data.popinfo.passenger )
    SIMDATA.update.popinfo.total(uid, mainCatSelected, title.id, adjusted );

    cbUpdated( !updated )

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCatSelected ])


  const marks = (min, max, step, unit) => {
    let content = [];
    let i = 0;
    for( i = min; i <= max; i += step ) {
      content.push( {value: i, label: i + unit})
    }

    // give the last label max value
    if( i - step < max ) {
      // remove the item right before the last label
//      content.pop()
      content.push( {value: max, label: max + unit})
    }
    return content 
  }


  const valuetext = (value) => {
    return `${value}°C`;
  }

  return(

    <TabContent>


      <div className="direct block">
        <TextView className="" type="title" text={`${title.name} ${state.popAdjusted}`}/>

        {/* label */}
        <div className="w-full" >
          <TextView className="w-full" type="subtitle" 
                    text={`주고객 연령층 선택 ${state.popSelected}`}/>
          <div className="lg:w-3/4 md:w-full sm:w-full grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1">


            <Checkbox id='age10m' name="checkbox" text={`10대 남성 (${age10m}명)`}
                      isSelected={state.ages.age10m.checked}
                      onChange={(e) => {handleCheck(e)} }/>
            <Checkbox id='age10f' name="checkbox" text={`10대 여성 (${age10f}명)`}
                      isSelected={state.ages.age10f.checked}
                      onChange={(e) => {handleCheck(e)} }/>

            <Checkbox id='age20m' name="checkbox" text={`20대 남성 (${age20m}명)`}
                      isSelected={state.ages.age20m.checked}
                      onChange={(e) => {handleCheck(e)} }/>
            <Checkbox id='age20f' name="checkbox" text={`20대 여성 (${age20f}명)`}
                      isSelected={state.ages.age20f.checked}
                      onChange={(e) => {handleCheck(e)} }/>

            <Checkbox id='age30m' name="checkbox" text={`30대 남성 (${age30m}명)`}
                      isSelected={state.ages.age30m.checked}
                      onChange={(e) => {handleCheck(e)} }/>
            <Checkbox id='age30f' name="checkbox" text={`30대 여성 (${age30f}명)`}
                      isSelected={state.ages.age30f.checked}
                      onChange={(e) => {handleCheck(e)} }/>

            <Checkbox id='age40m' name="checkbox" text={`40대 남성 (${age40m}명)`}
                      isSelected={state.ages.age40m.checked}
                      onChange={(e) => {handleCheck(e)} }/>
            <Checkbox id='age40f' name="checkbox" text={`40대 여성 (${age40f}명)`}
                      isSelected={state.ages.age40f.checked}
                      onChange={(e) => {handleCheck(e)} }/>

            <Checkbox id='age50m' name="checkbox" text={`50대 남성 (${age50m}명)`}
                      isSelected={state.ages.age50m.checked}
                      onChange={(e) => {handleCheck(e)} }/>
            <Checkbox id='age50f' name="checkbox" text={`50대 여성 (${age50f}명)`}
                      isSelected={state.ages.age50f.checked}
                      onChange={(e) => {handleCheck(e)} }/>

            <Checkbox id='age60m' name="checkbox" text={`60대 남성 (${age60m}명)`}
                      isSelected={state.ages.age60m.checked}
                      onChange={(e) => {handleCheck(e)} }/>
            <Checkbox id='age60f' name="checkbox" text={`60대 여성 (${age60f}명)`}
                      isSelected={state.ages.age60f.checked}
                      onChange={(e) => {handleCheck(e)} }/>
          </div>
        </div>

        <TextView className="" type="subtitle" text='잠재 고객 설정' />
        <TextView className="pl-8" type="default" 
                  text='아래 설정 거리 이내 거주 인구를 잠재 고객으로 설정 합니다.' />
        <div className="flex lg:w-1/2 md:w-full sm:w-full pl-8">
          <Slider
            className="mt-16 ml-8"
            value={coverage}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-custom"
            step={Distance.datastep}
            min={Distance.min}
            max={Distance.max}
            valueLabelDisplay="on"
            marks={marks(Distance.min, Distance.max, Distance.labelstep, Distance.unit)}
            onChange={ (event, value) => {setCoverage(value)} }
          />
        </div>

        <TextView className="pt-8" type="subtitle" text='실질 고객 설정' />
        <TextView className="pl-8" type="default" 
                  text='잠재고객 중 점포 주변을 통행하는 실질 고객의 비율을 설정 합니다.' />
        <div className="flex lg:w-1/2 md:w-full sm:w-full pl-8">
          <Slider
            className="mt-16 ml-8"
            value={passenger}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-custom"
            step={Marketshare.datastep}
            min={Marketshare.min}
            max={Marketshare.max}
            valueLabelDisplay="on"
            marks={marks(Marketshare.min, Marketshare.max, Marketshare.labelstep, Marketshare.unit )}
            onChange={ (event, value) => {setPassenger(value)} }
          />
        </div>
      </div>

    </TabContent>
  )
}


const TabComp = ( {tabno, title, mainCatId: mainCatSelected, mainCatData: data, qstring, cbUpdated } ) =>
{


  const cfg = Object.assign({}, Config );

  const [state, setState] = React.useState({
        catTotal : {val:data.total, str:''},
        subTotal1: {val:0,          str:''},
        subTotal2: {val:0,          str:''},
        weight: 
          {floor:{val:data.stores.weight.floor, checked:true}},
        stores: {
          inSameIndustry:[data.stores.inSameIndustry],
          inSimilarIndustry:[data.stores.inSimilarIndustry],
        }
  });

  var updated = false
  const [directCompetitors,   setDirectCompetitors] = React.useState(null);
  const [indirectCompetitors, setIndirectCompetitors] = React.useState(null);
  const [heightDirect,        setHeightDirect]   = React.useState(360);
  const [heightIndirect,      setHeightIndirect] = React.useState(360);

  async function fetchCompetitors( keycodes, class1Nm, class3Nm, sicNm) {

    const direct = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/same`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({'key_codes': keycodes, 'class3Nm': class3Nm })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {
       if( data.length > 0 ) {
//         data = data.map( (d,i) => {return( Object.assign({'no':i+1, 'id': d.shop_no}, d)) })
         data = data.map( (d,i) => {
           return( 
             Object.assign( 
               {
                 'no'       : i+1, 
                 'id'       : d.shop_no, 
                 'shop_no'  : d.shop_no,
                 'shop_nm'  : d.shop_nm,
                 'branch_nm': d.branch_nm,
                 'sic_nm'   : d.sic_nm,
                 'class2_nm': d.class2_nm,
                 'class3_nm': d.class3_nm,
                 'floor_no' : d.floor_no
               } 
             )
           ) 
         })

         direct['columns'] = theaderStores(Object.keys(data[0]))
         direct['rows'] = data

         setDirectCompetitors(direct)
         setHeightDirect( 360 );
       }
       else {
         const dummy = [
           {id:'9999', dummy:"해당 업종의 경쟁 업소가 없는 지역입니다." }
         ]
         direct['columns'] = theaderStores(Object.keys(dummy[0]))
         direct['rows'] = dummy
         setDirectCompetitors(direct)
         setHeightDirect( 200 );
       }

    })
    .catch( error => {
         const dummy = [
           {id:'9999', dummy:"서버 연결에 실패하였습니다. 잠시 후 다시 시도해 주십시오." }
         ]
         direct['columns'] = theaderStores(Object.keys(dummy[0]))
         direct['rows'] = dummy
         setDirectCompetitors(direct)
         setHeightDirect( 200 );
    });



    const indirect = []
    await fetch(`${cfg.net.enabled.url}:${cfg.net.enabled.port.be}/api/sic/relative`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({'key_codes': keycodes, 'class1Nm': class1Nm, 'class3Nm': class3Nm, 'sicNm': sicNm })
    })
    .then(response => {
       if( !response.ok ) {
         throw Error( response.statusText );
       }
       return response;
     })
    .then(response => { return response.json() } )
    .then(data => {
       if( data.length > 0 ) {
//         data = data.map( (d,i) => {return( Object.assign({'no':i+1, 'id': d.shop_no}, d)) })
         data = data.map( (d,i) => {
           return(
             Object.assign(
               {
                 'no'       : i+1,
                 'id'       : d.shop_no,
                 'shop_no'  : d.shop_no,
                 'shop_nm'  : d.shop_nm,
                 'branch_nm': d.branch_nm,
                 'floor_no': d.floor_no,
                 'sic_nm'   : d.sic_nm,
                 'class2_nm': d.class2_nm,
                 'class3_nm': d.class3_nm,
               }
             )
           )
         })

         indirect['columns'] = theaderStores(Object.keys(data[0]))
         indirect['rows'] = data
  
         setIndirectCompetitors(indirect)
         setHeightIndirect( 360 );
       }
       else {
         const dummy = [
           {id:'9999', dummy:"유사 업종의 경쟁 업소가 없는 지역입니다." }
         ]
         indirect['columns'] = theaderStores(Object.keys(dummy[0]))
         indirect['rows'] = dummy
         setIndirectCompetitors(indirect)
         setHeightIndirect( 200 );
       }
    })
    .catch( error => {
         const dummy = [
           {id:'9999', dummy:"서버 연결에 실패하였습니다. 잠시 후 다시 시도해 주십시오." }
         ]
         indirect['columns'] = theaderStores(Object.keys(dummy[0]))
         indirect['rows'] = dummy
         setIndirectCompetitors(indirect)
         setHeightIndirect( 200 );
    });
  }

  const theaderStores = (header) => {

    const format = [
          {'hid': 'no',        'headerName': 'No.',          'hide': false,
                               'resizable': false,           'width': 100,  'type': 'string'},
          {'hid': 'id',        'headerName': 'id.',          'hide': true,
                               'resizable': false,           'width': 100,  'type': 'string'},
/**
          {'hid': 'key_code',  'headerName': 'KEY_CODE',     'hide': true,
                               'resizable': false,           'width': 140, 'type': 'string'},
**/
          {'hid': 'shop_no',   'headerName': '상호ID',       'hide': true,
                               'resizable': false,           'width': 100, 'type': 'string'},
          {'hid': 'shop_nm',   'headerName': '상호명',       'hide': false,
                               'resizable': false,           'width': 400, 'type': 'string'},
          {'hid': 'branch_nm', 'headerName': '지점명',       'hide': true,
                               'resizable': false,           'width': 200, 'type': 'string'},
          {'hid': 'sic_nm',    'headerName': '표준산업분류', 'hide': false,
                               'resizable': false,           'width': 200, 'type': 'string'},
/**
          {'hid': 'class1_nm',   'headerName': '업종대분류', 'hide': false,
                               'resizable': false,           'width': 300, 'type': 'string'},
**/
          {'hid': 'class2_nm',   'headerName': '업종중분류', 'hide': false,
                               'resizable': false,           'width': 200, 'type': 'string'},
          {'hid': 'class3_nm',   'headerName': '업종소분류', 'hide': false,
                               'resizable': false,           'width': 200, 'type': 'string'},
/**
          {'hid': 'oaddr',     'headerName': '지번주소',     'hide': true,
                               'resizable': false,           'width': 400, 'type': 'string'},
          {'hid': 'naddr',     'headerName': '도로명주소',   'hide': true,
                               'resizable': false,           'width': 400, 'type': 'string'},
          {'hid': 'bldg_nm',   'headerName': '건물정보',     'hide': true,
                               'resizable': false,           'width': 200, 'type': 'string'},
**/
          {'hid': 'floor_no',  'headerName': '층정보',       'hide': false,
                               'resizable': false,           'width': 120, 'type': 'number'},
/**
          {'hid': 'unit_no',   'headerName': '호정보',       'hide': true,
                               'resizable': false,           'width': 80, 'type': 'number'},
**/
          {'hid': 'dummy',     'headerName': ' ',             'hide': false,
                               'resizable': false,           'width': 600, 'type': 'string'},
    ]

    const columns = []
    for( var i = 0; i < header.length; i++ ) {
      const hid = header[i]
      const item = format.find( it => it.hid === hid )
        columns.push( {
          'field': hid,
          'hide': item ? item.hide : false,
          'headerName': item ? item.headerName : '',
          'width': item ? item.width : 80,
          'type': item ? item.type : 'string',
          'valueGetter': item ? item.valueGetter : '',
          'headerAlign': 'center',
        })
    }

    return( columns )
  }

  const updateCatTotal = ( weight, total1, total2 ) => {
    // 시장점유율 = 자기점수 / 총점
    const myWeight  = weight;
    const totPoint = ((myWeight + total1) * 1.0) + (total2 * 0.23)

    const val  = Math.round( (myWeight / totPoint * 100) * 100) / 100
    const str  = val > 0
                 ? '(' + val.toLocaleString() + ' %)'
                 : '';

    return({val, str})
  }

  const refreshSubTotal1 = ( ids, data ) => {
    let total = 0;
    ids.forEach( (d,i) => {
      const item = data.rows.find( (it) => it.id === d  );

      const floor_weight = item.floor_no === '1' ? 10 : 3;
      total = total + floor_weight;
    })

    // state update
    state.subTotal1.val = Math.round(total * 100) / 100;
    state.subTotal1.str = state.subTotal1.val > 0 
                          ? '(' + state.subTotal1.val.toLocaleString() + ')' 
                          : '';

    const {val, str} = updateCatTotal(state.weight.floor.val, state.subTotal1.val, state.subTotal2.val)
    state.catTotal.val = val;
    state.catTotal.str = str;

    state.stores.inSameIndustry = ids;

    setState(state);

    // SIMDATA update
    SIMDATA.update.stores.inSameIndustry(uid, mainCatSelected, title.id, ids, state.catTotal.val );

    // notify the update
    cbUpdated( !updated )
  }

  const refreshSubTotal2 = ( ids, data ) => {
    let total = 0;
    ids.forEach( (d,i) => {
      const item = data.rows.find( (it) => it.id === d  );

      //weight = 1st floor ? 10 : 3
      const floor_weight = item.floor_no === '1' ? 10 : 3;
      total = total + floor_weight;
    })

    // state update
    state.subTotal2.val = Math.round( total * 100) / 100;
    state.subTotal2.str = state.subTotal2.val > 0 
                          ? '(' + state.subTotal2.val.toLocaleString() + ')' 
                          : '';

    const {val, str} = updateCatTotal(state.weight.floor.val, state.subTotal1.val, state.subTotal2.val)
    state.catTotal.val = val;
    state.catTotal.str = str;

    state.stores.inSimilarIndustry = ids;

    setState(state);

    // SIMDATA update
    SIMDATA.update.stores.inSimilarIndustry(uid, mainCatSelected, title.id, ids, state.catTotal.val );

    // notify the update
    cbUpdated( !updated )
  }

  const handleSelection1 = (e) => {

    console.log( e );
    let ids = ''
    if( typeof e.selectionModel === 'object' && e.selectionModel ) {
      console.log( e.selectionModel );
      ids = e.selectionModel;
    }
    else if( typeof e === 'object' && e ) {
      console.log( e );
      ids = e;
    }
    console.log( ids );

    refreshSubTotal1(ids, directCompetitors);
  }

  const handleSelection2 = (e) => {

    console.log( e );
    let ids = ''
    if( typeof e.selectionModel === 'object' && e.selectionModel ) {
      console.log( e.selectionModel );
      ids = e.selectionModel;
    }
    else if( typeof e === 'object' && e ) {
      console.log( e );
      ids = e;
    }
    console.log( ids );

    refreshSubTotal2(ids, indirectCompetitors);
  }

  const handleStoreOption = (e) => {

    // 연령층 선택 체크박스 상태 보존
    const id = e.target.id;
    let weight = 1;
    switch( id ) {
      case 'floor':
        weight = e.target.checked ? 10 : 3; 
        SIMDATA.update.stores.weight.floor(uid, mainCatSelected, title.id, weight, 100 )
        break;
      default :
        weight = 1;
        break;
    }

    // state update
    state.weight[id].checked = !state.weight[id].checked
    state.weight[id].val = weight;

    const {val, str} = updateCatTotal(state.weight[id].val, state.subTotal1.val, state.subTotal2.val)
    state.catTotal.val = val;
    state.catTotal.str = str;

    setState(state);

    // SIMDATA update
    SIMDATA.update.stores.total(uid, mainCatSelected, title.id, state.catTotal.val );

    // notify the update
    cbUpdated( !updated )

  }

  React.useEffect( () => {

    const keycodes = bundle.get(uid, bundle.key.region_keycodes);
    const industry = bundle.get(uid, bundle.key.classifications);
    fetchCompetitors(keycodes, industry.class1_nm, industry.class3_nm, industry.sic_nm );

    state.weight.floor.checked = data.stores.weight.floor > 3 ? true : false
    state.stores.inSameIndustry = data.stores.inSameIndustry;
    state.stores.inSimilarIndustry = data.stores.inSimilarIndustry;

    const {val, str} = updateCatTotal(state.weight.floor.val, state.subTotal1.val, state.subTotal2.val)
    state.catTotal.val = val;
    state.catTotal.str = str;

    setState( state );

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCatSelected, data])


  return(

    <TabContent>

      <div className="direct block">
        <TextView className="" type="title" text={`${title.name} ${state.catTotal.str}`}/>

        {/* label */}
        <div className="w-full" >
          <TextView className="w-full" type="subtitle" text='개업 예정 점포 입지 조건' />
          <div className="lg:w-1/2 md:w-full sm:w-full grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1">
            <Checkbox id='floor' name="checkbox" text='1층'
                      isSelected={state.weight.floor.checked}
                      onChange={(e) => {handleStoreOption(e)} }/>
            <Checkbox id='inhouse' name="checkbox" text='홀판매'
                      isSelected={false}
                      className="text-gray-300"
                      onChange={(e) => {} }/>
            <Checkbox id='delivery' name="checkbox" text='배달'
                      isSelected={false}
                      className="text-gray-300"
                      onChange={(e) => {} }/>
            <Checkbox id='takeout' name="checkbox" text='테이크아웃'
                      isSelected={false}
                      className="text-gray-300"
                      onChange={(e) => {} }/>
          </div>
        </div>



        <div className="bg-purple-000 py-4 ">
          <TextView className="w-1/2" type="subtitle" text={`동일 업종`} />
          {
            directCompetitors &&
            <div className="bg-purple-000 py-4 w-full"  style={{ height: heightDirect, width: '100%' }}>
              <DataGrid
                columns={directCompetitors.columns}
                rows={directCompetitors.rows}

                autoPageSize={true}
                autoHeight={false}
                pageSize={5}
                disableColumnMenu={true}

                checkboxSelection={directCompetitors.rows[0].dummy ? false : true}
                onSelectionModelChange={(e) => {handleSelection1(e)} }
                selectionModel={state.stores.inSameIndustry}
              />
            </div>
          }

        </div>
        <div className="bg-purple-000 py-4 ">
          <TextView className="w-1/2" type="subtitle" text={`유사 업종`} />
          {
            indirectCompetitors &&
            <div className="bg-purple-000 py-4 w-full"  style={{ height: heightIndirect, width: '100%' }}>
              <DataGrid
                columns={indirectCompetitors.columns}
                rows={indirectCompetitors.rows}


                autoPageSize={true}
                autoHeight={false}
                pageSize={5}
                disableColumnMenu={true}

                checkboxSelection={indirectCompetitors.rows[0].dummy ? false : true}
                onSelectionModelChange={(e) => {handleSelection2(e)} }
                selectionModel={state.stores.inSimilarIndustry}
              />
            </div>
          }
        </div>
      </div>

    </TabContent>
  )
}

const MenuButtons = ( {cbOnEdit: handleOnEdit, cbOnClick, catTotal, formula: mainFormula} ) =>
{

  const [ selected, setSelected ] = React.useState(0)

  const handleOnClick = (e, clicked, catId) => {
    setSelected(clicked)
    cbOnClick(e, catId)
  }

  return(
    <div className="relative mx-auto">
      <div className="flex">
      {
        mainFormula
        ? mainFormula.filter( (data) => {
            return data.id !== ""
          }).map( (d,i) => {
            const color = i === selected ? "white" : "pink"
            return(
              <div className="flex items-center" key={i}>
              {
                i > 0 &&
                <TextView className="px-2" text={d.op} />
              }
                <div className="h-full justify-center" >
                  <IconButtonR color="pink" className="py-2" 
                              id={d.id} text={d.name}
                              invert={i === selected ? false : true} 
                              onEdit={(e) => handleOnEdit(e)}
                              onClick={(e) => handleOnClick(e,i,d.id)} >
                    <div className="flex">
                      <TextView type="raw" className={`${color}`} text={d.name} />
                      {
                        catTotal[d.id] > 0
                        ?  <CheckIcon className="pl-2" />
                        :  <MoreHorizIcon className="pl-2" />
                      }
                    </div>
                  </IconButtonR>

                </div>
              </div>
            )
          })
        :  <div visibility="invisible" />
      }
      </div>
    </div>
  )
}

const CatButtons = ( {mainCatId, mainCatNm, formula, cbOpenTab} ) =>
{

  const [ title, setTitle ] = React.useState( mainCatNm )
  const [ total, setTotal ] = React.useState( 0 )

  const tot2str = (val) => {
    setTotal( val > 0 ? '' + val.toLocaleString() + Currency + '/월' : '' )

  }

  const tot = SIMDATA.getCatTotal(uid, mainCatId)

  const handleOnClick = (idx) => {
    cbOpenTab( idx );
  }

  React.useEffect( () => {
    const total = Math.round(tot)
    tot2str( total );
  }, [tot])

  React.useEffect( () => {
    setTitle(mainCatNm);
  }, [mainCatId, mainCatNm])

  return(
    <div className="relative text-center bg-white border border-gray-300 shadow">

      <div className="grid grid-cols-2 bg-blue-200">
        <TextView color="gray" className="p-4 text-left font-bold bg-gray-200"
                  text={title} />
        <TextView color="gray" className="p-4 text-right font-bold bg-gray-200"
                  text={total} />
      </div>

      <div className="inline-flex py-6">
      {
        formula &&
        formula.filter( (data) => {
            return data.subCatId !== ""
          }).map( (d,i) => {
            const color = d.total > 0 ? 'green' : 'red';
            const unitStr = d.unit === S.tab_radio_unit_etc ? d.unitEtc : d.unit;
            return(
              <div key={i} className="w-24 grid grid-cols-6">
                <div className="flex col-start-2 col-span-5 h-4 justify-center items-center">
                  <TextView text={`${d.total} ${unitStr}`} className="text-xs" />
                </div>
                {
                  i > 0 &&
                  <TextView className="flex justify-center items-center" text={d.op} />
                }
                <div className="flex col-start-2 col-span-5 justify-center text-center" 
                     onClick={() => {handleOnClick(i+1)}}>
                  <JobIndicator className="" color={color}>
                    {
                      d.total > 0 
                        ?  <CheckIcon style={{color:'green', fontSize:'24px' }}/>
                        :  <MoreHorizIcon fontSize="small" style={{color:'red'}}/>
                    }
                  </JobIndicator>
                </div>
                <div className="flex col-start-2 col-span-5 h-16 justify-center items-center">
                  <TextView text={d.subCatNm} className="text-xs" />
                </div>
              </div>
            )
          })
      }
      </div>
    </div>
  )
}

const Category = ( {mainCatId, mainCatNm, data, cbOnClick:handleOnClick, qstring, cbUpdated } ) => 
{

  const createLabels = ( data ) => {
    var labels = [];
    data.forEach( (d,i) => {
      labels.push( {id: d.subCatId, name: d.subCatNm} )
    })
    return labels;
  }
  const formula   = Object.values(data).filter( (d) => { return typeof d === 'object'  } )
  const tabLabels = createLabels(formula)

  var  updated = false
  const [ currentTab, setCurrentTab ] = React.useState( 1 )

  const handleChildUpdated = () => {
    cbUpdated(!updated)
  }

  const handleClickOnCatButton = (val) => {
    setCurrentTab( val );
  }

  const labels = tabLabels.map( d => d.name )

  const feat = FEATURED.get(uid)[mainCatId];
  
  return(
    <div>
      <div className="pt-2">
        <CatButtons mainCatId={mainCatId} mainCatNm={mainCatNm} formula={formula} 
                    cbOpenTab={handleClickOnCatButton} />
      </div>

      <div className="mt-4 pt-8">

        <TabView labels={labels} tabidx={currentTab} >
        {
          tabLabels.map( (d,i) => {

            if( feat[d.name] && feat[d.name].id === 'population' ) {
              return(
                <div key={i} id={"tab" + i+1} tabno={i+1} > 
                  <TabPop tabno={i+1} title={d} mainCatId={mainCatId} mainCatData={data[d.id]} 
                          cbUpdated={handleChildUpdated} />
                </div>
              )
            }

            else if( feat[d.name] && feat[d.name].id === 'marketShare' ) {
              return(
                <div key={i} id={"tab" + i+1} tabno={i+1} >
                  <TabComp tabno={i+1} title={d} mainCatId={mainCatId} mainCatData={data[d.id]} 
                          qstring={qstring}
                          cbUpdated={handleChildUpdated} />
                </div>
              )
            }

            else {
              return(
                <div key={i} id={"tab" + i+1} tabno={i+1} > 
                  <Tab tabno={i+1} title={d} mainCatId={mainCatId} mainCatData={data[d.id]} 
                          cbUpdated={handleChildUpdated} />
                </div>
              )
            }
            
          })
        }
        </TabView>

      </div>
    </div>
  )
}

/*
 *  in:
 *      - queryString : mainCatId
 *      - bundle      : main_cat_formula
 *                      sim_data(simulation data)
 *
 *  out:
 *      - queryString: mainCatid
 *      - bundle     : mainCatId (main category id)
 *                     sim_data(simulation data)
 */

export default function Main() 
{
  uid      = "demo"

  const history = useHistory();
  const qstring  = history.location.qstring

  const gotoNext    = (val) => history.push({pathname: '/demo/summary', qstring: val });
  const gotoMainCat = (val) => history.push({pathname: '/demo/sim/cm',  qstring: val });

  const mainCatFormula = bundle.get(uid, bundle.key.main_cat_formula);

  const [mainCatId, setMainCatId ] = React.useState(bundle.get(uid, bundle.key.category))
  const [open,      setOpen]       = React.useState(false);
  const [updated,   setUpdated ]   = React.useState(false)

  const [ disableDone, setDisableDone ]  = React.useState(true);
  const [ catTotal,    setCatTotal ]     = React.useState({});

  const simData = SIMDATA.get(uid )
  const brandInfo = bundle.get(uid, bundle.key.brand_info);
  brandInfo.dataReady = true;
  bundle.set( uid, bundle.key.brand_info, brandInfo );

  const openCalDlg = (e) => {

    e.stopPropagation()
    setOpen(true)
  }

  const handleOnClick = (e, catId) => {
    const mainCatName = e.target.innerText
    setMainCatId( catId )
   
    const qString = {mainCatId: mainCatName}
    gotoMainCat(qString)
  }

  const closeCalDlg = () => {
    setOpen(false);
  };

  const calculation = (_formula ) => {
    setOpen(false);
  }

/**
  const handleClickSave = (e) => {

    console.log(SIMDATA.getTotal(uid))
    console.log(SIMDATA.getCatTotal(uid, mainCatId))
  }
**/

  const handleClickDone = () => {
    gotoNext(qstring)
  }

  const handleChildUpdated = () => {

    const sim = SIMDATA.get(uid );
    const catData = Object.values(sim);

    let total = [];
    let tot = {}
    catData
      .forEach( (main,i) => {
          total[i] = 1;
          Object.values(main)
                .filter( (d) => {return typeof d === 'object'} )
                .forEach( (d,j) => { 
                  total[i] = total[i] * d.total
                  tot[main.mainCatId] = total[i]
          });
    })

    let gtotal = 1;
    Object.values(tot).forEach( (d) => { gtotal = gtotal * d })

    gtotal > 0 ? setDisableDone(false) : setDisableDone(true)
    setCatTotal( tot );

    setUpdated( !updated )
  }

  return (
    <>

      <Navbar />

      <div className="mx-auto h-full bg-gray-100" >
        <div className="container mx-auto " >

          <div className="pt-2">
            <MenuButtons cbOnClick={handleOnClick } 
                         cbOnEdit ={openCalDlg } 
                         catTotal={catTotal}
                         formula={mainCatFormula} />

          </div>
          <Top sales={SIMDATA.getTotal(uid)}/>

          <Category mainCatId={mainCatId} 
                    mainCatNm={simData[mainCatId].mainCatNm} 
                    data={simData[mainCatId]} 
                    qstring={qstring} 
                    cbUpdated={handleChildUpdated} />

          <div className="bg-red-000 py-4 text-right">

{/**
            <Button color="blue" disabled={!disableSave} onClick={handleClickSave} >
              Save
            </Button>
**/}
            <Button color="blue" className="ml-4" disabled={disableDone} onClick={handleClickDone} >
              Done
            </Button>
          </div>
        </div>
        <CalDlg open={open}
                data={defData.catItems}
                onClose={closeCalDlg}
                onCalc={ calculation } />

      </div>
      <div className="w-full pt-16 pb-2 px-16 bg-gray-100">
        <Footer />
      </div>
    </>
  );
}
