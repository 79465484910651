import React                    from "react";
import {useHistory}             from 'react-router-dom';
import * as d3                  from 'd3';


import {bundle}                 from "shared/helpers";

import Navbar                   from "components/Navbar";
import Footer                   from "components/Footer";
import MBMap                    from 'components/Map/MBMap';
import Pyramid                  from "components/Chart/Pyramid";
import Mline                    from "components/Chart/Mline";
import Radar                    from "components/Chart/Radar";
import {
        TabView, 
}                               from "components/Controls/TabView";

import {
        Card,
        CardHeader,
        CardBody,
        CardFooter,
}                               from "components/Controls/Card";

import {
        Button,
        TextView, 
}                               from "./wrapper";

const CardView = ({cols, className, ...props}) =>
{
  const clsName = `grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 divide-x-4 
                   absolute z-40 right-0 mr-20 
                   ${className}`;

  return(
    <div elevation={5} className={clsName} >
      { props.children }
    </div>
  )
}

const ChartView = ({cols, className, ...props}) =>
{
/**
    <ChartView className={`mr-20 lg:mt-40 md:mt-80 bg-white ${show}` }>
  const clsName = `mr-20 lg:mt-40 mr-20 right-0 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-${cols} divide-x-4
                   absolute z-40 right-0 mr-20
                   ${className}`;
**/
  const clsName = `absolute mr-20 right-0 z-40 
                   ${className}`;

  return(
    <div elevation={5} className={clsName} >
      { props.children }
    </div>
  )
}

const Banner = ( {show, state} ) =>
{

  const majorRate = state.majorRate ? state.majorRate + "%" : '-';
  const aptRate   = state.aptRate   ? state.aptRate   + "%" : '-';
  const year1Rate = state.year1Rate ? state.year1Rate + "%" : '-';
  const year5Rate = state.year5Rate ? state.year5Rate + "%" : '-';

  return(
    <CardView cols={4} className={`w-6/12 h-32 bg-transparent ${show}` }>

      <Card color="blue" className='bg-blue-500'>
        <CardHeader h={6} color={{bg:'transparent', fg:'white'}} >
          <TextView text="주 소비연령 비율"     type="small" />
        </CardHeader>

        <CardBody color={{bg:'transparent', fg:'white'}} >
            <TextView className="h-full font-bold items-center" type="xxlarge" 
                      text={`${majorRate}`} />
        </CardBody>

        <CardFooter h={4} color={{bg:'transparent', fg:'white'}} >
          <TextView text="주 소비연령 : 20~39세"     type="small" />
        </CardFooter>
      </Card >

      <Card color="blue" className='bg-blue-500'>
        <CardHeader h={6} color={{bg:'transparent', fg:'white'}} >
          <TextView type="small" text="아파트 비중" />
        </CardHeader>

        <CardBody color={{bg:'transparent', fg:'white'}} >
            <TextView className="h-full font-bold items-center" text={`${aptRate}`} type="xxlarge" />
        </CardBody>

        <CardFooter h={4} color={{bg:'transparent', fg:'gray'}} >
        </CardFooter>
      </Card >

      <Card color="blue" className='bg-blue-500'>
        <CardHeader h={6} color={{bg:'transparent', fg:'white'}} >
          <TextView type="small" text="사회활동인구 증감 (최근 1년)" />
        </CardHeader>

        <CardBody color={{bg:'transparent', fg:'white'}} >
          <TextView className="h-full font-bold items-center" text={`${year1Rate}`} type="xxlarge" >
          </TextView>
        </CardBody>

        <CardFooter h={4} color={{bg:'transparent', fg:'white'}} >
          <TextView type="small" text="사회활동인구 : 15~64세" />
        </CardFooter>
      </Card >

      <Card color="blue" className='bg-blue-500'>
        <CardHeader h={6} color={{bg:'transparent', fg:'white'}} >
          <TextView type="small" text="사회활동인구 증감 (최근 5년)" />
        </CardHeader>

        <CardBody color={{bg:'transparent', fg:'white'}} >
          <TextView className="h-full font-bold items-center" text={`${year5Rate}`} type="xxlarge" >
          </TextView>
        </CardBody>

        <CardFooter h={4} color={{bg:'transparent', fg:'white'}} >
          <TextView type="small" text="사회활동인구 : 15~64세" />
        </CardFooter>
      </Card >
    </CardView>
  )
}

const Tab = ({tabno, data, ...rest} ) =>
{

  return(
    data &&
    <Pyramid
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const Tab2 = ({tabno, data, ...rest} ) =>
{

  return(

    data &&
    <Mline
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const Tab3 = ({tabno, data, ...rest} ) =>
{

  return(

    data &&
    <Radar
      data={data}
      wparam={{width:'500', height:'250'}}
    />
  )
}

const BannerChart = ( {pop, trend, htype, show, ...rest} ) =>
{
  const labels = [ "인구구성", "인구변동", "주거형태" ]

  return(
    <ChartView className={`bottom-0 lg:mb-60 sm:mb-0 md:mb-40 bg-white ${show}` }>
    {
      <TabView labels={labels} tabidx={1} >
        <div key={0} id={"tab1"} tabno={1} >
          <Tab tabno={1} data={pop} />
        </div>
        <div key={1} id={"tab2"} tabno={2} >
          <Tab2 tabno={2} data={trend} />
        </div>
        <div key={2} id={"tab3"} tabno={3} >
          <Tab3 tabno={3} data={htype} />
        </div>
      </TabView>
    }
    </ChartView>
  )
}

export default function Main() {

  const uid      = "demo"
  const history  = useHistory();
  const gotoNext = (val) => history.push({pathname: '/demo/sim/g', qstring: val });
  const qstring  = history.location.qstring
  const mapCenter= bundle.get(uid, bundle.key.region_map_center)


  const [ krpop,        setKrpop      ] = React.useState(null);
  const [ ptrend,       setPtrend     ] = React.useState(null);
  const [ htype,        setHtype      ] = React.useState(null);
  const [ show,         setShow       ] = React.useState('hidden');
//  const [ nop,          setNop        ] = React.useState(0);
  const [ keycodes,     setKeycodes   ] = React.useState(0);
  const [ features,     setFeatures   ] = React.useState(null);
  const [ disableBtn,   setDisableBtn ] = React.useState(true);
  const [ state,        setState      ] = React.useState({majorRate:0, aptRate:0, year1Rate:0, year5Rate:0});

console.log( uid );

  const population = {
    data: [
      { agegroup : "0-4",    agenmMale: "age_male_5",   agenmFemale: "age_female_5",   male: 0, female: 0 },
      { agegroup : "5-9",    agenmMale: "age_male_10",  agenmFemale: "age_female_10",  male: 0, female: 0 },
      { agegroup : "10-14",  agenmMale: "age_male_15",  agenmFemale: "age_female_15",  male: 0, female: 0 },
      { agegroup : "15-19",  agenmMale: "age_male_20",  agenmFemale: "age_female_20",  male: 0, female: 0 },
      { agegroup : "20-24",  agenmMale: "age_male_25",  agenmFemale: "age_female_25",  male: 0, female: 0 },
      { agegroup : "25-29",  agenmMale: "age_male_30",  agenmFemale: "age_female_30",  male: 0, female: 0 },
      { agegroup : "30-34",  agenmMale: "age_male_35",  agenmFemale: "age_female_35",  male: 0, female: 0 },
      { agegroup : "36-39",  agenmMale: "age_male_40",  agenmFemale: "age_female_40",  male: 0, female: 0 },
      { agegroup : "40-44",  agenmMale: "age_male_45",  agenmFemale: "age_female_45",  male: 0, female: 0 },
      { agegroup : "45-49",  agenmMale: "age_male_50",  agenmFemale: "age_female_50",  male: 0, female: 0 },
      { agegroup : "50-54",  agenmMale: "age_male_55",  agenmFemale: "age_female_55",  male: 0, female: 0 },
      { agegroup : "55-59",  agenmMale: "age_male_60",  agenmFemale: "age_female_60",  male: 0, female: 0 },
      { agegroup : "60-64",  agenmMale: "age_male_65",  agenmFemale: "age_female_65",  male: 0, female: 0 },
      { agegroup : "65-69",  agenmMale: "age_male_70",  agenmFemale: "age_female_70",  male: 0, female: 0 },
      { agegroup : "70-74",  agenmMale: "age_male_75",  agenmFemale: "age_female_75",  male: 0, female: 0 },
      { agegroup : "75-79",  agenmMale: "age_male_80",  agenmFemale: "age_female_80",  male: 0, female: 0 },
      { agegroup : "80-84",  agenmMale: "age_male_85",  agenmFemale: "age_female_85",  male: 0, female: 0 },
      { agegroup : "85-89",  agenmMale: "age_male_90",  agenmFemale: "age_female_90",  male: 0, female: 0 },
      { agegroup : "90-94",  agenmMale: "age_male_95",  agenmFemale: "age_female_95",  male: 0, female: 0 },
      { agegroup : "95-100", agenmMale: "age_male_100", agenmFemale: "age_female_100", male: 0, female: 0 },
      { agegroup : "100+",   agenmMale: "age_male_105", agenmFemale: "age_female_105", male: 0, female: 0 },
    ],

    init : () => {
      population.data.map( it => {
        it.male = 0;
        it.female = 0;
        return( it )
      })
    },
  }

  const drawKrpopChart = ( features ) => {

    population.init()

    const rdata = Array.from(features.values())

    rdata.map( row => {

      /* eslint-disable no-unused-vars */
      const [year, key_code, proptype, ...fields] = Object.keys(row);
      /* eslint-enable no-unused-vars */

      fields.forEach(field => {
        if( field.includes("female") ) {
          const item = population.data.find( it => it.agenmFemale === field)
          if( item ) { item.female += row[field] }
        }
        else if( field.includes("male") ) {
          const item = population.data.find( it => it.agenmMale === field)
          if( item ) item.male += row[field]
        }
      })
      return 0
    });

    const chartdata = []
    population.data.map( row => {
      chartdata.push({ group: row.agegroup, male: row.male, female: row.female });
      return 0
    });
    return( chartdata )
  }

  const drawPtrendChart = ( features ) => {

    const rdata = Array.from(features.values())

    var ages = [];
    var years = [];
    rdata.map( d => {

      ages = Object.keys(d).map( key => {
             return( key.substring(0,3) )
           })

          .filter( (item, pos, self) => {
             return( item !== "key" && item !== "pro" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })

      years =  Object.keys(d).map( key => {
             return( key.substring(4,8) )
           })

          .filter( (item, pos, self) => {
             return( item !== "code" && item !== "type" )   // skip 'key_code' & 'proptype'
           })

          .filter( (item, pos, self) => {
             return( self.indexOf(item) === pos )
           })
      return 0
    });

    var matrix = []
    matrix = new Array(ages.length).fill(0).map( () => new Array(years.length).fill(0));

    const chartdata = []
    for( var i = 0; i < ages.length; i++ ) {
      for( var j = 0; j < years.length; j++ ) {
        const idx = `${ages[i]}y${years[j]}`;
        matrix[i][j] = {'year': years[j], 'population': d3.sum(rdata.map( row => row[idx]) ) }
      }
      chartdata.push( {name: ages[i], values: matrix[i]})
    }

    return( chartdata )
  }

  const drawKrHousetypeChart = ( features ) => {

    var nosAgg = new Map();

    const rdata = Array.from(features.values())

    rdata.map( (d) => {

      const [...fields] = Object.keys(d);

      fields.forEach(field => {

        if( field === 'num_ho_single' || field === 'num_ho_multi' || field === 'num_ho_semi' ) {
          const accum = nosAgg.get( 'num_house' ) ? nosAgg.get( 'num_house' ) : 0;
          nosAgg.set( 'num_house', accum + d[field] );
        }
        else if( field === 'num_th_small' || field === 'num_th_large' ||  field === 'num_etc'  ) {
          const accum = nosAgg.get( 'num_etc' ) ? nosAgg.get( 'num_etc' ) : 0;
          nosAgg.set( 'num_etc', accum + d[field] );
        }
        else if( field === 'num_apt' ) {
          const accum = nosAgg.get( 'num_apt' ) ? nosAgg.get( 'num_apt' ) : 0;
          nosAgg.set( 'num_apt', accum + d[field] );
        }

      })

      return 0
    })

    const chartdata = []
    chartdata.push({ name: "htype", '단독주택': nosAgg.get( 'num_house' ),
                                    '아파트':   nosAgg.get( 'num_apt'   ),
                                    '다세대/빌라/기타':   nosAgg.get( 'num_etc'   )
                    })

    return( chartdata )
  }




  const newSelectionObject = function(features ) {
    const sel = {
      featPop: new Map(features.population),
      featPtrend: new Map(features.ptrend),
      featHtype: new Map(features.housetype),
      dateCreated: Date.now(),
    };
    return sel;
  };

  const handleSelectionChange = function(features, center) {
    bundle.set(uid, bundle.key.region_map_center, [center.lng, center.lat])
    setFeatures( features )
  }

  const updateMajorRate = (data) => {

    const age = data.filter( it => (
//                              it.group === "15-19" ||
                                it.group === "20-24" ||
                                it.group === "25-29" || it.group === "30-34" ||
                                it.group === "35-39"
/**
                                it.group === "40-44" ||
                                it.group === "45-49" || it.group === "50-54" ||
                                it.group === "55-59" || it.group === "60-64"
**/
                             ) )

    const ageSum = d3.sum(age.map( (d) => {return d.male + d.female  } ) );
    const tot    = d3.sum(Object.values(data)
                     .map( (d) => {return d.male + d.female} ) );

    const val  = Math.round( (ageSum / tot * 100) * 10) / 10
    state.majorRate = val;
    setState( state );
  }

  // 아파트 비중
  const updateAptRate = (data) => {
    const tot = d3.sum(Object.values(data)
                     .map( (d) => {return d['아파트'] + d['단독주택'] + d['다세대/빌라/기타']} ) );
    const val = Math.round( (data[0]['아파트'] / tot * 100) * 10) / 10
    state.aptRate = val;
    setState( state );
  }

  // 인구 변동율
  const updatePopTrend = (data) => {
    const popChanges  = [];
    data.filter(it => (it.name === 'a64'))
        .forEach((d,i) => {
              d.values.forEach( (it) => {
                popChanges.push({year: it.year, pop: it.population})
            } )
    } )

    const thisYear = d3.max( popChanges.map( (d) => {return d.year} ) );
    const year1Ago = thisYear - 1;
    const year5Ago = thisYear - 5;

    const popThisYear = popChanges.find( it => it.year == thisYear );
    const popYear1Ago = popChanges.find( it => it.year == year1Ago );
    const popYear5Ago = popChanges.find( it => it.year == year5Ago );

/**
    let y1Rate = ((popThisYear.pop - popYear1Ago.pop) * 100) / popYear1Ago.pop
        y1Rate = Math.round((y1Rate * 10)) / 10
    let y5Rate = ((popThisYear.pop - popYear5Ago.pop) * 100) / popYear5Ago.pop
        y5Rate = Math.round((y5Rate * 10)) / 10
**/

    let y1Rate = 0;
    if( popThisYear && popYear1Ago ) {
      y1Rate = ((popThisYear.pop - popYear1Ago.pop) * 100) / popYear1Ago.pop
      y1Rate = Math.round((y1Rate * 100)) / 100
    }

    let y5Rate = 0;
    if( popThisYear && popYear5Ago ) {
      y5Rate = ((popThisYear.pop - popYear5Ago.pop) * 100) / popYear5Ago.pop
      y5Rate = Math.round((y5Rate * 100)) / 100
    }

    state.year1Rate = y1Rate;
    state.year5Rate = y5Rate;
    setState( state );
  }

  const refresh = function(features ) {

    const sel = newSelectionObject(features );

    // number of population
    const rdata = Array.from(sel.featPop.values() )
    const kcodes = rdata.map( (d) => {return d.key_code} );
    setKeycodes ( kcodes );
//    const total = d3.sum(rdata.map( (row) => row['age_both_total'] ));
//    setNop(total)

    // population pyramid
    const cdata = drawKrpopChart( sel.featPop )
    setKrpop( cdata );

    updateMajorRate(cdata);	 // 20세 ~ 39세 비중

    // 인구 변동 챠트
    const tdata = drawPtrendChart( sel.featPtrend )
    setPtrend( tdata )
    updatePopTrend(tdata);	 // 인구변동율

    // housing type
    const hdata = drawKrHousetypeChart( sel.featHtype )
    setHtype( hdata )
    updateAptRate(hdata);	 // 아파트 비중

    setShow('show')
  }

  React.useEffect( () => {
      if( features && features.population.size > 0 ) {
        refresh( features )
        setDisableBtn(false)
      }
      else {
        setShow('hidden')
        setDisableBtn(true)
      }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  const handleClickNext = () => {

//    let popTotal = d3.sum( krpop.map( (d,i) => { return( d.male + d.female ); }) );
//    popTotal = Math.round( popTotal ) 


    bundle.set(uid, bundle.key.region_pop_pyramid,    krpop )
    bundle.set(uid, bundle.key.region_pop_timeline,   ptrend )
    bundle.set(uid, bundle.key.region_house_type,     htype )
    bundle.set(uid, bundle.key.region_keycodes,       keycodes )
//HSJ     FEATURED.set("default", "통행량",       popTotal );

    gotoNext(qstring)
  }

  return (
    <>
      {/* --- [ Header ] --- */}
      <Navbar />


{/**
      <div className="container mx-auto w-full bg-blue-500 pb-0 min-w-min" >
      <div className="w-full bg-blue-500 pb-0 h-64 " >
**/}
      <div className="h-full w-full" >

        <div className="top-section mx-auto pt-2 px-4">
          <TextView className="text-gray-600"
                      type="title" 
                      text="창업 예정 지역 선택" 
            />

          <TextView className="text-gray-600"
                      text="마우스를 드래그 하여 대상 지역을 선택하세요. 각 격자는 250m 단위 입니다."
          />

        </div>

        <div className="body-section relative pt-2 px-4 w-full">
          <div className="relative" style={{height: "720px"}} >

            <MBMap
              mapCenter={mapCenter}
              onSelectionChange={handleSelectionChange}
            />
            <Banner show={show} state={state}/>
            <BannerChart pop={krpop} trend={ptrend} htype={htype}  show={show}/>

          </div>
        </div>

        <div className="bottom-section relative mx-auto pt-2 px-4">
          <div className="text-right pr-16 z-0">
            <Button color="blue" disabled={disableBtn}
                    onClick={() => {handleClickNext()} } >
              Next
            </Button>
          </div>
        </div>

      </div>
      <div className="w-full pt-8 pb-2 px-16 bg-transparent">
        <Footer />
      </div>


    </>
  )
}
