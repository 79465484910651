const heights = [360]

export const class3 = {
  wparam : {
    width : 520,
    height: heights[0],
    units:'(단위:개)',
    numFormat:',',
  },
};

export const pyramid = {
  wparam : {
    width : 720,
    height: heights[0],
  }
};

export const mline = {
  wparam : {
    width : 540,
    height: heights[0],
  }
};


export const slider = {
  distance : {
    min: 5,
    max: 60,
    datastep: 5,
    labelstep: 5,
    unit: '분',
  },

  population : {
    min: 1000,
    max: 10000,
    datastep: 500,
    labelstep: 1000,
    unit: '명',
  },

}




