import React                    from "react";
import {useHistory}             from 'react-router-dom';

import ClearIcon                from '@material-ui/icons/Clear';

import { initializeApp }        from 'firebase/app';
import { getAuth, 
         signInWithPopup, 
         GoogleAuthProvider }   from "firebase/auth";

import Navbar                   from "components/Navbar.js";
import Footer                   from "components/Footer.js";

import {strings}                from "shared/strings";
import {firebaseConfig}         from "shared/firebase";

import {
        TextView,
        TH,
        TR,
        TL,
        Button,
}                               from "./wrapper";

const S = strings.Pricing;

const provider = new GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');


/**
const firebaseConfig = {

  apiKey: "AIzaSyBJ0d1TK0xrF5DSxp38Y6PFvpdXuPP2EKM",
  authDomain: "dev.ubiforecast.com",
  projectId: "ubi-forecast",
  storageBucket: "ubi-forecast.appspot.com",
  messagingSenderId: "643154230070",
  appId: "1:643154230070:web:6e0e7930860262a01d5197",
  measurementId: "G-BW3H2ZRFXY"
};
**/

export default function Main() 
{
//  const [ token, setToken ] = React.useState();
  const [ user, setUser ] = React.useState();

  const history = useHistory();
  const gotoHome = (val) => history.push({pathname: '/home',   qstring: val });

  React.useEffect( () => {
    console.log( user );
    if( user && user.emailVerified ) {
      console.log( user.email );

      const qstring = {}
      gotoHome( qstring );

    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleGetStarted = () => {

    initializeApp(firebaseConfig);

    const auth = getAuth();

    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      GoogleAuthProvider.credentialFromResult(result);
//      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      setUser( user )
      // ...
    }).catch((error) => {
      // Handle Errors here.
//      const errorCode = error.code;
//      const errorMessage = error.message;
      // The email of the user's account used.
//      const email = error.email;
      // The AuthCredential type that was used.
//      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }



  return (
    <>

      {/* --- [ Header ] --- */}
      <div className="w-full bg-white" >
        <Navbar />
      </div>

      {/* --- [ Body ] --- */}
      <div className="container mx-auto w-full py-24 bg-blue-500" >


        {/* --- [ Title ] --- */}

        <div className="px-20">
          <TextView className="text-3xl font-semibold text-white "
                    text={S.title_line1} />
          <TextView className="text-5xl font-semibold text-white pt-6"
                    text={S.title_line2} />
        </div>


        {/* --- [ Features ] --- */}
        <div className="mt-24 mx-32 rounded-xl">
          <div className="bg-white">
            <div className="grid grid-cols-4 border border-gray-200 gap-y-0 gap-x-0 " >
              <TH className="row-span-2" text='서비스 항목' />
              <TH className="row-span-2 bg-blue-400 text-white text-3xl" text='Free' />
              <TH className="col-span-2 bg-gray-700" >
                <TextView className="text-3xl font-semibold text-white" text='Premium (10,000원 / 월)' />
              </TH>

              <TH className="bg-gray-700" >
                <TextView className="text-2xl font-semibold text-white" text='Included' />
              </TH>
              <TH className="bg-gray-700" >
                <TextView className="text-2xl font-semibold text-white" text='On demand' />
              </TH>

              <TL className="" text='범용 템플릿 사용' />
              <TR className="col-span-3 bg-blue-50" text='Free' />

              <TL className="" text='매출 예측 데이터 저장' />
              <TR className="col-span-3 bg-blue-50" text='Free' />

              <TL className="" text='매출 예측 데이터 조회' />
              <TR className="col-span-3 bg-blue-50" text='Free' />

              <TL className="" text='매출 예측 보고서 조회' />
              <TR className="col-span-3 bg-blue-50" text='Free' />

              <TL className="" text='매출 예측 보고서 출력' />
              <TR className="col-span-3 bg-blue-50" text='Free' />

              <TL>
                <TextView className="py-2 text-2xl font-semibold" text='Local storage' />
                <TextView className="py-2 text-xl font-normal" text='저장 건수' />
              </TL>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='1건' />
              </TR>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='5건' />
              </TR>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                  <div  className="py-2">
                    <ClearIcon style={{color:'green'}} />
                  </div>
              </TR>


              <TL>
                <TextView className="py-2 text-2xl font-semibold" text='클라우드' />
                <TextView className="py-2 text-xl font-normal" text='저장 건수' />
                <TextView className="py-2 text-xl font-normal" text='전용 템플릿 추가' />
                <TextView className="py-2 text-xl font-normal" text='전용 템플릿 사용' />
              </TL>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
              </TR>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='5건' />
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
                <TextView className="py-2 text-xl font-normal" text='Unlimited' />
              </TR>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='5,000원 / 추가 1건' />
                <TextView className="py-2 text-xl font-normal" text='500,000원 ~' />
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
              </TR>

              <TL>
                <TextView className="py-2 text-2xl font-semibold" text='그리드맵' />
                <TextView className="py-2 text-xl font-normal" text='맵 다운로드' />
                <TextView className="py-2 text-xl font-normal" text='통계 데이터 다운로드' />
                <TextView className="py-2 text-xl font-normal" text='통계 데이터 추가' />
                <TextView className="py-2 text-xl font-normal" text='챠트 추가' />
              </TL>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='50 회 / 일' />
                <TextView className="py-2 text-xl font-normal" text='200 회 / 일' />
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
              </TR>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='500회 / 일' />
                <TextView className="py-2 text-xl font-normal" text='2,000회 / 일' />
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
                <div  className="py-2">
                  <ClearIcon style={{color:'green'}} />
                </div>
              </TR>
              <TR>
                <TextView className="py-2 text-2xl font-semibold" text='&nbsp;' />
                <TextView className="py-2 text-xl font-normal" text='5,000원 / 추가 500회' />
                <TextView className="py-2 text-xl font-normal" text='5,000원 / 추가 2,000회' />
                <TextView className="py-2 text-xl font-normal" text='500,000원 / 추가 1 건' />
                <TextView className="py-2 text-xl font-normal" text='200,000원 / 추가 1 챠트' />
              </TR>

              <TL className="text-3xl" text='서비스 선택' />
              <TR className="bg-blue-400 text-white py-8"  >
                <TextView className="pb-2 text-2xl font-semibold text-white" text='Free' />
                <Button className="mt-8" color="blue" onClick={() => {handleGetStarted()}} >
                  <TextView text='시작하기'  className="text-sm" />
                </Button>
              </TR>
              <TR className="col-span-2 bg-gray-700 text-white py-8"  >
                <TextView className="pb-2 text-2xl font-semibold text-white" text='Premium' />
                <Button className="mt-8" color="blue" disabled onClick={() => {handleGetStarted()}} >
                  <TextView text='가입하기(준비중)'  className="text-sm" />
                </Button>
              </TR>

            </div>
          </div>
        </div>
      </div>


      {/* --- [ Footer ] --- */}
      <div className="w-full py-2 bg-transparent">
        <Footer />
      </div>

    </>
  );
}
