import {
  Button          as __Button,
//  IconButtonR     as __IconButtonR,
  ImageButton     as __ImageButton,

} from "components/Controls/Buttons";
import {
  TextView        as __TextView,

} from "components/Controls/TextView";

export const Button = ( {color, ...props} ) =>
{
  var clsName = ""
  if( props.outlined )
  {
    clsName = `py-4 px-16 rounded text-sm
                  bg-transparent hover:bg-gray-300 text-${color}-700
                  border border-${color}-700
                  ${props.className} `;
  }
  else
  {
    clsName = `py-4 px-16 rounded text-sm
                  bg-${color}-600 hover:bg-${color}-700 text-white
                  ${props.className} `;
  }

  return __Button( clsName, props )
}



/**
export function IconButtonR( {...props} )
{
  const clsName = `${props.className}`

  return __IconButtonR( clsName, props )
}
**/

export const TextView = ( {className, ...props} ) => {

  var clsName = '';
  switch( props.type )
  {
    case 'title' :
         clsName = `bg-transparent rounded py-2 px-3
                    text-gray-600
                      text-2xl font-bold ${className}`
         break
    case 'large' :
    case 'subtitle' :
         clsName = `bg-transparent rounded py-2 px-3
                      text-gray-600
                      text-xl font-semibold ${className}`
         break
    case 'small' :
         clsName = `bg-transparent rounded
                      text-xs font-normal ${className}`
         break
    case 'raw' :
         clsName = `bg-transparent font-normal ${className}`
         break
    case 'normal' :
    default :
         clsName = `bg-transparent rounded
                      text-gray-600
                      font-normal ${className}`
         break
  }

  return __TextView( clsName, props )
}



export function IconButtonR( {className, color, ...props} ){

  var clsName = ""
  if( props.invert ) {
      clsName = `text-${color}-700 font-normal bg-transparent
                 py-2 px-4 border border-${color}-500 rounded
                 h-full hover:bg-gray-300
                 ${className}`
  }
  else {
      clsName = `font-normal bg-${color}-500 text-white
                 py-2 px-4 rounded h-full hover:bg-${color}-700
                 ${className}`
  }

  return __ImageButton( clsName, props )
}
