const simulation_page_title = "예상 매출 시뮬레이션";
const service_name = "UbiForecast 매출 예측";
const service_id = "UbiForecast";
//const service_name = "유비툴";
export const strings  = {
  common: {
    currency: "원",
  },

  Landing: {
//    title_line1: `${service_name} 시스템이`,
    title_line1: `${service_id} 가`,
    title_line2: `매출 예측을 쉽게 해 드립니다.`,
    subtitle_line1: "예비창업자의 매출예측은 물론",
    subtitle_line2: "프랜차이즈 본사의 예비 가맹점 지원 업무를",
    subtitle_line3: "보다 손쉽게 할 수 있습니다.",
    feat_title: `${service_id} 매출 예측 시스템은 이렇게 구성되어 있습니다.`,
    feat_col1_title: "지역 특성을 고려한 고객 설정",
    feat_col1_desc: "창업 예정 지역의 실거주 인구를 창업 업종의 특성에 맞게 연령별, 성별로 세밀하게 특정할 수 있습니다.",
    feat_col2_title: "신뢰할 수 있는 데이터",
    feat_col2_desc: "공공 기관에서 발표한 각종 사회 통계 자료를 매출예측에 손쉽게 반영할 수 있습니다.",
    feat_col3_title: "매출 예측 결과 보고서",
    feat_col3_desc: "매출 예측 시뮬레이션 결과를 요약한 보고서를 제공합니다.  보고서는 해당지역 경쟁업소 정보를 포함하고 있어서 현지 조사에 유용하게 활용할 수 있습니다.",
    start_title: "지금 시작하세요",
    start_subtitle: `${service_id}에서 매출 예측의 새로운 패러다임을 경험해 보세요.`,
  },
  Pricing: {
    title_line1: `Pricing plans`,
    title_line2: `무료로 사용하고 필요할 때만 지출하세요.`,
//    title_line2: `무료로 시작하고 사용한만큼 지불하세요. 사용한한만큼Start for free, then pay as you go.`,
  },
  Search: {
    title_line1: `상권검색`,
    subtitle_line1: `검색조건을 설정한 후 지도에서 마우스를 드래그 하여 검색 지역을 선택하면 검색 조건에 부합하는 데이터가 지도 위에 팝업 표시 됩니다.`,
  },
  Home: {
//    learnmore_title1: "Ubitool is a sales forecasting tool for your stores",
//    demo_title: "Explore a demo project",
    demo_title: "데모 체험",
    learnmore_title1: `${service_name} 서비스는 상가업소 예비 창업자를 위한 매출 예측 시스템으로 아래와 같이 활용할 수 있습니다.`,
//    learnmore_title1: `${service_name}은 아래와 같은 활용이 가능합니다.",
//    learnmore_title1: `유비밈즈 매출예측 시스템을 사용하여 아래와 같이 활용할 수 있습니다.`,
    learnmore_row1: "창업 예정 지역의 인구정보와 경쟁점 정보가 자동 연동됩니다.",
    learnmore_row2_title: "예비 창업자: ",
    learnmore_row2_desc: "창업전 사전 매출 예측",
    learnmore_row3_title: "프랜차이즈 본사: ",
//    learnmore_row3_desc: "예비 가맹점에 과학적 매출 예측 결과 제공",
    learnmore_row3_desc: "예비 가맹점을 위한 예상 매출액 산출",
    learnmore_row3_desc2: "*가맹사업법 제9조 [허위/과장된 정보제공 등의 금지]를 위한 근거 자료",
  },
  Register: {
    step1: {
//      page_title: "Let's start with a name for your project",
      page_title: "브랜드 설정",
      brand_nm_label: "브랜드명*",
      brand_nm_hint: "브랜드명을 입력하세요",
//      brand_nm_example: "mybrand-seoul-001",
      brand_nm_example: "할머니 국수",
      branch_nm_label: "지점명",
      branch_nm_hint: "지점명을 입력하세요",
      branch_nm_example: "서울 종로1가점",
    },
    step2: {
      page_title: "업종을 선택해 주세요",
      page_desc: "창업 하고자 하는 점포에 해당하는 업종을 지정 합니다.",
      class1_title: "대표업종",
      class1_desc: "대표업종을 선택하면 해당 대표업종의 하위업종(세부업종)이 표시됩니다.",
      class3_title: "세부업종",
      class3_desc: "아래에서 세부 업종을 선택하세요.",
//      class3_desc2: "항목 타이틀 옆의 메뉴버튼(",
//      class3_desc3: ")에 있는 부가기능을 사용하면 보다 편리하게 검색할 수 있습니다.",
    },
  },

  SimGuide: {
    page_title: simulation_page_title,
    page_desc: "간단한 데이터 입력을 통해 예상 매출을 추정해 볼 수 있습니다.",
    btn1_desc: "매출 예측에 필요한 기초 데이터 입력을 시작합니다.",
    btn1_desc2: "해당하는 항목을 모두 선택한 후 시작 버튼을 누르세요.",
    btn1_label: "시작",
//    btn1_label: "매출액 항목 입력 시작",
    btn2_label: "내점고객 + 배달매출 + 테이크아웃",
//    chkbox1: "내점고객",
    chkbox1_id: "inhouse",
    chkbox2_id: "delivery",
    chkbox3_id: "takeout",
    chkbox1: "점내 판매",
    chkbox2: "배달 판매",
    chkbox3: "테이크아웃 판매",
    warning1: "생성 후 변경할 수 없습니다.",
    warning2: "항목 갯수를 변경하려면 프로젝트를 삭제하고 다시 생성해야 합니다.",
  },

  SimMain: {
    page_title: simulation_page_title,
    page_desc: "매출액이 아래와 같은 항목으로 구성되었습니다.",
    page_desc2: "각 항목 버튼을 클릭하여 데이터를 입력하세요. ",
    warning: "매출액 구성 항목의 갯수는 변경할 수 없습니다.",
    warning2: "항목 갯수를 변경하려면 프로젝트를 삭제하고 다시 생성해야 합니다.",
  },
  SimCatMain: {
    page_title: simulation_page_title,
//    tab_radio_desc: "입력 항목의 계산 방법을 선택 하세요.",
    tab_radio_desc: "계산 방법:",
    tab_radio_item1: "합계",
    tab_radio_item2: "평균",
    tab_radio_unit_desc: "단위",
    tab_radio_unit1: "%",
    tab_radio_unit2: "원",
    tab_radio_unit3: "명",
    tab_radio_unit4: "개",
    tab_radio_unit5: "회",
    tab_radio_unit_etc: "기타",
    tab_row_item: 'Enter name',
    tab_row_value:'Enter value',
    tab_row_desc:'Enter description',
  },

/**
  Cal: {
    desc: "해당 하는 항목을 계산식으로 만드세요 ",
    example: "국수전문점 예시: 잠재고객 수 x 객단가x 외식비율 x 면류비율x 세대수 가중치 x 아파트 가중치",
  },
**/
  CalDlg: {
    displayText: "왼쪽의 '템플릿 선택' 버튼을 눌러 창업 업종에 적합한 템플릿을 선택하여 사용하거나 매출 공식을 직접 작성하여 사용할 수 있습니다.",
    btn_select: "선택",
    help: {
      title: "도움말",
      inhouse: {
        customers_key  : "잠재고객",
/**
        customers_value: "잠재고객을 의미 합니다. \n \
                          해당 상권에 거주하는 인구가 기본값으로 입력됩니다.\n \
                          통행량을 직접 조사하여 입력하면 보다 정확한 매출 예측이 가능합니다.",
**/
        customers_value: "잠재고객을 의미 합니다. " +
                         "해당 상권에 거주하는 인구가 기본값으로 입력됩니다." +
                         "통행량을 직접 조사하여 입력하면 보다 정확한 매출 예측이 가능합니다.",
        price_key      : "객단가",
        price_value    : "고객 한명이 1회 방문시 평균적으로 지출하는 금액을 의미 합니다. \n \
                          잘 모르겠으면 가장 인기 있는 품목의 가격을 입력해 보세요.",

        dineout_key    : "외식비율",
        dineout_value  : "통계청 발표 우리나라 사람들의 외식 비율이 자동 설정됩니다. \n \
                          해당 지역의 특성에 맞는 정확한 수치를 입력하면 매출 예측이 보다 정확해 집니다.",

        noodle_key     : "면류방문",
        noodle_value   : "외식 인구 중 면류를 이용하는 사람들의 비율이 자동 설정됩니다. ",

        household_key  : "세대수 가중치",
        household_value: "해당 지역의 세대수를 분석하여 적절한 가중치가 기본값으로 설정됩니다.",

        apt_key        : "아파트 가중치",
        apt_value      : "해당 지역의 아파트 분포를 분석하여 적절한 가중치를 기본값으로 설정합니다.",
      },
    },
    samples : {
//      title: "업종별 매출액 계산 공식 선택",
      title: "매출액 계산 공식 선택",
      desc: "아래에서 가장 비슷한 예시를 선택하거나 직접 입력할 수 있습니다.",
      inhouse: {
        kfood_key: "한식",
        kfood_general_key: "일반한식",
//        kfood_general_value1: "잠재고객	x	객단가	x	외식비율	x	방문비율	x	일반한식 비율	x	시장점유율",
        kfood_general_value1: "잠재고객	x	객단가	x	외식비율	x	일반한식 비율	x	시장점유율	x	방문외식 빈도",
        kfood_general_value2: "통행량	x	객단가	x	외식비율	x	일반한식 비율	x	시장점유율	x	방문외식 빈도",
        kfood_meat_key: "육류구이",
        kfood_meat_value1: "잠재고객	x	객단가	x	외식비율	x	육류구이 비율	x	시장점유율	x	방문외식 빈도",
        kfood_meat_value2: "통행량	x	객단가	x	외식비율	x	육류구이 비율	x	시장점유율	x	방문외식 빈도",
        kfood_stew_key: "국.탕.찜류",
        kfood_stew_value1: "잠재고객	x	객단가	x	외식비율	x	국.탕.찜류 비율	x	시장점유율	x	방문외식 빈도",
        kfood_stew_value2: "통행량	x	객단가	x	외식비율	x	국.탕.찜류 비율	x	시장점유율	x	방문외식 빈도",
        kfood_set_key: "한정식",
        kfood_set_value1: "잠재고객	x	객단가	x	외식비율	x	한정식 비율	x	시장점유율	x	방문외식 빈도",
        kfood_set_value2: "통행량	x	객단가	x	외식비율	x	한정식 비율	x	시장점유율	x	방문외식 빈도",
        kfood_seafood_key: "해산물",
        kfood_seafood_value1: "잠재고객	x	객단가	x	외식비율	x	해산물 비율	x	시장점유율	x	방문외식 빈도",
        kfood_seafood_value2: "통행량	x	객단가	x	외식비율	x	해산물 비율	x	시장점유율	x	방문외식 빈도",
        kfood_noodle_key: "면류전문",
        kfood_noodle_value1: "잠재고객	x	객단가	x	외식비율	x	면류 비율	x	시장점유율	x	방문외식 빈도",
        kfood_noodle_value2: "통행량	x	객단가	x	외식비율	x	면류 비율	x	시장점유율	x	방문외식 빈도",
        kfood_congee_key: "죽/도시락",
        kfood_congee_value1: "잠재고객	x	객단가	x	외식비율	x	죽/도시락 비율	x	시장점유율	x	방문외식 빈도",
        kfood_congee_value2: "통행량	x	객단가	x	외식비율	x	죽/도시락 비율	x	시장점유율	x	방문외식 빈도",
        cafe_key: "구내식당",
        cafe_value1: "잠재고객	x	객단가	x	외식비율	x	구내식당 비율	x	시장점유율	x	방문외식 빈도",
        cafe_value2: "통행량	x	객단가	x	외식비율	x	구내식당 비율	x	시장점유율	x	방문외식 빈도",
        fastfood_key: "패스트푸드",
        fastfood_value1: "잠재고객	x	객단가	x	외식비율	x	패스트푸드 비율	x	시장점유율	x	방문외식 빈도",
        fastfood_value2: "통행량	x	객단가	x	외식비율	x	패스트푸드 비율	x	시장점유율	x	방문외식 빈도",
        japanese_key: "일식",
        japanese_value1: "잠재고객	x	객단가	x	외식비율	x	일식 비율	x	시장점유율	x	방문외식 빈도",
        japanese_value2: "통행량	x	객단가	x	외식비율	x	일식 비율	x	시장점유율	x	방문외식 빈도",
        chinese_key: "중식",
        chinese_value1: "잠재고객	x	객단가	x	외식비율	x	중식 비율	x	시장점유율	x	방문외식 빈도",
        chinese_value2: "통행량	x	객단가	x	외식비율	x	중식 비율	x	시장점유율	x	방문외식 빈도",
        casual_key: "분식",
        casual_value1: "잠재고객	x	객단가	x	외식비율	x	분식 및 김밥 비율	x	시장점유율	x	방문외식 빈도",
        casual_value2: "통행량	x	객단가	x	외식비율	x	분식 및 김밥 비율	x	시장점유율	x	방문외식 빈도",
        coffee_key: "커피",
        coffee_value1: "잠재고객	x	객단가	x	외식비율	x	커피전문점 비율	x	시장점유율	x	방문외식 빈도",
        western_key: "서양식",
        western_value1: "잠재고객	x	객단가	x	외식비율	x	서양식 비율	x	시장점유율	x	방문외식 빈도",
        foreign_key: "기타외국식",
        foreign_value1: "잠재고객	x	객단가	x	외식비율	x	기타 외국 음식 비율	x	시장점유율	x	방문외식 빈도",
        chicken_key: "치킨",
        chicken_value1: "잠재고객	x	객단가	x	외식비율	x	치킨전문점 비율	x	시장점유율	x	방문외식 빈도",
        bakery_key: "제과",
        bakery_value1: "잠재고객	x	객단가	x	외식비율	x	제과제빵 비율	x	시장점유율	x	방문외식 빈도",
        bar_key: "주점",
        bar_value1: "잠재고객	x	객단가	x	외식비율	x	주점 비율	x	시장점유율	x	방문외식 빈도",
        catering_key: "출장음식",
        catering_value1: "잠재고객	x	객단가	x	외식비율	x	출장음식 비율	x	시장점유율	x	방문외식 빈도",
        foodtruck_key: "이동음식",
        foodtruck_value1: "잠재고객	x	객단가	x	외식비율	x	이동음식 비율	x	시장점유율	x	방문외식 빈도",
      },
      delivery: {
        kfood_key: "한식",
        kfood_general_key: "일반한식",
        kfood_general_value1: "잠재고객	x	객단가	x	외식비율	x	일반한식 비율	x	시장점유율	x	배달외식 빈도",
        kfood_general_value2: "통행량	x	객단가	x	외식비율	x	일반한식 비율	x	시장점유율	x	배달외식 빈도",
        kfood_meat_key: "육류구이",
        kfood_meat_value1: "잠재고객	x	객단가	x	외식비율	x	육류구이 비율	x	시장점유율	x	배달외식 빈도",
        kfood_meat_value2: "통행량	x	객단가	x	외식비율	x	육류구이 비율	x	시장점유율	x	배달외식 빈도",
        kfood_stew_key: "국.탕.찜류",
        kfood_stew_value1: "잠재고객	x	객단가	x	외식비율	x	국.탕.찜류 비율	x	시장점유율	x	배달외식 빈도",
        kfood_stew_value2: "통행량	x	객단가	x	외식비율	x	국.탕.찜류 비율	x	시장점유율	x	배달외식 빈도",
        kfood_set_key: "한정식",
        kfood_set_value1: "잠재고객	x	객단가	x	외식비율	x	한정식 비율	x	시장점유율	x	배달외식 빈도",
        kfood_set_value2: "통행량	x	객단가	x	외식비율	x	한정식 비율	x	시장점유율	x	배달외식 빈도",
        kfood_seafood_key: "해산물",
        kfood_seafood_value1: "잠재고객	x	객단가	x	외식비율	x	해산물 비율	x	시장점유율	x	배달외식 빈도",
        kfood_seafood_value2: "통행량	x	객단가	x	외식비율	x	해산물 비율	x	시장점유율	x	배달외식 빈도",
        kfood_noodle_key: "면류전문",
        kfood_noodle_value1: "잠재고객	x	객단가	x	외식비율	x	면류 비율	x	시장점유율	x	배달외식 빈도",
        kfood_noodle_value2: "통행량	x	객단가	x	외식비율	x	면류 비율	x	시장점유율	x	배달외식 빈도",
        kfood_congee_key: "죽/도시락",
        kfood_congee_value1: "잠재고객	x	객단가	x	외식비율	x	죽/도시락 비율	x	시장점유율	x	배달외식 빈도",
        kfood_congee_value2: "통행량	x	객단가	x	외식비율	x	죽/도시락 비율	x	시장점유율	x	배달외식 빈도",
        cafe_key: "구내식당",
        cafe_value1: "잠재고객	x	객단가	x	외식비율	x	구내식당 비율	x	시장점유율	x	배달외식 빈도",
        cafe_value2: "통행량	x	객단가	x	외식비율	x	구내식당 비율	x	시장점유율	x	배달외식 빈도",
        fastfood_key: "패스트푸드",
        fastfood_value1: "잠재고객	x	객단가	x	외식비율	x	패스트푸드 비율	x	시장점유율	x	배달외식 빈도",
        fastfood_value2: "통행량	x	객단가	x	외식비율	x	패스트푸드 비율	x	시장점유율	x	배달외식 빈도",
        japanese_key: "일식",
        japanese_value1: "잠재고객	x	객단가	x	외식비율	x	일식 비율	x	시장점유율	x	배달외식 빈도",
        japanese_value2: "통행량	x	객단가	x	외식비율	x	일식 비율	x	시장점유율	x	배달외식 빈도",
        chinese_key: "중식",
        chinese_value1: "잠재고객	x	객단가	x	외식비율	x	중식 비율	x	시장점유율	x	배달외식 빈도",
        chinese_value2: "통행량	x	객단가	x	외식비율	x	중식 비율	x	시장점유율	x	배달외식 빈도",
        casual_key: "분식",
        casual_value1: "잠재고객	x	객단가	x	외식비율	x	분식 및 김밥 비율	x	시장점유율	x	배달외식 빈도",
        casual_value2: "통행량	x	객단가	x	외식비율	x	분식 및 김밥 비율	x	시장점유율	x	배달외식 빈도",
        coffee_key: "음료",
        coffee_value1: "잠재고객	x	객단가	x	외식비율	x	커피전문점 비율	x	시장점유율	x	배달외식 빈도",
        western_key: "서양식",
        western_value1: "잠재고객	x	객단가	x	외식비율	x	서양식 비율	x	시장점유율	x	배달외식 빈도",
        foreign_key: "기타외국식",
        foreign_value1: "잠재고객	x	객단가	x	외식비율	x	기타 외국 음식 비율	x	시장점유율	x	배달외식 빈도",
        chicken_key: "치킨",
        chicken_value1: "잠재고객	x	객단가	x	외식비율	x	치킨전문점 비율	x	시장점유율	x	배달외식 빈도",
        bakery_key: "제과",
        bakery_value1: "잠재고객	x	객단가	x	외식비율	x	제과제빵 비율	x	시장점유율	x	배달외식 빈도",
        bar_key: "주점",
        bar_value1: "잠재고객	x	객단가	x	외식비율	x	주점 비율	x	시장점유율	x	배달외식 빈도",
        catering_key: "출장음식",
        catering_value1: "잠재고객	x	객단가	x	외식비율	x	출장음식 비율	x	시장점유율	x	배달외식 빈도",
        foodtruck_key: "이동음식",
        foodtruck_value1: "잠재고객	x	객단가	x	외식비율	x	이동음식 비율	x	시장점유율	x	배달외식 빈도",

      },
      takeout: {
        kfood_key: "한식",
        kfood_general_key: "일반한식",
        kfood_general_value1: "잠재고객	x	객단가	x	외식비율	x	일반한식 비율	x	시장점유율	x	포장외식 빈도",
        kfood_general_value2: "통행량	x	객단가	x	외식비율	x	일반한식 비율	x	시장점유율	x	포장외식 빈도",
        kfood_meat_key: "육류구이",
        kfood_meat_value1: "잠재고객	x	객단가	x	외식비율	x	육류구이 비율	x	시장점유율	x	포장외식 빈도",
        kfood_meat_value2: "통행량	x	객단가	x	외식비율	x	육류구이 비율	x	시장점유율	x	포장외식 빈도",
        kfood_stew_key: "국.탕.찜류",
        kfood_stew_value1: "잠재고객	x	객단가	x	외식비율	x	국.탕.찜류 비율	x	시장점유율	x	포장외식 빈도",
        kfood_stew_value2: "통행량	x	객단가	x	외식비율	x	국.탕.찜류 비율	x	시장점유율	x	포장외식 빈도",
        kfood_set_key: "한정식",
        kfood_set_value1: "잠재고객	x	객단가	x	외식비율	x	한정식 비율	x	시장점유율	x	포장외식 빈도",
        kfood_set_value2: "통행량	x	객단가	x	외식비율	x	한정식 비율	x	시장점유율	x	포장외식 빈도",
        kfood_seafood_key: "해산물",
        kfood_seafood_value1: "잠재고객	x	객단가	x	외식비율	x	해산물 비율	x	시장점유율	x	포장외식 빈도",
        kfood_seafood_value2: "통행량	x	객단가	x	외식비율	x	해산물 비율	x	시장점유율	x	포장외식 빈도",
        kfood_noodle_key: "면류전문",
        kfood_noodle_value1: "잠재고객	x	객단가	x	외식비율	x	면류 비율	x	시장점유율	x	포장외식 빈도",
        kfood_noodle_value2: "통행량	x	객단가	x	외식비율	x	면류 비율	x	시장점유율	x	포장외식 빈도",
        kfood_congee_key: "죽/도시락",
        kfood_congee_value1: "잠재고객	x	객단가	x	외식비율	x	죽/도시락 비율	x	시장점유율	x	포장외식 빈도",
        kfood_congee_value2: "통행량	x	객단가	x	외식비율	x	죽/도시락 비율	x	시장점유율	x	포장외식 빈도",
        cafe_key: "구내식당",
        cafe_value1: "잠재고객	x	객단가	x	외식비율	x	구내식당 비율	x	시장점유율	x	포장외식 빈도",
        cafe_value2: "통행량	x	객단가	x	외식비율	x	구내식당 비율	x	시장점유율	x	포장외식 빈도",
        fastfood_key: "패스트푸드",
        fastfood_value1: "잠재고객	x	객단가	x	외식비율	x	패스트푸드 비율	x	시장점유율	x	포장외식 빈도",
        fastfood_value2: "통행량	x	객단가	x	외식비율	x	패스트푸드 비율	x	시장점유율	x	포장외식 빈도",
        japanese_key: "일식",
        japanese_value1: "잠재고객	x	객단가	x	외식비율	x	일식 비율	x	시장점유율	x	포장외식 빈도",
        japanese_value2: "통행량	x	객단가	x	외식비율	x	일식 비율	x	시장점유율	x	포장외식 빈도",
        chinese_key: "중식",
        chinese_value1: "잠재고객	x	객단가	x	외식비율	x	중식 비율	x	시장점유율	x	포장외식 빈도",
        chinese_value2: "통행량	x	객단가	x	외식비율	x	중식 비율	x	시장점유율	x	포장외식 빈도",
        casual_key: "분식",
        casual_value1: "잠재고객	x	객단가	x	외식비율	x	분식 및 김밥 비율	x	시장점유율	x	포장외식 빈도",
        casual_value2: "통행량	x	객단가	x	외식비율	x	분식 및 김밥 비율	x	시장점유율	x	포장외식 빈도",
        coffee_key: "커피",
        coffee_value1: "잠재고객	x	객단가	x	외식비율	x	커피전문점 비율	x	시장점유율	x	포장외식 빈도",
        western_key: "서양식",
        western_value1: "잠재고객	x	객단가	x	외식비율	x	서양식 비율	x	시장점유율	x	포장외식 빈도",
        foreign_key: "기타외국식",
        foreign_value1: "잠재고객	x	객단가	x	외식비율	x	기타 외국 음식 비율	x	시장점유율	x	포장외식 빈도",
        chicken_key: "치킨",
        chicken_value1: "잠재고객	x	객단가	x	외식비율	x	치킨전문점 비율	x	시장점유율	x	포장외식 빈도",
        bakery_key: "제과",
        bakery_value1: "잠재고객	x	객단가	x	외식비율	x	제과제빵 비율	x	시장점유율	x	포장외식 빈도",
        bar_key: "주점",
        bar_value1: "잠재고객	x	객단가	x	외식비율	x	주점 비율	x	시장점유율	x	포장외식 빈도",
        catering_key: "출장음식",
        catering_value1: "잠재고객	x	객단가	x	외식비율	x	출장음식 비율	x	시장점유율	x	포장외식 빈도",
        foodtruck_key: "이동음식",
        foodtruck_value1: "잠재고객	x	객단가	x	외식비율	x	이동음식 비율	x	시장점유율	x	포장외식 빈도",
      },
    },
  },

};
