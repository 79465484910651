//import Paper          from '@material-ui/core/Paper';

export const Card = ({color, children, ...props}) =>
{
  const bgcolor = color === "white" ? "bg-white" : "bg-" + color + "-200";
  return(
    <div className={`flex flex-col w-full h-full 
                     border border-gray-300
                     ${bgcolor} shadow rounded-2xl p-2 ${props.className}`} >
      {children}
    </div>
  )
}

export const CardHeader = ({h, color, children, ...props}) =>
{
  const bgcolor = color.bg === "white" ? "bg-white"   : "bg-"   + color.bg + "-200";
  const fgcolor = color.fg === "white" ? "text-white" : "text-" + color.fg + "-700";
  const height  = "h-" + h;
  return(
    <div className={`flex-none ${height} ${bgcolor} ${fgcolor}`} >
      {children}
    </div>
  )
}

export const CardBody = ({color, children, ...props}) =>
{
  const bgcolor = color.bg === "white" ? "bg-white"   : "bg-"   + color.bg + "-200";
  const fgcolor = color.fg === "white" ? "text-white" : "text-" + color.fg + "-700";
  return(
    <div className={`flex-grow ${bgcolor} ${fgcolor} ${props.className}`} >
      {children}
    </div>
  )
}

export const CardFooter = ({h, color, children, ...props}) =>
{
  const bgcolor = color.bg === "white" ? "bg-white"   : "bg-"   + color.bg + "-200";
  const fgcolor = color.fg === "white" ? "text-white" : "text-" + color.fg + "-700";
  const height  = "h-" + h;
  return(
    <div className={`flex-none ${height} ${bgcolor} ${fgcolor} ${props.className}`} >
      {children}
    </div>
  )
}

export const CardButton = ( {color, children, ...props} ) => 
{
  const bgcolor = color.bg === "white" ? "bg-white"   : "bg-"   + color.bg + "-200";
  const fgcolor = color.fg === "white" ? "text-white" : "text-" + color.fg + "-900";

  return(
    <button className={`h-full w-full font-semibold ${bgcolor} ${fgcolor} ${props.className}`} 
            onMouseDown={props.onMouseDown}
            onClick={props.onClick}>
      {children}
    </button>

  )
}
