import React                    from "react";
import {useHistory}             from 'react-router-dom';
import Navbar                   from "components/Navbar.js";

import AddIcon                  from '@material-ui/icons/Add';
import DeleteIcon               from '@material-ui/icons/DeleteOutline';
import StoreIcon                from '@material-ui/icons/StoreMallDirectoryOutlined';

import Footer                   from "components/Footer.js";
import {
        Card, 
        CardHeader, 
        CardBody, 
        CardFooter, 
        CardButton,
}                               from "components/Controls/Card";

import { 
        TextView,
        Button,
}                               from "./wrapper";
import {bundle}                 from "shared/helpers";
import {strings}                from "shared/strings";

const S = strings.Home;

export default function Main() 
{
  const history  = useHistory();
//  const uid      = history.location.qstring.uid;
//  const projects   = history.location.qstring.brands;
  const uid      = bundle.getUid();
//  const projects   = bundle.get(uid, bundle.key.auth_brands );
//  const projects   = bundle.get("default", bundle.key.brand_info );



  const gotoNext = (val) => history.push( {pathname: '/register', qstring: val} );
  const gotoDemo = (val) => history.push( {pathname: '/demo/reg', qstring: val} );
  const gotoSim  = (val) => history.push( {pathname: '/sim/cm',   qstring: val} );

  const [projects, setProjects] = React.useState([bundle.get(uid, bundle.key.brand_info)])
//  const [projects, setProjects] = React.useState([bundle.get("default", bundle.key.brand_info)])
//  const [projects, setProjects] = React.useState(brands)
//console.log(bundle.get(uid, bundle.key.auth_user) );
//console.log( pids );

console.log(uid)
console.log(projects)

  const gotoSimulation = (brandNm) => {

    const qstring = {
    }

    gotoSim( qstring );
  }

  const handleAdd = () => {

/**
    bundle.del('default');
    bundle.set("default", bundle.key.region_map_center, [127.024612, 37.532600] )
**/
    // register project ID.

    const qstring = {}
    gotoNext( qstring );
  }

  const handleNext = () => {

/**
    bundle.del('default');
    bundle.set("default", bundle.key.region_map_center, [127.024612, 37.532600] )
**/
    bundle.del(uid);
    bundle.set(uid, bundle.key.region_map_center, [127.024612, 37.532600] )

    const qstring = {}
    gotoNext( qstring );
  }

  const handleDemo = () => {

//    bundle.clear();
    bundle.del('demo');
    bundle.set("demo", bundle.key.region_map_center, [127.024612, 37.532600] )

    const qstring = {}
    gotoDemo( qstring );
  }

  const handleDelete = () => {
//    bundle.clear();
//    bundle.del('default');
console.log( uid );
    bundle.del(uid);
    setProjects([]);
  }


  return (
    <>
      {/* --- [ Header ] --- */}
      <Navbar />

      <div className="w-full" >

        <div className="absolute bg-blue-500 h-80 w-full" />
        <div className="relative container mx-auto min-w-min pt-24 items-center">
          <TextView type="subtitle" text="Your stores" 
                    className="text-xl font-semibold w-full text-white pl-12" />

          <div className="divide-y divide-gray-400 gap-y-8" >

            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-6 px-12">
              <div className="w-full h-64 py-4">
              {
                projects[0] && projects[0].dataReady === true 
                  ? 
                    <Button onClick={() => {handleAdd()}} color="blue" className="">
                      <AddIcon fontSize="large" />
                      <TextView text="신규 등록($10)" className="font-semibold pt-2"/>
                    </Button>
                  :
                    <Button onClick={() => {handleNext()}} color="blue" className="">
                      <AddIcon fontSize="large" />
                      <TextView text="신규 등록(FREE)" className="font-semibold pt-2"/>
                    </Button>
              }
              </div>

              {
                projects[0] && projects[0].dataReady === true && projects.map( (d) => {
                  return(
                    <div key={d.brandId} className="bg-red-000 w-full max-w-sm h-64 px-0 py-4">

                      <Card color="white">
                        <CardHeader h={8} color={{bg:'white', fg:'gray'}} >
                        </CardHeader>

                        <CardBody color={{bg:'white', fg:'gray'}} >
                          <CardButton color={{bg:'white', fg:'gray'}} 
                                  onClick={() => gotoSimulation(d)} text={d.branchName} >
                            <TextView className="font-bold" text={d.brandName} />
                            <TextView className="font-bold" text={d.branchName} />
                          </CardButton>
                        </CardBody>

                        <CardFooter h={8} color={{bg:'white', fg:'gray'}} >
                          <div className="flex h-full justify-end items-center">
                            <button onClick={() => handleDelete()}>
                              <DeleteIcon fontSize="default" />
                            </button>
                          </div>
                        </CardFooter>
                      </Card>

                    </div>
                  )
                })
              }
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-6 px-12 pt-8">
              <div className="w-full h-64">
                <Button onClick={() => {handleDemo()}} color="indigo" >
                  <TextView className="font-semibold" text={S.demo_title} />
                </Button>
              </div>
              <div className="w-full h-full max-w-3xl sm:col-span-2">
                <Card className="grid lg:grid-cols-3" >
                  <div className="col-span-2"> 
                    <TextView className="font-bold text-normal"
                              text={S.learnmore_title1} />
                    <TextView className="pt-4 font-bold" text={S.learnmore_row2_title} />
                    <TextView className="pl-12" text={S.learnmore_row2_desc} />
                    <TextView className="pt-4 font-bold" text={S.learnmore_row3_title} />
                    <TextView className="pl-12" text={S.learnmore_row3_desc} />
                    <TextView className="pl-12 text-sm text-blue-600" text={S.learnmore_row3_desc2} />

                    <TextView className="font-bold"
                              text={S.learnmore_desc2} />
                  </div> 
                  <div className="flex text-center justify-center items-center hidden lg:inline-flex"> 
                    <StoreIcon fontSize="large" style={{fontSize:'72' }} />
                  </div> 
                </Card>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="w-full pt-32 pb-2 px-16 bg-transparent">
        <Footer />
      </div>
    </>
  );
}
