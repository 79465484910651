export function TextView( clsName, props ){

  const classname = `${clsName}`
  return(
      <div className={classname} onClick={props.onClick} >
          {props.text}
          {props.children}
      </div>
  )
}

export function EditText( clsName, color, props )
{

  const fg = "text-" + color + "-700";
  const classname = `shadow appearance-none border rounded
                     w-full py-2 px-3 ${fg} leading-tight
                     focus:outline-none focus:shadow-outline
                     ${clsName}`;

  return(
      <input className={classname} type="text"
             placeholder={props.hint} value={props.text}
             onChange={props.onChange} />
  )
}

export function PillText( clsName, props ){
  const classname = `border border-gray-300 text-xs
                     rounded-full py-1 px-6 flex items-center justify-center
                     ${clsName}`;
  return(
    <div className={classname} onClick={props.onClick}>
      {props.text}
    </div>
  )
}

export function CircleText( prop, override ){
  const classname = `border border-gray-700
                     rounded-full w-24 h-24 flex items-center justify-center
                     ${override.color.default}
                     hover:${override.color.hover}
                     ${override.color.text}
                     ${prop.className} ${override.className}`;
  return(
    <div className={classname} onClick={prop.onClick}>
      {prop.text}
    </div>
  )
}

