import {
        TextView     as __TextView,
}                    from "components/Controls/TextView";
import {
        Button  as __Button,
}                    from "components/Controls/Buttons";

export const TextView = ( {color, ...props} ) =>
{

  var clsName = `bg-transparent px-3
                  ${props.className}`;

  return __TextView( clsName, props )
}

export const  Button = ( {type, fg, bg, ...props} ) =>
{
  if( type === 'top' )      return StartButtonTop( props );
  else if( type === 'bot' ) return StartButtonBot( props );
}

export const  StartButtonTop = ( props ) =>
{
  let clsName = "";
  if( props.outlined ) {
    clsName = `bg-transparent text-white rounded-lg
               hover:bg-blue-300
               ${props.className}`;
  }
  else {
    clsName = `bg-white text-blue-600 shadow-2xl rounded-2xl 
               border border-blue-800
               hover:bg-blue-300
               hover:text-blue-600
               ${props.className}`;
  }

  return __Button( clsName, props )
}
export const  StartButtonBot = ( props ) =>
{
  let clsName = `bg-blue-600 text-white shadow-2xl rounded-2xl 
               border border-blue-600
               hover:bg-gray-200
               hover:text-gray-700
               ${props.className}`;

  return __Button( clsName, props )
}
