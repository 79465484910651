import React from 'react';
import * as d3 from 'd3';
//import legend from 'shared/legend';
//import { progressBar } from 'shared/helpers';
//import { progressBar, watermark } from 'shared/helpers';
//import orderBy from 'lodash/orderBy';
import { flattenObjArray } from 'shared/helpers';
import { drawRadarSkeleton } from 'shared/radar';
import { radar3 as defaults, strings } from 'shared/defaults';

const Radar = React.forwardRef(({ id, data, wparam }, ref) => {
  const cfg = Object.assign({}, defaults, strings, wparam);

  const w       = cfg.width,
        h       = cfg.height,
        mtop    = cfg.margin.top,
        mleft   = cfg.margin.left,
        mbottom = cfg.margin.bottom,
        mright  = cfg.margin.right;

  const bounded = {
    width: w - mleft - mright,
    height: h - mbottom - mtop,
  };

  const fgRef        = React.useRef(null);
  const bgRef        = React.useRef(null);
  const pbarRef      = React.useRef(null);
  const watermarkRef = React.useRef(null);

  const draw = () => {
    const fg = d3.select(fgRef.current);
    const bg = d3.select(bgRef.current);

//    const groupColumn = data.columns[0];

    const groupColumn  = Object.keys(data[0])[0];

    const groups = data.map((d) => d[groupColumn]);
    const flat = flattenObjArray(data, groupColumn);
    const axes = flat.map((d) => d.axis);
    const maxValue = Math.max(
      cfg.maxValue || 0,
      d3.max(flat.map((d) => d.value)),
    );
    const color = d3.scaleOrdinal(d3.schemeCategory10).domain(groups);
//    const labelFormat = d3.format(cfg.formatString);
    const byGroup = d3
      .nest()
      .key((d) => d.group)
      .entries(flat);
    // const top3 = d3
    //   .nest()
    //   .key((d) => d.group)
    //   .rollup(
    //     (values) => orderBy(values, ['value'], ['desc']).slice(0, 3),
    //     // .sort((a, b) => axes.indexOf(b.axis) - axes.indexOf(a.axis)),
    //   )
    //   .entries(flat);
    const radius =
      Math.min(bounded.width / 2, bounded.height / 2) -
      2 * cfg.fontSize * cfg.labelFactor;
    // Draw the skeleton (axes, ticks, and labels).
    const { radiusScale, angleScale } = drawRadarSkeleton(fg, flat, {
      radius,
      levels: cfg.levels,
      maxValue,
      labelGutter: cfg.labelGutter,
      axes,
      fontSize: cfg.fontSize,
    });

    const radarLine = d3
      .lineRadial()
      .curve(cfg.roundStrokes ? d3.curveCardinalClosed : d3.curveLinearClosed)
      .radius((d) => radiusScale(d.value))
      .angle((d) => angleScale(d.axis) + Math.PI / 2);

    bg
      .selectAll('.area')
      .data(byGroup)
      .join('path')
      .attr('class', 'area')
      .attr('stroke-width', cfg.strokeWidth)
      .transition()
      .attr('d', (d) => radarLine(d.values))
      .style('fill', (d) => color(d.key))
      .style('stroke', (d, i) => color(d.key));

    const series = fg
      .selectAll('.series')
      .data(byGroup, (d) => d.key)
      .join('g')
      .attr('class', 'series');

    series
      .selectAll('.label')
      .data((d) => d.values)
      .join('text')
      .attr('class', 'label')
      .attr('text-anchor', 'middle')
      .text((d) => d.value)
      .style('font-size', cfg.fontSize)
      .style('font-weight', 'bold')
      .style('fill', (d) => color(d.group))
      .attr(
        'x',
        (d, i) => {
          if( i === 0 ) {
            return( radiusScale(d.value * cfg.labelFactor) * Math.cos(angleScale(d.axis)) )
//            return( radiusScale(d.value * cfg.labelFactor) * Math.cos(angleScale(d.axis)) + 20 )
          } 
          else if( i === 1) {
//            return( radiusScale(d.value * cfg.labelFactor) * Math.cos(angleScale(d.axis)) )
            return( radiusScale(d.value * cfg.labelFactor) * Math.cos(angleScale(d.axis)) + 20 )
          } 
          else if( i === 2) {
            return( radiusScale(d.value * cfg.labelFactor) * Math.cos(angleScale(d.axis)) - 20 )
          } 
          else  {
            return( radiusScale(d.value * cfg.labelFactor) * Math.cos(angleScale(d.axis))  )
          } 
        }
      )
      .attr(
        'y',
        (d, i) => {
          if( i === 0 ) {
            return( radiusScale(d.value * cfg.labelFactor) *
              Math.sin(angleScale(d.axis)) +
            cfg.dotRadius / 2 )
          } 
          else {
            return( radiusScale(d.value * cfg.labelFactor) *
              Math.sin(angleScale(d.axis)) +
            cfg.dotRadius / 2  )
          } 
        } 
      )
      .transition()
/** disable watermark
      .on("end", (d, i) => {
            progressBar.hide(pbarRef)
            watermark.show(watermarkRef, cfg.width, cfg.height, cfg.watermark );
       })
**/

  };

  React.useEffect(() => {

    try {

      const numrows = data && data.length > 0 ?   
                      data.map( (d) => { return( d3.sum(Object.values(d)) ) } ) 
                      : 0;
      if( data && data.length > 0 && numrows > 0 ) {

          draw();
      }
      else {

//        // show progress bar
//        progressBar.show(pbarRef, cfg.width, cfg.height, cfg.norec );
//

//
// disable watermark
//        watermark.hide(watermarkRef);

        d3.select(fgRef.current).selectAll("*").remove()
        d3.select(bgRef.current).selectAll("*").remove()
      }

    } catch (error) {
      alert(`Invalid data entered for chart id.` + error);
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cfg, ]);
//  }, [data, cfg, draw]);

  return (
    <svg
      ref={ref}
      width={cfg.width} 
      height={cfg.height}
      className="chart" 
      viewBox={`0 0 ${cfg.width} ${cfg.height}`}
      style={{ margin: 'auto', display: 'block', fontSize: cfg.fontSize, backgroundColor:'' }}
    > 

      <g ref={pbarRef} transform={`translate(0,0)`}/>
      <g ref={watermarkRef} transform={`translate(0,0)`}/>

      <g
        transform={`translate(${cfg.margin.left + bounded.width / 2}, 
                    ${cfg.margin.top + bounded.height / 2 })`}
      >
        <g ref={bgRef} />
        <g ref={fgRef} />
      </g>
    </svg>
  );
});

export default Radar;
