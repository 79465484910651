export const defData = {
  sales : [ 
      { 
        "id": "내점고객",
        "op": "+",
        "dp": "hidden",
      },
      { 
        "id": "배달매출",
        "op": "+",
        "dp": "visible",
      },

      { 
        "id": "take-out",
        "op": "+",
        "dp": "visible",
      } ,

      { 
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      } ,

      {
        "id": "",
        "op": "",
        "dp": "invisible",
      }
  ],


  catItems : [ 
      { 
        "id": "통행량",
        "op": "+",
        "dp": "invisible",
        "value": "470",
        "vtype": "select",
      },
      { 
        "id": "잠재수요",
        "op": "×",
        "dp": "visible",
        "value": "3793947",
        "vtype": "formula",
      },

      {
        "id": "외식비율",
        "op": "×",
        "dp": "visible",
        "value": "0.84",
        "vtype": "direct",
      },

      {
        "id": "면류방문",
        "op": "×",
        "dp": "visible",
        "value": "0.2928",
        "vtype": "direct",
      },

      {
        "id": "통행인의질(통행량)",
        "op": "×",
        "dp": "visible",
        "value": "0.98",
        "vtype": "direct",
      },

      {
        "id": "통행인의질(내점객)",
        "op": "×",
        "dp": "visible",
        "value": "0.99",
        "vtype": "direct",
      },

      {
        "id": "주택 세대수 가중치",
        "op": "×",
        "dp": "0.97",
        "vtype": "direct",
      },

      {
        "id": "아파트가중치",
        "op": "×",
        "dp": "visible",
        "value": "1",
        "vtype": "direct",
      },

      {
        "id": "아파트평수(30평)",
        "op": "×",
        "dp": "visible",
        "value": "0.96",
        "vtype": "direct",
      },

      {
        "id": "아파트 3억이상 비율",
        "op": "×",
        "dp": "visible",
        "value": "0.93",
        "vtype": "direct",
      },

      {
        "id": "상주인구",
        "op": "×",
        "dp": "visible",
        "value": "0.84",
        "vtype": "direct",
      },

      {
        "id": "",
        "op": "",
        "dp": "",
        "value": "",
        "vtype": "",
      },

    ]
}


